import React, { useState, useContext } from 'react';
import HomePage from '../pages/HomePage';
import { useEffect } from 'react';
import { GlobalContext } from '../providers/GlobalContext';
import { getAllArticles } from '../services/ArticleService';
import SubscriptionListPage from '../pages/SubscriptionListPage/SubscriptionListPage';
import BundleIcon from "../assets/icons/Bundle-Icon.svg";
import SubscriptionIcon from "../assets/icons/rrfi/fi-rr-videoconference.svg";
import TrainingIcon from "../assets/icons/Subscription-Icon.svg";
import TrainerIcon from "../assets/icons/Trainer-Icon.svg";
import UseDimensions from '../hooks/UseDimensions';
import ShopPage from '../pages/ShopPage/ShopPage';
import OnlineCoachingImage from "../assets/images/Online-Coaching-Image.webp";
import PackageImage from "../assets/images/Package-Image.webp";
import TrainerImage from "../assets/images/Package-Image.jpg";
import { formatName } from '../utils/FormatUtils';
import { getAllTrainers } from '../services/TrainerService';
import { LoadingContext } from '../providers/LoadingContext';
import { navigate } from '../utils/RoutingUtils';

function ShopScreen(props) {
  const {user, setUser} = useContext(GlobalContext);
  const {isLoading, setIsLoading} = useContext(LoadingContext);
  const [articles, setArticles] = useState([]);
  const [trainers, setTrainers] = useState([]);
  const [layout, setLayout] = useState(props.layout == "TRAINER" ? "TRAINER" : "PRODUCTS");
  const [subLayout, setSubLayout] = useState(props.layout == "TRAINER" ? "TRAINING" : props.layout);

  useEffect(() => {
    getContent();
  }, []);

  async function getContent() {
    const articleResponse = await getAllArticles();
    setArticles(articleResponse.body);

    const trainerResponse = await getAllTrainers();
    setTrainers(trainerResponse.body);

    console.debug("get all articles result: ", articleResponse);
    console.debug("get all trainers result: ", trainerResponse);
  }

  function onArticleClickHandler(articleName, id) {
    const article = articles?.filter((article) => article.id == id).pop();
    navigate(`/${formatName(article.owner.name)}/${formatName(article.name)}`, true);
  }

  
  function onTrainerClickHandler(trainerName) {
    navigate(`/${formatName(trainerName)}`, true);
  }


  const headerValues = [
    {title: "Trenéři", src: TrainerIcon, selected: layout == "TRAINER", onClick: () => setLayout("TRAINER")},
    {title: "Produkty", src: SubscriptionIcon, selected: layout == "PRODUCTS", onClick: () => setLayout("PRODUCTS")},
  ];

  const productsValues = [
    {title: "Osobní Tréninky", src: TrainingIcon, selected: subLayout == "TRAINING", onClick: () => setSubLayout("TRAINING")},
    {title: "Online Coaching", src: SubscriptionIcon, selected: subLayout == "SUBSCRIPTION", onClick: () => setSubLayout("SUBSCRIPTION")},
    {title: "Balíčky a Služby", src: BundleIcon, selected: subLayout == "PACKAGE", onClick: () => setSubLayout("PACKAGE")},
  ];

  
  const layoutValues = [
    { 
      type: "TRAINER",
      title: "Vyber si svého trenéra",
      description: "Objev elitní tým certifikovaných online fitness trenérů, kteří jsou zde, aby tě provedli každým krokem tvojí cesty za lepší postavou.",
      src: TrainerImage,
      texts: [
        {title: "Trenér přesně pro tebe", description: "Pomocí AI algoritmu ti vybereme trenéra, který ti bude nejvíce vyhovovat na základě tvých cílů a preferencí."},
      ],
      buttonText: "Najít ideálního trenéra",
      onButtonClick: () => window.location.href = "/filter/trainer",
    },
    { 
      type: "TRAINING",
      title: "Co jsou tréninky",
      description: "",
      texts: [
        { 
          title: "Osobní tréninky",
          description: "Osobní tréninky od certifikovaných trenérů, ať už ve fitness centrech, doma či venku."
        },
        { 
          title: "Jednorázová platba",
          description: "Po zakoupení tě trenér kontaktuje a domluví s tebou termín. Tréninky nalezeš v sekci Moje plány."
        },
      ],
      src: PackageImage,
    },
    { 
      type: "SUBSCRIPTION",
      title: "Co je online coaching",
      description: "Tvůj osobní online trenér, který ti pomůže dostat ze sebe maximum. Vytvoří ti na míru šitý tréninkový plán, tvůj osobní jídelníček a bude s tebou pravidelně komunikovat, ať jsi kdekoliv.",
      texts: [
        { 
          title: "Tvůj online trenér", 
          description: "Pravidelná komunikace s trenérem, který bude sledovat tvůj pokrok a vždy vytvářet potřebné plány."
        },
        { 
          title: "Měsíční platba", 
          description: "Každý měsíc ti trenér vytvoří nové plány na základě tvých výsledků. Coaching nalezeš v sekci Moje plány."
        },
      ],
      src: OnlineCoachingImage,
    },
    { 
      type: "PACKAGE",
      title: "Co jsou balíčky",
      description: "Kompletní plán s instrukcemi od certifikovaného trenéra. Ideální pro ty, kdo preferují samostatnější přístup ke svým tréninkům a jednorázové řešení.",
      texts: [
        { 
          title: "Vylepšení pro trénink",
          description: "Plány na míru a užitečné znalosti od trenérů, které ti pomohou dosáhnout lepších výsledků."
        },
        { 
          title: "Jednorázová platba",
          description: "Po zakoupení tě trenér kontaktuje a probere s tebou tvou představu. Balíček nalezeš v sekci Moje plány."
        },
      ],
      src: PackageImage,
    },
  ]

  const articlesOneTimeMapped = articles
  ?.filter((article) => article.subType == "PACKAGE" || article.subType == "PREPARED_PACKAGE")
  ?.sort((a, b) => a.price == 0 ? -1 : 1)
  ?.map((article) => {
    return {
      id: article.id,
      title: article.name,
      description: article.about,
      price: article.price,
      src: article.imageUri?.[0] || "",
      type: article.type,
    }
  }) || [];

  const articlesOneTimePhysicalMapped = articles
  ?.filter((article) => article.subType == "TRAINING")
  ?.sort((a, b) => a.price == 0 ? -1 : 1)
  ?.map((article) => {
    return {
      id: article.id,
      title: article.name,
      description: article.about,
      price: article.price,
      src: article.imageUri?.[0] || "",
      type: article.type,
    }
  }) || [];

const articlesOnlineSubscriptionMapped = articles
  ?.filter((article) => article.subType == "COACHING")
  ?.map((article) => {
    return {
      id: article.id,
      title: article.name,
      description: article.about,
      price: article.price,
      src: article.imageUri?.[0] || "",
      type: article.type,
    }
  }) || [];

  return(
      <ShopPage
          layout={layout}
          subLaylout={subLayout}
          subscriptions={articlesOnlineSubscriptionMapped}
          packages={articlesOneTimeMapped}
          trainings={articlesOneTimePhysicalMapped}
          trainers={trainers}
          onArticleClick={onArticleClickHandler}
          onTrainerClick={onTrainerClickHandler}
          headerValues={headerValues}
          productsValues={productsValues}
          layoutValues={layoutValues}
      />
  );
}
export default ShopScreen;