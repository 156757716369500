import React, { useState, useContext } from 'react';
import HomePage from '../pages/HomePage';
import { useEffect } from 'react';
import { GlobalContext } from '../providers/GlobalContext';
import { getAllArticles } from '../services/ArticleService';
import SubscriptionListPage from '../pages/SubscriptionListPage/SubscriptionListPage';
import BundleIcon from "../assets/icons/Bundle-Icon.svg";
import SubscriptionIcon from "../assets/icons/Subscription-Icon.svg";
import TrainerIcon from "../assets/icons/Trainer-Icon.svg";
import UseDimensions from '../hooks/UseDimensions';
import ShopPage from '../pages/ShopPage/ShopPage';
import { formatName } from '../utils/FormatUtils';
import { getAllTrainers, postActivateAccount } from '../services/TrainerService';
import AdminListPage from '../pages/AdminListPage/AdminListPage';
import { getAdminAllTrainers, postAdminActivateTrainer, postAdminLogin } from '../services/AdminService';

export default function AdminListScreen(props) {
  const {user, setUser} = useContext(GlobalContext);
  const [users, setUsers] = useState([]);
  const [trainers, setTrainers] = useState([]);
  const [layout, setLayout] = useState("TRAINER");
  const {height} = UseDimensions();

  useEffect(() => {
    getContent();
  }, []);

  async function getContent() {
    const trainerResponse = await getAdminAllTrainers();
    setTrainers(trainerResponse.body);

    if(!trainerResponse.success) {
      window.location.href = "/error/403";
      return;
    }

    console.debug("get all trainers result: ", trainerResponse);
  }

  
  function onTrainerClickHandler(trainerName) {
    window.location.href = `/${formatName(trainerName)}`;
  }

  async function onTrainerLoginClickHandler(trainerId) {
    const response = await postAdminLogin(trainerId);

    if(response.success) {
      setUser({
        isLoggedIn: true,
        token: response.body.token,
        tempToken: "/",
        name: "Username",
        language: "cs",
      });

      window.location.href = "/trainer/dashboard";
    } else {
      alert("Error logging in trainer");
    }
  }

  async function onTrainerActivateClickHandler(trainerId) {
    const response = await postAdminActivateTrainer(trainerId);

    if(response.success) {
      alert("Trainer account activated");
      getContent();
    } else {
      alert("Error activating trainer account");
    }
  }

  function onUserClickHandler(userId) {
    // window.location.href = `/user/${userId}`;
  }


  const headerValues = [
    {title: "Trenéři", src: TrainerIcon, selected: layout == "TRAINER", onClick: () => setLayout("TRAINER")},
    {title: "Uživatele", src: SubscriptionIcon, selected: layout == "USER", onClick: () => setLayout("USER")},
  ];


  return(
      <AdminListPage
          height={height}
          layout={layout}
          users={users}
          trainers={trainers}
          onUserClick={onUserClickHandler}
          onTrainerClick={onTrainerClickHandler}
          onTrainerLoginClick={onTrainerLoginClickHandler}
          onTrainerActivateClick={onTrainerActivateClickHandler}
          headerValues={headerValues}
      />
  );
}