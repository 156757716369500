import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Trans } from 'react-i18next';
import GlobalContextProvider from './providers/GlobalContextProvider';
import './providers/I18nProvider';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { STRIPE_PUBLIC_KEY, GOOGLE_CLIENT_ID } from './constants/Api';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { csCZ } from '@mui/x-date-pickers/locales';
import LoadingContextProvider from './providers/LoadingProvider';

const root = ReactDOM.createRoot(document.getElementById('root'));

const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

//todo stripe promise
root.render(
    <GlobalContextProvider>
            <Elements stripe={stripePromise}> 
                <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID} >
                        <LocalizationProvider 
                            dateAdapter={AdapterDayjs}
                            localeText={csCZ.components.MuiLocalizationProvider.defaultProps.localeText}
                        >
                            <LoadingContextProvider>
                                <Trans>
                                    <App />
                                </Trans>
                            </LoadingContextProvider>
                        </LocalizationProvider>
                </GoogleOAuthProvider>
            </Elements>
    </GlobalContextProvider>
);