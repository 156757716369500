import React from 'react';
import './DashboardPage.css';
import {BottomNavbarDouble, BottomNavbarProgress} from '../../components/Navbar/BottomNavbar';
import { ChooseInputDescription } from '../../components/TextInput/ChooseInput';
import TrainerBox from '../../components/Boxes/TrainerBox';
import { formatPrice } from '../../utils/FormatUtils';
import { Switch } from '../../components/Navbar/SwitchHeader';
import ActivationStatusComponent from '../../components/ActivationStatusComponent';
import EmptySmallTrainerBox from '../../components/Boxes/EmptySmallTrainerBox';
import SmallTrainerBox from '../../components/Boxes/SmallTrainerBox';

function DashboardPage(props) {

  const deliveryStatus = {
    'PENDING': 'Čeká na vyřízení',
    'CONSULTATION': 'Čeká na konzultaci',
    'IMPLEMENTING': 'Probíhá vyřizování',
    'ACTIVE': 'Předaná klientovi',
    'CANCELED': 'Zrušeno',
  }

  const Subscription = () => {
    if(props.subscriptions.length < 1) {
      return (
          <EmptySmallTrainerBox title="Žádní aktivní klienti"/>
      );
    }

    return props.subscriptions.map((subscription, key) =>
      <SmallTrainerBox
        key={subscription.id}
        title={subscription.article.name}
        description={deliveryStatus[subscription.deliveryStatus]}
        src={subscription.article.imageUri[0]}
        onClick={() => props.onSubscriptionClick(subscription.id)}
      />
    );
        
    
  }

  const Package = () => {
    if(props.packages.length < 1) {
      return (
          <EmptySmallTrainerBox title="Žádné objednávky k vyřízení"/>
      );
    }

    return props.packages.map((article, key) =>
      <SmallTrainerBox
        key={article.id}
        title={article.article.name}
        description={deliveryStatus[article.deliveryStatus]}
        src={article.article.imageUri[0]}
        onClick={() => props.onPackageClick(article.id)}
      />
    );
  }

  return (
      <div className="dashboard_page">

          <div className="dashboard_page__header">          
            
            <div className="dashboard_page__header_balance">
              <h5 className="dashboard_page__header_balance_title">Aktuální zůstatek</h5>
              <h1 className="dashboard_page__header_balance_value">{formatPrice(props.balance, true, false)}</h1>
            </div>

            <div className="dashboard_page__header_trainer">
              <TrainerBox 
                          title={props.trainer?.name}
                          description={props.trainer?.description}
                          email={props.trainer?.email}
                          phone={props.trainer?.phoneNumber}
                          src={props.trainer?.imageUri?.length > 0 && props.trainer?.imageUri[0]}
                          onClick={props.onTrainerClick}
                          type={"light"}
                          />
            </div>

          </div>

          <div className="dashboard_page__body">    
            {!props.activationStatus && <Switch values={props.headerValues}/>}
            {props.activationStatus && <ActivationStatusComponent activationStatus={props.activationStatus} onClick={props.onActivationClick} theme={"dark"}/>}
          </div>

          <div className='dashoard_page_content'>
            {props.layout == "SUBSCRIPTION" && <Subscription/>}
            {props.layout == "PACKAGE" && <Package/>}
          </div>


          <BottomNavbarDouble leftButtonText={"Upravit profil"} rightButtonText={"Na profil"} leftButtonOnClick={props.onEditClick} rightButtonOnClick={props.onToProfileClick}/>
      </div>
    );
}

export default DashboardPage;
