import React, { useState, useContext, useEffect } from 'react';
import { parsePhoneNumberFromString, CountryCode } from 'libphonenumber-js';
import RegisterPage from '../pages/RegisterPage/RegisterPage';
import { GlobalContext } from '../providers/GlobalContext';
import { getGoogleUser, register } from '../services/UserService';
import { registerClient, registerGoogleClient } from '../services/ClientService';
import { useParams } from 'react-router-dom';

function RegisterScreen() {
  const {redirect, emailParam, affiliateId} = useParams();
  const {user, setUser} = useContext(GlobalContext);

  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [screenState, setScreenState] = useState("idle");
  const [error, setError] = useState(null);

  useEffect(() => {
    if(emailParam == "google") {
      fetchUserData();
      return;
    }

    setEmail(emailParam || "");
    
  }, []);

  async function fetchUserData() {
    const result = await getGoogleUser();
    if (!result.success) {
      console.log(result.message);
      return;
    }

    setUsername(result.body.username);
    setEmail(result.body.email);
    setPhone(result.body.phoneNumber);
  }

  async function onButtonClick() {
    if(emailParam == "google") {
      registerGoogle();
      return;
    }

    registerEmail();
  }

  async function registerGoogle() {
    setScreenState("loading");

    const body = {
      username,
      email,
      phoneNumber: phone.replaceAll(" ", ""),
    }

    const clientResult = await registerGoogleClient(body);
      
    if(!clientResult.success) {
      console.log(clientResult.message)
      setError(clientResult.message)
      setScreenState("idle");
      return;
    }

    setUser({
      isLoggedIn: true,
      token: clientResult.body.token,
      name: username,
      language: "cs"
    })

    setError("")
    setScreenState("success");

    setTimeout(() => {
      const redirectUrl = redirect ? "/" + redirect.replaceAll("_", "/") : "/";
      window.location = redirectUrl;
    }, 1500);
  }
  
  async function registerEmail() {
    setScreenState("loading");

    const registerResult = await register(username, email, phone.replaceAll(" ", ""), password, affiliateId);
  
    if(!registerResult.success) {
      console.log(registerResult.message)
      setError(registerResult.message)
      setScreenState("idle");
      return;
    }

    setUser({
      isLoggedIn: true,
      token: registerResult.body.token,
      name: username,
      language: "cs"
    })

    const clientResult = await registerClient();
      
    if(!clientResult.success) {
      console.log(clientResult.message)
      setError(clientResult.message)
      setScreenState("idle");
      return;
    }

    setUser({
      isLoggedIn: true,
      token: clientResult.body.token,
      name: username,
      language: "cs"
    })

    setError("")
    setScreenState("success");

    setTimeout(() => {
      const redirectUrl = redirect ? "/" + redirect.replaceAll("_", "/") : "/";
      window.location = redirectUrl;
    }, 1500);
  }

  function onUsernameChange(username) {
    setUsername(username.target.value);
  }

  function onEmailChange(email) {
    setEmail(email.target.value);
  }

  function onPhoneChange(phoneValue) {
    const value = phoneValue.target.value;
    const oldValue = phone;

    const lastChar = value.charAt(value.length-1);
    const isDeleting = oldValue.length > value.length;

    if(isDeleting) {
      setPhone(value);
      return;
    }

    if(phone.length == 0) {
      lastChar.match(/[0-9]/g) ? setPhone("+420 " + lastChar) : setPhone("+420 ");
      return;
    }

    if(value.length == 4 || value.length == 8 || value.length == 12) {
      setPhone(value + " ");
      return;
    }

    if(value.length == 5 || value.length == 9 || value.length == 13) {
      setPhone(value.substring(0, value.length-1) + " " + lastChar);
      return;
    }

    if(lastChar.match(/[0-9]/g) && value.length <= 16) {
      setPhone(value);
      return;
    }

  }

  function onPasswordChange(password) {
    setPassword(password.target.value);
  }

  function onPrivacyClick() {
    window.location = "/privacy";
  }

  function onConditionsClick() {
    window.location = "/conditions";
  }


  const isFormEmailValid = (
    username.length > 2 
    && username.length < 30 
    && username?.split(" ").length >= 1 
    && username?.split(" ").length <= 3
    && username?.split(" ").pop() != ""
    && email.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g) 
    && phone.match(/^\+\d+ \d{3} \d{3} \d{3}$/)
    && password.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d!@#$%^&*\-]{8,}$/g)
  )

  const isFormGoogleValid = (
    username.length > 2 
    && username.length < 30
    && username?.split(" ").length >= 1 
    && username?.split(" ").length <= 3
    && username?.split(" ").pop() != ""
    && email.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g) 
    && phone.match(/^\+\d+ \d{3} \d{3} \d{3}$/)
    && parsePhoneNumberFromString(phone)?.isValid() || false
  )

  const isFormValid = emailParam == "google" ? isFormGoogleValid : isFormEmailValid;
  

  return (
    <RegisterPage
      username={username}
      email={email}
      phone={phone}
      password={password}
      onUsernameChange={onUsernameChange}
      onEmailChange={onEmailChange}
      onPhoneChange={onPhoneChange}
      onPasswordChage={onPasswordChange}
      onButtonClick={onButtonClick}
      onPrivacyClick={onPrivacyClick}
      onConditionsClick={onConditionsClick}
      showPassword={emailParam != "google"}
      screenState={screenState}
      error={error}
      submitDisabled={!isFormValid}
    />
  );
}
export default RegisterScreen;