import SubmitButton from "../Buttons/Submit/SubmitButton";
import "./EmptySubscriptionBox.css";
import PackageBoxIcon from "../../assets/icons/Package-Box-Icon.svg";
import Icon from "../Icon";
import ButtonPrimary from "../Buttons/Primary/ButtonPrimary";

export default function EmptySubsciptionBox(props) {
  const { title, buttonText, onClick, ...restProps } = props;

  return (
    <div className="empty_subscription_box" {...restProps}>
      <div className="empty_subscription_box__box">
        <div />
        <Icon
          src={PackageBoxIcon}
          alt="package box icon"
          className="empty_subscription_box__box_image"
        />
        <div />
        <h5 className="empty_subscription_box__box_h3">{title}</h5>
        <ButtonPrimary
          title={buttonText}
          onClick={() => onClick(title)}
          className="empty_subscription_box__box_button"
        />
        <div />
      </div>
    </div>
  );
}
