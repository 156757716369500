import React from 'react';
import './BaseModal.css';
import { motion, AnimatePresence } from 'framer-motion';

export default function BaseModal({style, children, isVisible, setIsVisible }) {
  const closeModal = () => {
    setIsVisible(false);
  };

  return (
    <AnimatePresence
    >
      {isVisible && (
        <>
          <motion.div 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3, ease: "easeInOut" }}
            className="base_modal__overlay"
            onClick={closeModal}
          />
          <motion.div 
            initial={{ translateY: '100%' }}
            animate={{ translateY: 0 }}
            exit={{ translateY: '100%' }}
            transition={{ duration: 0.3, ease: "easeInOut" }}
            className="base_modal"
            style={style}
          >
            <div className="base_modal__container">
              {children}
            </div>
          </motion.div>
        </>
      )}
    </AnimatePresence>
  );
}
