import React, { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom"
import FilterPage from "../pages/FilterPage/FilterPage";
import UseDimensions from "../hooks/UseDimensions";
import { getFilter } from "../services/FilterService";
import StepScreen from "./StepScreen";
import MaleIcon from "../assets/icons/Male-Icon.svg";
import FemaleIcon from "../assets/icons/Female-Icon.svg";
import OtherIcon from "../assets/icons/Other-Icon.svg";
import ForbidenIcon from "../assets/icons/Forbiden-Icon.svg";
import StudentIcon from "../assets/icons/Student-Icon.svg";
import AdultIcon from "../assets/icons/Adult-Icon.svg";
import OlderAdultIcon from "../assets/icons/Older-Adult-Icon.svg";
import SeniorIcon from "../assets/icons/Senior-Icon.svg";
import Weight1Icon from "../assets/icons/Weight-1-Icon.svg";
import Weight2Icon from "../assets/icons/Weight-2-Icon.svg";
import Weight3Icon from "../assets/icons/Weight-3-Icon.svg";
import Weight4Icon from "../assets/icons/Weight-4-Icon.svg";
import WeightIcon from "../assets/icons/Weight-Icon.svg";
import SubscriptionIcon from "../assets/icons/Subscription-Icon.svg";
import BundleIcon from "../assets/icons/Bundle-Icon.svg";
import { postArticle, getArticleById, putArticle } from "../services/ArticleService";
import { getAllTrainerCategories } from "../services/CategoryService";
import { formatName } from "../utils/FormatUtils";
import { GlobalContext } from "../providers/GlobalContext";
import { putTrainerInfo } from "../services/TrainerService";
import { goBack } from "../utils/RoutingUtils";

export default function TrainerCertificateScreen() {
  const {user, setUser} = useContext(GlobalContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [categories, setCategories] = useState([]);
  const trainer = user?.trainer?.length > 0 ? user?.trainer[0] : {};
  const navigate = useNavigate();

  useEffect(() => {
    async function fetch() {
      const response = await getAllTrainerCategories();
      if(response.success) {
        console.log("Categories: ", response.body);
        setCategories(response.body);
      }
    }

    fetch();
  }, []);

  
  const values = [
    {
      index: 0,
      type: "selection",
      title: "Ověření trenérského účtu",
      description: "Než zahájíš prodej, potřebujeme se ujistit, že jsi skutečný trenér",
      value: null,
      values: [
        {
          index: 0,
          title: "Trenérským certifikátem",
          description: "Ověříš se nahráním trenérské licence či podobného dokumentu.",
          src: BundleIcon,
        },
        {
          index: 1,
          title: "Občanským průkazem",
          description: "Pro ověření nahraješ fotku občanského průkazu a odkaz na stránky posilovny, ve které působíš.",
          src: SubscriptionIcon,
        },
      ]
    },
    {
      index: 1,
      type: "content",
      title: "Nahraj certifikaci",
      description: "Abys mohl začít prodávat, potřebujeme ověřit tvou trenérskou licenci či certifikát",
      maxLength: 1,
      if: (values) => values[0]?.value == 0,
      exists: user?.certifcateUri?.length > 0 ? true : false,
      value: user?.certifcateUri ? user?.certifcateUri?.map(example => {return { text: example?.substring(example?.length -20), src: example }}) : null,
    },
    {
      index: 2,
      type: "content",
      title: "Nahraj Občanský průkaz",
      description: "Abys mohl začít prodávat, nahraj přední stranu občanského průkazu či jiného dokladu totožnosti",
      maxLength: 1,
      if: (values) => values[0]?.value == 1,
      exists: user?.certifcateUri?.length > 0 ? true : false,
      value: user?.certifcateUri ? user?.certifcateUri?.map(example => {return { text: example?.substring(example?.length -20), src: example }}) : null,
    },
    {
      index: 3,
      type: "text",
      title: "Odkaz na stránky posilovny",
      description: "Odkaz na stránky posilovny, ve které působíš",
      placeholder: "Odkaz",
      errorText: "Odkaz musí mít 3 až 200 znaků",
      if: (values) => values[0]?.value == 1,
      validator: (value) => value?.length >= 3 && value?.length <= 200 ,
      src: WeightIcon,
      value: "",
    },
  ];

  function onNextCallback(values) {
    console.log("Next");
    console.log(values);
  }

  async function onFinishCallback(values) {
    console.log("Finish");
    console.log(values);

    if(isSubmitting) {
      return;
    }

    setIsSubmitting(true);

    let body = {};

    if(values[0]?.value == 0) {
      body = {
        certificate: values[1]?.exists ? null : values[1]?.value?.map(value => {return { fileName: value.title, file: value.src.substring(value.src.indexOf("base64,") + 7)}}),
      }
    }

    if(values[0]?.value == 1) {
      body = {
        certificate: values[2]?.exists ? null : values[2]?.value?.map(value => {return { fileName: value.title, file: value.src.substring(value.src.indexOf("base64,") + 7)}}),
        gymUrl: values[3]?.value,
      }
    }

    const response = await putTrainerInfo(body);
    
    if(response.success) {
      console.log("Success");

      goBack();
    }

    if(!response.success) {
      console.log("Error");
      console.log(response.message);
      alert(response.message);
    }


    setIsSubmitting(false);
  }

  if(categories?.length == 0 || !user?.trainer?.length > 0) {
    return <div></div>
  }

  console.log("Trainer: ", trainer.length);

  return (
    <StepScreen
      values={values}
      isLoading={isSubmitting}
      onNextCallback={onNextCallback}
      onFinishCallback={onFinishCallback}
    />
  )
}