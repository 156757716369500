import "./ActivationStatusComponent.css";
import IconBox from "./Boxes/IconBox";
import CreditCardIcon from "../assets/icons/Credit-Card-Icon.svg";
import ChatIcon from "../assets/icons/Chat-Icon.svg";
import TimeIcon from "../assets/icons/Time-Icon.svg";
import GymIcon from "../assets/icons/Gym-Icon.svg";
import ArrowRightIcon from "../assets/icons/Arrow-Right-Icon.svg";
import LineIcon from "../assets/icons/Line-Icon.svg";
import ButtonPrimary from "./Buttons/Primary/ButtonPrimary";

export default function ActivationStatusComponent(props) {
  const {activationStatus} = props;
  

  const deliveryValues = [
    { 
      src: CreditCardIcon, 
      status: "PUBLIC_INFO", 
      type:"round_dark",
      title: "Doplň informace o profilu",
      description: "Svůj nový trenérský profil obohať o základní informace o sobě a svém tréninku",
      buttontext: "Vytořit veřejný profil"
    },
    { 
      src: LineIcon, 
      type: "transparent" 
    },
    { 
      src: ChatIcon, 
      status: "PRIVATE_INFO", 
      type:"round_dark",
      title: "Bankovní údaje",
      description: "Doplň bankovní spojení abychom veděli, kam ti máme posílat zisk z prodaných produktů",
      buttontext: "Vyplnit bankovní údaje"
     },
    { 
      src: LineIcon, 
      type: "transparent" 
    },
    { 
      src: TimeIcon, 
      status: "CERTIFICATE_UPLOAD", 
      type:"round_dark",
      title: "Nahraj certifikát",
      description: "Abys mohl začít prodávat, potřebujeme ověřit tvou trenérskou licenci či certifikát",
      buttontext: "Nahrát certifikát"
    },
    { 
      src: LineIcon, 
      type: "transparent"
    },
    { 
      src: GymIcon, 
      status: "CERTIFICATE_APPROVAL", 
      type:"round_dark",
      title: "V procesu schvalování",
      description: "Tvůj certifikát ověříme do 48 hodin, ale mezitím už můžeš připravit své produkty k prodeji",
    },
    { 
      src: LineIcon, 
      type: "transparent"
    },
    { 
      src: GymIcon, 
      status: "ACTIVATION", 
      type:"round_dark",
      title: "Profil schválen",
      description: "Nyní můžeš začít prodávat svůj obsah a budovat svou značku! Uvidíme se nahoře",
      buttontext: "Zveřejnit profil"
    },
  ]

  return (
    <div className="activation_status_component">
      <div className="activation_status_component__header">
        <p className="activation_status_component__h4_bold">
            {deliveryValues.find((value) => value.status == activationStatus).title}
        </p>
        <p className="activation_status_component__h4">
          {deliveryValues.find((value) => value.status == activationStatus).description}
        </p>
      </div>
      <div className="activation_status_component__box">
        {deliveryValues.map((value, index) => {
          const activeIndex = deliveryValues.findIndex((value) => value.status == activationStatus);
          return (<IconBox key={index} {...value} type={value.type} active={index <= activeIndex} />)
        }
        )}
      </div>
        {deliveryValues.find((value) => value.status == activationStatus)?.buttontext != null 
          && <ButtonPrimary 
          title={deliveryValues.find((value) => value.status == activationStatus)?.buttontext}
          onClick={props.onClick} 
          className="activation_status_component__button"/>}
    </div>
  )
   
  
}