import React, { useEffect, useState } from 'react';
import ContentBox from './ContentBox';
import "./ContentBoxGrid.css"

export default function ContentBoxGrid(props) {
  const { values, theme, onClick, maxRows  } = props;
  const [maxItemsPerRow, setMaxItemsPerRow] = useState(0);

  useEffect(() => {
    function calculateMaxItems() {
      const container = document.querySelector('.content_box_grid');
      if (container) {
        const containerWidth = container.offsetWidth;
        const boxWidth = 136 + 16; // width of each box
        setMaxItemsPerRow(Math.floor(containerWidth / boxWidth));
      }
    }
    calculateMaxItems();
    window.addEventListener('resize', calculateMaxItems);
    return () => window.removeEventListener('resize', calculateMaxItems);
  }, [values]);

  let displayedRows = Math.ceil(values.length / maxItemsPerRow);
  displayedRows = Math.min(displayedRows, maxRows ? maxRows : displayedRows);

  const totalItems = displayedRows * maxItemsPerRow;
  const displayedValues = values.slice(0, totalItems);
  const placeholderCount = totalItems - displayedValues.length;

  return (
    <div className="content_box_grid">
      {displayedValues.map((value, index) => (
        <div key={index} >
           <ContentBox key={index} theme={theme} onClick={onClick} {...value} text={value.text} contentUri={value.contentUri} style={{ marginRight: "var(--dynamic-gap)" }}/>
        </div>
      ))}
      {Array.from({ length: placeholderCount }, (_, index) => (
        <div key={'placeholder-' + index} className="content_box content_box_grid_box"></div>
      ))}
    </div>
  );
}
