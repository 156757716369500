import React, { useState, useContext, useEffect, useMemo } from 'react';
import TrainerProfilePage from '../pages/TrainerProfile/TrainerProfilePage';
import { GlobalContext } from '../providers/GlobalContext';
import { getTrainerByName } from '../services/TrainerService';
import { useParams } from 'react-router-dom';
import ArchivementIcon from "../assets/icons/Achievement-Icon.svg";
import QualificationIcon from "../assets/icons/Qualification-Icon.svg";
import { formatName } from '../utils/FormatUtils';
import SubscriptionIcon from "../assets/icons/rrfi/fi-rr-videoconference.svg";
import TrainingIcon from "../assets/icons/Subscription-Icon.svg";
import BundleIcon from "../assets/icons/Bundle-Icon.svg";

function TrainerProfileScreen() {
  const { user, setUser } = useContext(GlobalContext);
  const { username } = useParams();
  
  const [trainer, setTrainer] = useState({})
  const [showMore, setShowMore] = useState(false);
  const [layout, setLayout] = useState("PACKAGE");

  useEffect(() => {
    getTrainer(username);
  }, []);

  async function getTrainer(name) {
    console.log("name");
    const response = await getTrainerByName(name);

    if(!response.success) {
      window.location.href = "error/404";
      return;
    }

    setTrainer(response.body);

    console.debug("get trainer result: ", response);

    // set layout
    const favouriteArticle = response.body.articles?.find((article) => article.isRecomended == true);
    if(favouriteArticle) {
      setLayout(favouriteArticle.type == "MONTHLY_SUBSCRIPTION" ? "SUBSCRIPTION" : favouriteArticle.subType == "TRAINING" ? "TRAINING" : "PACKAGE");
      return;
    }

    const trainingArticle = response.body.articles?.find((article) => article.subType == "TRAINING");
    if(trainingArticle) {
      setLayout("TRAINING");
      return;
    }

    const subscriptionArticle = response.body.articles?.find((article) => article.type == "MONTHLY_SUBSCRIPTION");
    if(subscriptionArticle) {
      setLayout("SUBSCRIPTION");
      return;
    }
  }

  function showMoreHandler() {
    setShowMore((prewState) => !prewState);
  }

  function onSocialClickHandler(social) {
    switch (social) {
      case "email":
        window.location.href = `mailto:${trainer.email}`;
        break;
      case "phone":
        window.location.href = `tel:${trainer.phoneNumber}`;
        break;
      default:
        window.location.href = trainer.socials[social];
        break;
    }
  }

  function onArticleClickHandler(articleName) {
    if(articleName == "EMPTY_PACKAGE" || articleName == "EMPTY_SUBSCRIPTION" || articleName == "EMPTY_TRAINING") {
      window.location.href = "/trainer/package/create";
      return;
    }

    window.location.href = `/${formatName(trainer.name)}/${formatName(articleName)}`;
  }

  function onContactClickHandler() {
    window.location.href = `tel:${trainer.phoneNumber}`;
  }

  function onTrainingClick() {
    setLayout("TRAINING");  
  }

  function onPackageClick() {
    setLayout("PACKAGE");
  }

  function onSubscriptionClick() {
    setLayout("SUBSCRIPTION");
  }

  function onEditClick() {
    window.location.href = "/trainer/edit/info";
  }

  function onToDashboardClick() {
    window.location.href = "/trainer/dashboard";
  }

  const isOwner = user?.username === trainer?.name;

  const focusMapped = useMemo(() => trainer?.focus?.map((focus) => {
    return {
      text: focus.name,
      src: focus.imageUri,
    }
  }) || [trainer]);
  

  const achievementMapped = useMemo(() => trainer?.achievements?.map((achievement) => {
    return {
      text: achievement,
      src: ArchivementIcon,
    }
  }) || [trainer]);


  const qualificationMapped = useMemo(() => trainer?.qualification?.map((qualification) => {
    return {
      text: qualification,
      src: QualificationIcon,
    }
  }) || [trainer]);
  
  const quickLook = useMemo(() => {
    return [...focusMapped, ...achievementMapped, ...qualificationMapped];
  }, [focusMapped, achievementMapped, qualificationMapped]);


  const articlesOneTimePhysicalMapped = trainer.articles
    // ?.filter((article) => article.type == "ONE_TIME_PURCHASE" || article.type == "FREE")
    ?.filter((article) => article.subType == "TRAINING")
    ?.filter((article) => article.status == "ACTIVE" || article.status == "PENDING" || isOwner)
    ?.sort((a, b) => a.price == 0 ? -1 : 1)
    ?.sort((a, b) => a.isRecomended == true ? -1 : 1)
    ?.map((article) => {
      return {
        title: article.name,
        description: article.about,
        price: article.price,
        src: article.imageUri?.[0] || "",
        isRecomended: article.isRecomended,
        isFree: article.type == "FREE",
        hidden: article.status == "ACTIVE" ? false : true,
        type: article.type,
      }
    }) || [];

  const articlesOneTimeMapped = trainer.articles
    // ?.filter((article) => article.type == "ONE_TIME_PURCHASE" || article.type == "FREE")
    ?.filter((article) => article.subType == "PACKAGE"  || article.subType == "PREPARED_PACKAGE")
    ?.filter((article) => article.status == "ACTIVE" || article.status == "PENDING" || isOwner)
    ?.sort((a, b) => a.price == 0 ? -1 : 1)
    ?.sort((a, b) => a.isRecomended == true ? -1 : 1)
    ?.map((article) => {
      return {
        title: article.name,
        description: article.about,
        price: article.price,
        src: article.imageUri?.[0] || "",
        isRecomended: article.isRecomended,
        isFree: article.type == "FREE",
        hidden: article.status == "ACTIVE" ? false : true,
        type: article.type,
      }
    }) || [];

  const articlesOnlineSubscriptionMapped = trainer.articles
    ?.filter((article) => article.subType == "COACHING")
    ?.filter((article) => article.status == "ACTIVE" || article.status == "PENDING" || isOwner)
    ?.sort((a, b) => a.price == 0 ? -1 : 1)
    ?.sort((a, b) => a.isRecomended == true ? -1 : 1)
    ?.map((article) => {
      return {
        title: article.name,
        description: article.about,
        price: article.price,
        src: article.imageUri?.[0] || "",
        isRecomended: article.isRecomended,
        hidden: article.status == "ACTIVE" ? false : true,
        type: article.type,
      }
    }) || [];
    
  const socialsMapped = Object.keys(trainer?.socials || {}).map(key => {
    return { text: key, value: trainer?.socials[key] };
  }) || [];

  socialsMapped.push({ text: "phone", value: trainer?.phoneNumber });
  socialsMapped.push({ text: "email", value: trainer?.email });

  const headerValues = [
    {title: "Osobní Tréninky", src: TrainingIcon, selected: layout == "TRAINING", onClick: () => onTrainingClick()},
    {title: "Online Coaching", src: SubscriptionIcon, selected: layout == "SUBSCRIPTION", onClick: () => onSubscriptionClick()},
    {title: "Balíčky a Služby", src: BundleIcon, selected: layout == "PACKAGE", onClick: () => onPackageClick()},
  ];

  if(isOwner) {
    articlesOneTimePhysicalMapped.push({type: "EMPTY_TRAINING"});
    articlesOneTimeMapped.push({type: "EMPTY_PACKAGE"});
    articlesOnlineSubscriptionMapped.push({type: "EMPTY_SUBSCRIPTION"});
  }

  return (
    <TrainerProfilePage
      name={trainer.name}
      description={trainer.description}
      trainerImage={trainer?.imageUri?.[0]}
      quickLook={quickLook}
      focus={focusMapped}
      achievement={achievementMapped}
      qualification={qualificationMapped} 
      about={trainer.about}
      socials={socialsMapped}
      articlesTraining={articlesOneTimePhysicalMapped}
      articlesOneTime={articlesOneTimeMapped}
      articlesOnlineSubscription={articlesOnlineSubscriptionMapped}
      showMore={showMore}
      layout={layout}
      headerValues={headerValues}
      isOwner={isOwner}
      isActive={trainer.status == "ACTIVE"}
      onShowMoreClick={showMoreHandler}
      onSocialClick={onSocialClickHandler}
      onArticleClick={onArticleClickHandler}
      onContactClick={onContactClickHandler}
      onEditClick={onEditClick}
      onToDashboardClick={onToDashboardClick}
    />
  );
}
export default TrainerProfileScreen;
