import React, { useState, useContext, useEffect } from 'react';
import ArticleDetailPage from '../pages/ArticleDetailPage';
import { GlobalContext } from '../providers/GlobalContext';
import { getTrainerByName } from '../services/TrainerService';
import { useParams } from 'react-router-dom';
import { formatName } from '../utils/FormatUtils';
import ArchivementIcon from "../assets/icons/Achievement-Icon.svg";
import QualificationIcon from "../assets/icons/Qualification-Icon.svg";
import { getArticleById } from '../services/ArticleService';

function ArticleDesignScreen() {
  const {user, setUser} = useContext(GlobalContext);
  const { username, article } = useParams();
  
  const [trainer, setTrainer] = useState({}); 
  const [trainerArticle, setTrainerArticle] = useState({});
  const [isLoginModalVisible, setIsLoginModalVisible] = useState(false);

  useEffect(() => {
    getTrainerArticle(username, article);
  }, []);

  async function getTrainerArticle(username, articleName) {
    const response = await getTrainerByName(username);

    if(!response.success) {
      window.location.href = "error/404";
      return;
    }

    setTrainer(response.body);
    
    console.debug("get trainer by id result: ", response.body);

    const article = response.body.articles?.find((article) => {
      return formatName(article.name) === formatName(articleName);
    });

    setTrainerArticle(article);

    // temp fix to increment article views
    await getArticleById(article.id);

    console.debug("get trainer article result: ", article);
  }

  function onDownloadExampleClickHandler() {
    if(!trainerArticle?.exampleUri?.[0]) {
      return
    };

    window.location.href = trainerArticle?.exampleUri?.[0];
  }

  function onRelatedArticleClickHandler(relatedArticleName) {
    // TODO podle jmena trenera
    // window.location.href = `${formatName(article.name)}/${formatName(relatedArticleName)}`;
  }

  function onPurchaseClickHandler() {
    if(!user.isLoggedIn && !user.email) {
      setIsLoginModalVisible(true);
      return;
    }

    window.location.href = `/checkout/${trainerArticle.type == "MONTHLY_SUBSCRIPTION" ? "subscription" : "package"}/${trainerArticle.id}`;
  }

  function onTrainerClickHandler() {
    const formattedString = trainer.name
      .normalize("NFD") // Normalize the string to decompose accented characters
      .replace(/[\u0300-\u036f]/g, "") // Remove diacritics
      .toLowerCase() // Convert to lowercase
      .replace(/\s+/g, "-") // Replace spaces with hyphens
      .replace(/[^a-zA-Z0-9-]/g, ""); // Remove non-alphanumeric characters except hyphen

    window.location.href = `/${formattedString}`;
  }

  function onEditClickHandler() {
    window.location.href = `/trainer/package/update/${trainerArticle.id}`;
  }

  const isOwner = user?.username === trainer.name;

  const categoriesMapped = trainerArticle.categories?.map((focus) => {
    return {
      text: focus.name,
      src: focus.imageUri,
    }
  }) || [];

  const achievementMapped = trainerArticle.achievements?.map((achievement) => {
    return {
      text: achievement,
      src: ArchivementIcon,
    }
  }) || [];


  const requirementMapped = trainerArticle.requirements?.map((requirement) => {
    return {
      text: requirement,
      src: QualificationIcon,
    }
  }) || [];

  const relatedArticlesMapped = trainerArticle.relatedArticles?.map((relatedArticle) => {
    return {
      title: relatedArticle.name,
      description: relatedArticle.description,
      src: relatedArticle.imageUri?.[0] || "",
    }
  }) || [];

  return (
    <ArticleDetailPage
      id={trainerArticle.id}
      type={trainerArticle.type}
      name={trainerArticle.name}
      about={trainerArticle.about}
      price={trainerArticle.price}
      categories={categoriesMapped}
      achievements={achievementMapped}
      requirements={requirementMapped}
      articleImage={trainerArticle?.imageUri?.[0]}
      relatedArticles={relatedArticlesMapped}
      trainer={trainer}
      isLoginModalVisible={isLoginModalVisible}
      isOwner={isOwner}
      isActive={trainerArticle.status === "ACTIVE"}
      setIsLoginModalVisible={setIsLoginModalVisible}
      onDownloadExampleClick={onDownloadExampleClickHandler}
      onRelatedArticleClick={onRelatedArticleClickHandler}
      onPurchaseClick={onPurchaseClickHandler}
      onTrainerClick={onTrainerClickHandler}
      onEditClick={onEditClickHandler}
    />
  );
}

export default ArticleDesignScreen;
