import React, { useState , useContext, useEffect} from "react";
import { Link } from "react-router-dom";
import LogoIcon from "../../assets/icons/Logo-Icon.svg";
import LogoDarkIcon from "../../assets/icons/Logo-Dark-Icon.svg";
import HamburgerIcon from "../../assets/icons/Hamburger-Icon.svg";
import HamburgerDarkIcon from "../../assets/icons/Hamburger-Dark-Icon.svg";
import User from "../../assets/icons/User-Icon.svg";
import CloseIcon from "../../assets/icons/Close-Icon.svg";
import { GlobalContext } from "../../providers/GlobalContext";
import LoginModal from "../modal/LoginModal";
import "./Navbar.css";
import Icon from "../Icon";
import { formatName } from "../../utils/FormatUtils";
import { navigate } from "../../utils/RoutingUtils";

export default function Navbar() {
  const {user, setUser} = useContext(GlobalContext);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoginModalVisible, setIsLoginModalVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const isAdmin = user?.isAdmin
  const isAutenticated = Boolean(user?.username || user.isLogged)
  const isTrainer = user?.trainer?.length > 0
  const currentLink = window.location.pathname;

  const clientLinks = [
    {link: "/home", text: "Domov"},
    {link: "/shop/trainer", text: "Obchod"},
    {link: "/package", text: "Moje plány"},
    {link: "/profile", text: "Nastavení"},
    isAdmin && {link: "/admin/list", text: "Admin"},
  ];

  const trainerLinks = [
    {link: "/home", text: "Domov"},
    {link: "/shop/trainer", text: "Obchod"},
    {link: "/package", text: "Moje plány"},
    {link: "/trainer/dashboard", text: "Profil"},
    {link: "/profile", text: "Nastavení"},
    isAdmin && {link: "/admin/list", text: "Admin"},
  ];

  const anonymousLinks = [
    {link: "/home", text: "Domov"},
    {link: "/shop/trainer", text: "Obchod"},
    {link: "/package", text: "Moje plány"},
    {link: "/trainer/welcome", text: "Jsem trenér"},
  ];

  const LightModeLinks = [
    "/profile",
    "/profile/edit/info",
    "/register",
    "/checkout",
    "/training",
    "/subscription",
    "/package",
    "/shop/training",
    "/shop/subscription",
    "/shop/package",
    "/shop/trainer",
    "/filter",
    "/questions",
    "/trainer/welcome",
    "/trainer/register",
    "/trainer/init",
    "/trainer/edit/info",
    "/trainer/edit/bank",
    "/trainer/edit/certificate",
    "/trainer/package/create",
    "/trainer/package/update",
    "/trainer/affiliate/",
    "/admin/list",
    "/error",
  ];

  const LightModeBlacklistLinks = [
    /^\/subscription\/\w+$/,
    /^\/filter\/\w+\/(\d,?)+\/result$/,
  ];

  const HiddenLinks = [
    "/register", 
  ];

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 800);
  }, []);

  

  const isLightMode = Boolean(LightModeLinks.find((link) => currentLink.startsWith(link))) && !Boolean(LightModeBlacklistLinks.find((link) => link.test(currentLink)));
  const isHidden = Boolean(HiddenLinks.find((link) => currentLink.startsWith(link)));
  const links = isAutenticated ? isTrainer ? trainerLinks : clientLinks : anonymousLinks;

  function isLinkActive(link) {
    return currentLink.startsWith(link) ? "navbar__opened_h3_active" : "navbar__opened_h3_inactive"
  }

  function onMenuClick() {
    setIsOpen((prevState) => !prevState);
  }

  function onLinkClick(link) {
    navigate(link, true);
  }

  function onLoginClick() {
    setIsOpen(false);
    setIsLoginModalVisible(true);
  }

  const Button = ({title, onClick, theme}) => {
    return (
      <div className={theme == "light" ? "light_navbar__button" : "navbar__button"} onClick={onClick}>
        <p className={theme == "light" ? "light_navbar__button_h4" : "navbar__button_h4"}>{title}</p>
      </div>
    )
  }



  if(isLightMode) {
  
    return (
      <div className="light_navbar">
        <div className={isOpen ? "navbar__opened": undefined}> 
          <div className={isOpen ? "navbar__container" : "light_navbar__container"}>
    
            {isOpen && <Icon src={LogoIcon} alt="logo" className="navbar__logo" onClick={() => onLinkClick("/home")} />}
            {!isOpen && <Icon src={LogoDarkIcon} alt="logo dark" className="navbar__logo" onClick={() => onLinkClick("/home")} />}
    
            <div className="navbar__container_links">
              {(!isAutenticated && !loading && !isHidden && !isOpen) && <Button title={"Přihlásit se"} onClick={onLoginClick} theme={"light"} />}
              <div className="navbar__hamburger" onClick={onMenuClick}>
                {isOpen && <Icon src={CloseIcon} alt="opened menu" className="navbar_icon_small" />}
                {!isOpen && <Icon src={HamburgerDarkIcon} alt="closed menu" className="navbar_icon_small" />}
              </div>
            </div>

          </div>

          {isOpen && links.map((link) => (
                <h5 key={link.link} className={isLinkActive(link.link)} onClick={() => onLinkClick(link.link)}>{link.text}</h5>
          ))}
          {(isOpen && !isAutenticated) && <h5 className={"navbar__opened_h3_inactive"} onClick={onLoginClick}>Přihlásit se</h5>}
          {/* {isOpen && isAutenticated && <h5 className={"navbar__opened_h3_logout"} onClick={onLogoutClick}>Odhlásit se</h5>} */}


        </div>

        <LoginModal 
          redirect={currentLink.replace(/^\//, '').replace(/\//g, '_')}
          isVisible={isLoginModalVisible} 
          setIsVisible={setIsLoginModalVisible}
        />
      </div>
    );
  }


  return (
    <div className="navbar">
      <div className="navbar__shadow"/>
      <div className={isOpen ? "navbar__opened" : undefined}> 
        <div className="navbar__container">

          <Icon src={LogoIcon} alt="logo" className="navbar__logo" onClick={() => onLinkClick("/home")} />


          <div className="navbar__container_links">
              {(!isAutenticated && !loading && !isHidden && !isOpen) && <Button title={"Přihlásit se"} onClick={onLoginClick} />}
              <div className="navbar__hamburger" onClick={onMenuClick}>
                {isOpen && <Icon src={CloseIcon} alt="opened menu" className="navbar_icon_small" />}
                {!isOpen && <Icon src={HamburgerIcon} alt="closed menu" className="navbar_icon_small" />}
              </div>
          </div>
          
        </div>

        {isOpen && links.map((link) => (
              <h5 key={link.link} className={isLinkActive(link.link)} onClick={() => onLinkClick(link.link)}>{link.text}</h5>
        ))}
        {(isOpen && !isAutenticated) && <h5 className={"navbar__opened_h3_inactive"} onClick={onLoginClick}>Přihlásit se</h5>}
        {/* {isOpen && isAutenticated && <h5 className={"navbar__opened_h3_logout"} onClick={onLogoutClick}>Odhlásit se</h5>} */}

      </div>

      <LoginModal 
        redirect={currentLink.replace(/^\//, '').replace(/\//g, '_')}
        isVisible={isLoginModalVisible} 
        setIsVisible={setIsLoginModalVisible}
      />
    </div>
  );
}
