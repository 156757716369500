import React from "react";
import './AdminListPage.css';
import {SwitchHeader} from "../../components/Navbar/SwitchHeader";
import ButtonPrimary from "../../components/Buttons/Primary/ButtonPrimary";
import Icon from "../../components/Icon";
import ProductBoxGrid from "../../components/Boxes/ProductBoxGrid";
import TrainerBox from "../../components/Boxes/TrainerBox";

export default function AdminListPage(props) {
  const {height, headerValues, layout, trainers, users, onUserClick, onTrainerClick, onTrainerLoginClick, onTrainerActivateClick} = props;



  return (
    <>
      <SwitchHeader values={headerValues}/>
      <div className="admin_list_page" style={{minHeight: height - 64}}>
              <div className="admin_list_page__body">
                <div className="admin_list_page__body_box">
                      {layout == "TRAINER" &&
                        <div className="admin_list_page__body_box_trainer">
                          {trainers.map((trainer, index) => 
                          <div key={index} className="admin_list_page__body_box_trainer_box">
                            <TrainerBox  
                              key={index}
                              title={trainer?.name}
                              description={"Status: " + trainer?.status}
                              email={trainer?.email}
                              phone={trainer?.phoneNumber}
                              src={trainer?.imageUri?.length > 0 && trainer?.imageUri[0]}
                              onClick={onTrainerClick}
                            />
                            <ButtonPrimary title="Aktivovat" onClick={() => onTrainerActivateClick(trainer?.id)} />
                            <ButtonPrimary title="Přihlásit" onClick={() => onTrainerLoginClick(trainer?.id)} />
                          </div>
                          )}
                        </div>
                      }
                </div>
              </div>
      </div>
    </>
  );

}

