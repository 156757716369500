import React, {useEffect} from "react";
import "./BottomNavbar.css";
import ButtonPrimary from "../Buttons/Primary/ButtonPrimary";
import Loader from "../Loader";

export function BottomNavbar(props) {
  const { title, description, buttonText, onClick, disabled } = props;

  useEffect(() => {
    document.body.style.paddingBottom = '80px'; 

    return () => {
      document.body.style.paddingBottom = '';
    };
  }, []); 

  return (
    <div className="bottom_navbar">
      <div className="bottom_navbar__left">
        <h5 className="bottom_navbar__left_h3">{title}</h5>
        <p className="bottom_navbar__left_h4">{description}</p>
      </div>
      <div className="bottom_navbar__right">
        <div className="bottom_navbar__right_box">
          <ButtonPrimary title={buttonText} onClick={onClick} disabled={disabled}  />
        </div> 
      </div>
    </div>
  );
}


export function BottomNavbarProgress(props) {
  const { backButtonText, submitButtonText, backButtonDisabled, submitButtonDisabled, onBackClick, onSubmitClick, isLoading } = props;

  useEffect(() => {
    document.body.style.paddingBottom = '80px'; 

    return () => {
      document.body.style.paddingBottom = '';
    };
  }, []); 
  
  return (
    <div className="bottom_navbar">
      <div className="bottom_navbar__left">
       {!backButtonDisabled && 
          <button className="bottom_navbar_progress__left_button" onClick={onBackClick}>
            <h5 className="bottom_navbar_progress__left_button_h3" >{backButtonText}</h5>
          </button> 
        }
      </div>
      <div className="bottom_navbar__right">
        {(submitButtonDisabled || isLoading) 
        ? 
        <button className="bottom_navbar_progress__right_button_disabled">
          {isLoading 
          ? <Loader/>
          : <h5 className="bottom_navbar__right_button_disabled_h3" >{submitButtonText}</h5>
        }
        </button>
        : 
        <button className="bottom_navbar_progress__right_button" onClick={onSubmitClick}>
          <h5 className="bottom_navbar__right_button_h3" >{submitButtonText}</h5>
        </button>
         }
      </div>
    </div>
  );
}

export function BottomNavbarDouble(props) {
  const { leftButtonText, leftButtonOnClick, rightButtonText, rightButtonOnClick } = props;

  useEffect(() => {
    document.body.style.paddingBottom = '80px'; 

    return () => {
      document.body.style.paddingBottom = '';
    };
  }, []); 

  return (
    <div className="bottom_navbar_double">
        <ButtonPrimary title={leftButtonText} onClick={leftButtonOnClick} type={"outlined_light"}  />

        <ButtonPrimary title={rightButtonText} onClick={rightButtonOnClick} />

    </div>
  );
}