import { useState } from 'react';
import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';
import './PDFViewer.css';
import UseDimensions from '../../hooks/UseDimensions';



pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

export default function PDFViewer({pdfPath}) {
  const [numPages, setNumPages] = useState();
  const {width} = UseDimensions();

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const finalWidth = (width > 900 ? 900 : width) - 48;

  return (
    <div className='pdf_viewer'>
      <div className='pdf_viewer_container'>
        <Document file={pdfPath} onLoadSuccess={onDocumentLoadSuccess}>
          {Array.from(new Array(numPages), (el, index) => {
            return (
              <>
                  <Page width={finalWidth} className='pdf_viewer_container_page' pageNumber={index + 1} />
                  <div className='pdf_viewer_container_page' style={{height: '24px'}}></div>
              </>
            );
          })
          }
        </Document>
      </div>
    </div>
  );
  
}