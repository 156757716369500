import React from "react";
import "./ProfileEditInfoPage.css";
import TextInput from "../../components/TextInput/TextInput";
import SubmitButton from "../../components/Buttons/Submit/SubmitButton";
import { Link } from "react-router-dom";
import Loader from "../../components/Loader";
import ButtonPrimary from "../../components/Buttons/Primary/ButtonPrimary";
import PaymentCardComponent from "../../components/PaymentCardComponent";
import EmailIcon from "../../assets/icons/Email-Grey-Icon.svg";
import parsePhoneNumberFromString from "libphonenumber-js";
import SuccessIcon from "../../assets/icons/Success-Icon.svg";
import Icon from "../../components/Icon";

export default function ProfileEditInfoPage(props) {
  const Error = ({ error }) => {
    if (!error) {
      return undefined;
    }

    return (
      <div className="profile_edit_info_page_message" >
      <p>{error}</p>
      </div>
    );
  };

  return (
    <div className="profile_edit_info_page__container" style={{minHeight: window.innerHeight}}>
        <h3 className="profile_edit_info_page__title">Osobní údaje</h3>
        <TextInput 
          value={props.username}
          onChange={props.onUsernameChange}
          placeholder={"Jméno a příjmení"}
          icon={EmailIcon} 
          type="text"
          theme="outlined_light"
          validator={(value) => value?.length > 2 && value?.length < 30 &&  value?.split(" ").length > 1 && value?.split(" ").length <= 3 && value?.split(" ").pop() != ""} 
          underlineText="Abychom věděli, jak tě oslovovat"
          errorText="Uživatelské jméno musí mít 3 až 30 znaků"
        />

        <TextInput
          value={props.email}
          onChange={props.onEmailChange}
          placeholder={"E-mail"}
          type="email"
          icon={EmailIcon} 
          theme={"outlined_light"} 
          validator={(value) => value.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g)} 
          underlineText="Abys mohl dostávat potvrzení objednávek"
          errorText="E-mail není ve nesprávném formátu"
        />

        <TextInput
          value={props.phone}
          onChange={props.onPhoneChange}
          type="tel"
          placeholder={"Telefonní číslo"}
          icon={EmailIcon} 
          theme={"outlined_light"} 
          validator={(value) => value.match(/^\+\d+ \d{3} \d{3} \d{3}$/) && parsePhoneNumberFromString(value)?.isValid()}
          underlineText="Aby tě trenér měl jak kontaktovat"
          errorText="Telefonní číslo není ve správném formátu"
        />

       {props.showPassword && <TextInput
          value={props.password}
          onChange={props.onPasswordChage}
          placeholder={"Heslo"}
          type="password"
          icon={EmailIcon} 
          theme={"outlined_light"} 
          validator={(value) => value.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d!@#$%^&*\-]{8,}$/g) || value == "********"} 
          underlineText="Abys měl vše v bezpečí"
          errorText="Heslo musí obsahovat velké písmeno, číslo a mít alespoň 8 znaků"
        />}

        <Error error={props.error} />

        <ButtonPrimary 
        title={props.isLoading ? <Loader /> :  "Uložit"} 
        onClick={props.onButtonClick}
        disabled={props.submitDisabled} 
      />
    </div>
  );
}
