import React, { useState, useContext, useEffect } from 'react';
import PaymentEditPage from '../pages/PaymentEditPage/PaymentEditPage';
import { GlobalContext } from '../providers/GlobalContext';
import { putUser } from '../services/UserService';
import { getPaymentMethods, deletePaymentMethod } from '../services/ClientService';

export default function PaymentEditScreen() {
  const {user, setUser} = useContext(GlobalContext);
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [paymentCards, setPaymentCards] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    getPaymentCards();
  }, [])

  async function getPaymentCards() {
    const response = await getPaymentMethods(user.id);

    if(!response.success) {
      console.error(response.message)
      setError(response.message)
      return;
    }

    setPaymentCards(response.body);
    setIsLoading(false);

    console.debug("get payment cards result: ", response);
  }

  async function onButtonClick() {
    setIsLoading(true);

    const body = {
      username: username ? username : undefined,
      email: email ? email : undefined,
      phoneNumber: phone ? phone : undefined,
      password: password ? password : undefined
    }

    const registerResult = await putUser(body);
  
    if(!registerResult.success) {
      console.log(registerResult.message)
      setError(registerResult.message)
      setIsLoading(false);
      return;
    }


    setUser({...user, ...registerResult.body})
    setUsername("");
    setEmail("");
    setPhone("");
    setPassword("");

    setError("")
    setIsLoading(false);
  }

  async function onDeleteClick(id) {
    const deleteResponse = await deletePaymentMethod(id);

    if(!deleteResponse.success) {
      console.log(deleteResponse.message)
      setError(deleteResponse.message)
      return;
    }

    await getPaymentCards();    
  }

  function onUsernameChange(username) {
    setUsername(username.target.value);
  }

  function onEmailChange(email) {
    setEmail(email.target.value);
  }

  function onPhoneChange(phone) {
    setPhone(phone.target.value);
  }

  function onPasswordChange(password) {
    setPassword(password.target.value);
  }

  return (
    <PaymentEditPage
      username={username}
      email={email}
      phone={phone}
      password={password}
      usernamePlaceholder={user.username}
      emailPlaceholder={user.email}
      phonePlaceholder={user.phoneNumber}
      passwordPlaceholder={"************"}
      paymentCards={paymentCards}
      onUsernameChange={onUsernameChange}
      onEmailChange={onEmailChange}
      onPhoneChange={onPhoneChange}
      onPasswordChage={onPasswordChange}
      onButtonClick={onButtonClick}
      onDeleteClick={onDeleteClick}
      isLoading={isLoading}
      error={error}
    />
  );
}
