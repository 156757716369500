import { createContext } from "react";

type User = {
  isLogged: boolean;
  language: "en" | "cs";
  token: string;
  name: string;
}

export type GlobalContextType = {
  user: User,  
  setUser: (user: User) => void;
}

export const GlobalContext = createContext({} as GlobalContextType);
