import React, {useState, useRef} from 'react';
import { motion, useAnimation } from 'framer-motion';
import './FilterResultPage.css';
import {BottomNavbarProgress} from '../../components/Navbar/BottomNavbar';
import { ChooseInputDescription } from '../../components/TextInput/ChooseInput';
import Icon from '../../components/Icon';
import VerificationIcon from '../../assets/icons/Verification-Icon.svg';
import ButtonPrimary from '../../components/Buttons/Primary/ButtonPrimary';
import ArrowDownIcon from '../../assets/icons/Arrow-Down-Icon.svg';
import Loader from '../../components/Loader';
import { Footer } from '../../components/Navbar/Footer';

// Dot Component
function Dots ({totalIndex, pageIndex}) {
    const Dot = ({ isActive }) => (
    <motion.div
        style={{
            backgroundColor: isActive ? 'white' : '#B7BDC9'
        }}
        className='dots__dot'
        animate={{ 
            height: isActive ? 24 : 8,
         }}
    />
    );

    return (
    <div className='dots'>
        {Array.from({ length: totalIndex + 1 }, (_, index) => (
        <Dot key={index} isActive={index === pageIndex} />
        ))}
    </div>
    );
}

function FilterResultPage({ pageHeight, pageIndex, pageRef, isLoaded, trainers, onTrainerClick }) {
  const totalIndex = trainers.length;

  return (
          <div className="filter_result_page" style={{height: pageHeight}} ref={pageRef}>
              {/* <Dots totalIndex={totalIndex} pageIndex={pageIndex}/> */}
              <div className="filter_result_page__results" style={{height: pageHeight}}>
                    <div className='filter_result_page__results_head'>
                        <h2 className='filter_result_page__head_h2'>{isLoaded ? "Nalezeno" : "Hledáme"}</h2>
                        <h4 className='filter_result_page__head_h4'>{isLoaded ? "Tito trenéři ti budou nejlépe vyhovovat" : "Hledáme pro tebe ideální trenéry"}</h4>
                    </div>
                    <div className='filter_result_page__body'>
                        {isLoaded && <Icon alt="search" src={ArrowDownIcon} className="filter_result_page__body_icon" />}
                        {!isLoaded && <Loader width={40}/>}
                    </div>
                </div>
              {trainers.map((trainer, index) => (
                  <div key={index} className="filter_result_page__container" style={{height: pageHeight}}>
                      <div className='filter_result_page__head'>
                        <div className='filter_result_page__head_header'>
                            <Icon 
                                className='filter_result_page__head_header_image'
                                alt={`trainer ${trainer.name}`} 
                                src={trainer.imageUri[0]}
                            />
                            <div className='filter_result_page__head_header_shadow'/>
                            <div className='filter_result_page__head_header_shadow_right'/>
                            <h1 className='filter_result_page__head_header_h1'>
                                {(trainer.name?.split(' ') || []).map((part, index) => (
                                    index + 1 != (trainer.name?.split(' ') || []).length ? <span key={index}>{part}</span> : <span key={index}>{part}<Icon alt="verified" src={VerificationIcon} className="filter_result_page__head_header_icon" /></span>
                                ))}
                            </h1>
                        </div>
                        <div className="filter_result_page__head_box">
                            <h4 className='filter_result_page__head_box_h3'>{trainer.description}</h4>
                        </div>
                    </div>
                    <div className='filter_result_page__body'>
                        <ButtonPrimary title={"Přejít na profil trenéra"} onClick={() => onTrainerClick(trainer.name)} />
                    </div>
                  </div>
              ))}
            {/* <div className="filter_result_page__container" style={{height: pageHeight / 2}}>
                <Footer show/>
            </div> */}
          </div>
  );
}

export default FilterResultPage;
