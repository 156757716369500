import React from "react";
import "./LoginPage.css";
import TextInput from "../../components/TextInput/TextInput";
import SubmitButton from "../../components/Buttons/Submit/SubmitButton";
import { Link } from "react-router-dom";
import Loader from "../../components/Loader";

export default function LoginPage(props) {
  const Error = ({ error }) => {
    if (!error) {
      return <div className="login-page_message" />;
    }

    return (
      <div className="login-page_message_container">
        <div className="login-page_message">
          {/* <AlertIcon className="login_page__icon"/> */}
          <p>{error}</p>
        </div>
      </div>
    );
  };


  return (
    <div className="login_page_container">
      <h1 className="login_page_title">Přihlášení</h1>
      <div className="login_page_form">
        <TextInput
          value={props.email}
          onChange={props.onEmailChange}
          placeholder={"E-mail"}
        />

        <TextInput
          value={props.password}
          onChange={props.onPasswordChage}
          placeholder={"Heslo"}
          type="password"
        />
      </div>

      <div>
        <Error error={props.error} />
      </div>

      <div className="login_page_bottom">
        <SubmitButton
          type="submit"
          title={props.isLoading ? <Loader /> : "Přihlásit se"}
          onClick={props.onButtonClick}
        />
        <SubmitButton
          type="submit"
          title={props.isLoading ? <Loader /> : "Přihlásit Google účtem"}
          onClick={props.onGoogleButtonClick}
        />
        <div className="login_page_other_links">
          <Link to="/register">
            <p className="login_page_link">Registrovat se</p>
          </Link>
          <p>
            <b>●</b>
          </p>
          <Link to="/">
            <p className="login_page_link">Zapomenuté heslo</p>
          </Link>
        </div>
      </div>
    </div>
  );
}
