import React, { useState, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { GlobalContext } from "../providers/GlobalContext";
import UseDimensions from "../hooks/UseDimensions";
import BadRequestPage from "../pages/BadRequestPage/BadRequestPage";
import PackageBoxBoldIcon from "../assets/icons/Package-Box-Bold-Icon.svg";
import PackageBoxClosedIcon from "../assets/icons/Package-Box-Closed-Icon.svg";
import WarningIcon from "../assets/icons/Warning-Icon.svg";

function BadRequestScreen(props) {
  const defaultError = {
    code: "Chyba",
    icon: WarningIcon,
    title: "Něco se pokazilo",
    message: "Zkus to znovu, nebo chvíli počkej",
  };

  const accessDeniedError = {
    code: "403",
    icon: PackageBoxClosedIcon,
    title: "Přístup zamítnut",
    message: "Na tuto stránku nemáš dostatečné oprávnění. Zkontroluj zda jsi přihlášený a máš dostatečné oprávnění.",
  };

  const notFoundError = {
    code: "404",
    icon: PackageBoxBoldIcon,
    title: "Stránka nenalezena",
    message: "Stránka, kterou hledáš, neexistuje.",
  };

  const location = useLocation();
  const headerValue = location.pathname;
  const navigate = useNavigate();

  const { user, setUser } = useContext(GlobalContext);
  const [isLoginModalVisible, setIsLoginModalVisible] = useState(false);
  const { height } = UseDimensions();
  const [errCode, setErrCode] = useState(props.errCode);
  const [errIcon, setErrIcon] = useState(props.errIcon);
  const [errTitle, setErrTitle] = useState(props.errTitle);
  const [errMessage, setErrMessage] = useState(props.errMessage);

  function setBadRequestParamsDefault() {
    setErrCode(defaultError.code);
    setErrIcon(defaultError.icon);
    setErrTitle(defaultError.title);
    setErrMessage(defaultError.message);
  }

  function setBadRequestParams403() {
    setErrCode(accessDeniedError.code);
    setErrIcon(accessDeniedError.icon);
    setErrTitle(accessDeniedError.title);
    setErrMessage(accessDeniedError.message);
  }

  function setBadRequestParams404() {
    setErrCode(notFoundError.code);
    setErrIcon(notFoundError.icon);
    setErrTitle(notFoundError.title);
    setErrMessage(notFoundError.message);
  }

  function rerouteHome() {
    // navigate("/home"); // Když se přeroutuje, nezmění se barva navbaru
    window.location.href = "/home"; // possible fix aby se změnila barva navbaru???
  }

  useEffect(() => {
    switch (headerValue) {
      case "/error/default":
        setBadRequestParamsDefault();
        break;
      case "/error/403":
        setBadRequestParams403();
        break;
      case "/error/404":
        setBadRequestParams404();
        break;
      default:
        setBadRequestParamsDefault();
    }
  }, [headerValue]);

  return (
    <BadRequestPage
      height={height}
      errCode={errCode}
      errIcon={errIcon}
      errTitle={errTitle}
      errMessage={errMessage}
      isLoginModalVisible={isLoginModalVisible}
      setIsLoginModalVisible={setIsLoginModalVisible}
      rerouteHome={rerouteHome}
    />
  );
}
export default BadRequestScreen;
