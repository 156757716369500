import React from "react";
import "./LoadingPage.css";
import Icon from "../../components/Icon";
import LogoIcon from "../../assets/icons/Logo-Icon.svg";
import InfiniteLoader from "../../components/decorations/InfiniteLoader";

export default function LoadingPage(props) {
  
  return (
    <div className="loading_page">
      <div className="loading_page__container">
        <Icon src={LogoIcon} alt="logo" className="loading_page__logo" />
        <InfiniteLoader duration={props.duration}/>
      </div>
    </div>
  );
}
