import React from "react";
import "./DocumentPage.css";
import PDFViewer from "../../components/PDFViewer/PDFViewer";

function DocumentPage({path}) {

  return (
    <>
        <PDFViewer pdfPath={path} />
    </>
  );
}
export default DocumentPage;