import React from 'react';
import './FilterPage.css';
import {BottomNavbarProgress} from '../../components/Navbar/BottomNavbar';
import { ChooseInputDescription } from '../../components/TextInput/ChooseInput';

function FilterPage(props) {
  const { currentValue } = props;

  return (
      <div className="filter_page"  style={{minHeight: props.height - 64}}>

          <div className="filter_page__layout">          
            <h2 className="filter_page__title">{currentValue?.title}</h2>
            <h4 className="filter_page__subtitle">{currentValue?.description}</h4>

            <ChooseInputDescription values={currentValue?.values} selected={props.selected} onClick={props.onSelectClick} />
          </div>

          <BottomNavbarProgress 
            backButtonText={"Zpět"}
            submitButtonText={"Pokračovat"}
            backButtonDisabled={props.backButtonDisabled}
            submitButtonDisabled={props.submitButtonDisabled}
            onBackClick={props.onBackClick}
            onSubmitClick={props.onSubmitClick}
          />
      </div>
    );
}

export default FilterPage;
