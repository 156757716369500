import React, { useState, useContext } from "react";
import LoginPage from "../pages/LoginPage/LoginPage";
import { GlobalContext } from "../providers/GlobalContext";
import { login, loginGoogle } from "../services/UserService";
import { useGoogleLogin } from '@react-oauth/google';

function LoginScreen() {
  const { user, setUser } = useContext(GlobalContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  async function responseGoogle(response) {
    console.log("tokenResponse", response)

    const result = await loginGoogle(response.access_token);

    if (!result.success) {
      console.log(result.message);
      setError(result.message);
      setIsLoading(false);
      return;
    }

    console.log(result);
    console.log(result.body.token)

    setUser({
      isLoggedIn: true,
      token: result.body.token,
      name: "Username",
      language: "cs",
    });

      setError("sucess");
      setIsLoading(false);
  }
  
  const googleFailure = (error) => {
    console.error('Failed to login with Google:', error);
  };

  const onGoogleButtonClick = useGoogleLogin({
    onSuccess: responseGoogle,
    onFailure: googleFailure,
    scope: 'openid profile email',
  });


  async function onButtonClick() {
    setIsLoading(true);

    const data = {
      email: email,
      password: password,
    };

    const result = await login(data.email, data.password);

    if (!result.success) {
      console.log(result.message);
      setError(result.message);
      setIsLoading(false);
      return;
    }

    console.log(result);
    console.log(result.body.token)

    setUser({
      isLoggedIn: true,
      token: result.body.token,
      name: "Username",
      language: "cs",
    });

    setError("sucess");
    setIsLoading(false);
  }

  function onEmailChange(email) {
    setEmail(email.target.value);
  }

  function onPasswordChange(password) {
    setPassword(password.target.value);
  }

  return (
    <LoginPage
      email={email}
      password={password}
      onEmailChange={onEmailChange}
      onPasswordChage={onPasswordChange}
      onButtonClick={onButtonClick}
      onGoogleButtonClick={onGoogleButtonClick}
      isLoading={isLoading}
      error={error}
    />
  );
}
export default LoginScreen;
