export function formatName(name) {
  return name
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, "")
    .toLowerCase()
    .replace(/[^a-z0-9]/g, '-');
}

/**
 * transforms 1000 to 1 000,00
 * @param {*} price 
 */
export function formatPrice(price, includeDecimals = false, showFree = true) {
  if (!price && showFree) {
    return "Zdarma"
  }

  if (!price) {
    return includeDecimals ? "0,00 Kč" : "0 Kč";
  }

  let [integerPart, decimalPart] = price.toString().split('.');

  integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  if (!includeDecimals) {
    return integerPart + " Kč"; // Return here if decimals are not to be included
  }

  decimalPart = decimalPart ? decimalPart : "00";

  // Ensure two decimal places
  decimalPart = decimalPart.length === 1 ? `${decimalPart}0` : decimalPart;
  decimalPart = decimalPart.length > 2 ? decimalPart.substring(0, 2) : decimalPart;

  return `${integerPart},${decimalPart} Kč`;
}


/**
 * format phone number from +4200123456789 to +420 012 345 6789
 * @param {*} phone 
 */
export function formatPhone(phone) {
  if(!phone) return "";

  return phone.replace(/(\d{3})(\d{3})(\d{3})(\d{3})/, "$1 $2 $3 $4");
}

export function breakAfterWord(str, length) {
  if (!str || str.length <= length) return str;

  const subString = str.substring(0, length);
  const lastSpaceIndex = subString.lastIndexOf(' ');

  return lastSpaceIndex > 0 ? str.substring(0, lastSpaceIndex) + '...' : subString + '...';
};

/**
 * format date from 2021-10-12T00:00:00.000Z to 12.10.2021
 * @param {*} dateTime 
 * @returns 
 */
export function formatDate(dateTime) {
  if (!dateTime) return "";

  const date = new Date(dateTime);
  return date.toLocaleDateString("cs-CZ");
}


/**
 * format url from "https://www.google.com/document/1.txt" to "www.google.com:document:1.txt"
 */
export function formatUrl(url) {
  if (!url) return "";

  return url.replace(/https?:\/\//, "").replace(/\//g, ":");
}