import React, { useState, useEffect, useContext } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { PaymentElement, Elements, useStripe, useElements } from '@stripe/react-stripe-js';
import CheckoutPage from '../pages/CheckoutPage';
import { postSubscription } from '../services/SubscriptionService';
import { addPaymentMethod } from '../services/ClientService';
import { getArticleById } from '../services/ArticleService';
import { useParams } from 'react-router-dom';
import { STRIPE_PUBLIC_KEY } from '../constants/Api';
import { getPaymentMethods, deletePaymentMethod } from '../services/ClientService';
import { GlobalContext } from '../providers/GlobalContext';

export default function CheckoutScreen() {
  const {user, setUser} = useContext(GlobalContext);
  const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);
  const { type, id } = useParams();
  const [subscription, setSubscription] = useState();
  const [article, setArticle] = useState();
  const [paymentCards, setPaymentCards] = useState(false);
  const sucessTimeout = 3000;

  useEffect(() => {
    getArticle(id);
    getPaymentCards();
  }, []);

  async function handlePostSubscription(id) {
    const response = await postSubscription(id);
    setSubscription(response.body)
    
    console.debug("post subscription result: ", response);
  }

  async function handlePostFreeSubscription(id) {
    const response = await postSubscription(id);
    
    console.debug("post subscription result: ", response);
  }

  async function getArticle(id) {
    const response = await getArticleById(id);

    if(!response.success) {
      console.error(response.message)
      return;
    }

    setArticle(response.body);

    console.debug("get article by id result: ", response);

    if(response.body.type != "FREE") {
      handlePostSubscription(id);
    }
  }

  async function getPaymentCards() {
    const response = await getPaymentMethods(user.id);

    if(!response.success) {
      console.error(response.message)
      return;
    }

    setPaymentCards(response.body);

    console.debug("get payment cards result: ", response);
  }

  async function onDeleteClick(pid) {
    const response = await deletePaymentMethod(pid);

    if(!response.success) {
      console.error(response.message);
      return;
    }

    getPaymentCards();
    handlePostSubscription(id);
  }

  function onConditionsClick() {
    window.location.href = "/conditionsPayment";
  }

  const PaymentForm = ({clientSecret, price, article, paymentCards}) => {
    const [screenState, setScreenState] = useState("idle");
    const stripe = useStripe();
    const elements = useElements();

  
  
    const onSubmitClickHandler = async () => {
      if(screenState != "idle") {
        return;
      }

      setScreenState("loading");

      if (!stripe || !elements) {
        console.error('Stripe has not loaded yet.');
        setScreenState("idle");
        return;
      }

      if(subscription.paymentMethod) {
   
        const { error: paymentError } = await stripe.confirmCardPayment(
          clientSecret,
          { 
            payment_method: subscription.paymentMethod,
          }
        );
      
        if(paymentError) {
          console.error("Payment error: ", paymentError);
          setScreenState("idle");
          return;
        }
  
        console.log("success");
        setScreenState("success");
        setTimeout(() => {
          if(article.subType == "TRAINING") {
            window.location.href = "/training";
            return;
          }

          if(article.subType == "PACKAGE") {
            window.location.href = "/package";
            return;
          }

          window.location.href = "/subscription";
        }, sucessTimeout);
        return;
      }
  
      const { error: elementError } = await elements.submit();
      
      if (elementError) {
        console.error("Unexpected error: ", elementError);
        setScreenState("idle");
        return;
      }
  
      const { error: paymentError, paymentIntent } = await stripe.confirmPayment({
        elements: elements,
        clientSecret: clientSecret,
        redirect: 'if_required',
        confirmParams: {
          return_url: 'http://localhost:3000',
        },
      });
    
      if(paymentError) {
        console.error("Payment error: ", paymentError);
        setScreenState("idle");
        return;
      }

      setScreenState("success");

      setTimeout(() => {
        if(article.subType == "TRAINING") {
          window.location.href = "/training";
          return;
        }

        if(article.subType == "PACKAGE") {
          window.location.href = "/package";
          return;
        }

        window.location.href = "/subscription";
      }
      , sucessTimeout);
    };
  
    return (
      <CheckoutPage onSubmitClick={onSubmitClickHandler} onConditionsClick={onConditionsClick} price={price} screenState={screenState} type={type} card={paymentCards.length > 0 && paymentCards[0]} article={article} showElement={!subscription.paymentMethod} onDeleteClick={onDeleteClick}/>
    );
  };  

  const MockPaymentForm = ({price, article}) => {
    const [screenState, setScreenState] = useState("idle");

    const onSubmitClickHandler = async () => {
      if(screenState != "idle") {
        return;
      }

      setScreenState("loading");
      
      await handlePostFreeSubscription(id);

      setScreenState("success");

      setTimeout(() => {
        window.location.href = type == "package" ? "/package" : "/subscription";
      }, sucessTimeout);
    };

    console.log("article", screenState);
    return (
      <CheckoutPage 
        onSubmitClick={onSubmitClickHandler} 
        onConditionsClick={onConditionsClick}
        price={price} 
        screenState={screenState} 
        type={"free"} 
        article={article} 
        showElement={false} 
        onDeleteClick={() => {}}
      />
    );
  };


  if(article?.type == "FREE") {
    return  <MockPaymentForm price={article.price} article={article}/>
  }

  if(!subscription?.clientSecret) {
    return <></>
  }

  const appearance = { 
    theme: 'stripe',

    rules: {
      '.Input': {
        border: '1px solid #80858D',
      },
      '.Input:hover': {
        border: '1px solid transparent',
        outline: '2px solid black',
      },
      '.Label': {
        color: '#4D4C52',
        fontSize: '12px',
        paddingBottom: '4px',
        fontWeight: '600'
      },
      '.TermsText': {
        color: '#4D4C52',
        fontSize: '12px',
        lineHeight: '16px',
        fontWeight: '600',
      }
    },

    variables: {
      colorPrimary: '#4D4C52',
      colorBackground: '#F7F7FA',
      colorText: '#4D4C52',
      colorDanger: '#E91345',
      fontFamily: 'helvetica, tahoma, calibri, sans-serif',
      spacingUnit: '4.7px',
      borderRadius: '8px',
      fontSizeBase: '16px',
    },

  };

  return (
      <Elements stripe={stripePromise} options={{clientSecret: subscription.clientSecret, appearance: appearance, locale: "cs"}}>
        <PaymentForm clientSecret={subscription.clientSecret} paymentCards={paymentCards} article={article} price={subscription.price}  />
      </Elements>
  );
}
