import React, { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom"
import FilterPage from "../pages/FilterPage/FilterPage";
import UseDimensions from "../hooks/UseDimensions";
import { getFilter } from "../services/FilterService";
import StepScreen from "./StepScreen";
import MaleIcon from "../assets/icons/Male-Icon.svg";
import FemaleIcon from "../assets/icons/Female-Icon.svg";
import OtherIcon from "../assets/icons/Other-Icon.svg";
import ForbidenIcon from "../assets/icons/Forbiden-Icon.svg";
import StudentIcon from "../assets/icons/Student-Icon.svg";
import AdultIcon from "../assets/icons/Adult-Icon.svg";
import OlderAdultIcon from "../assets/icons/Older-Adult-Icon.svg";
import SeniorIcon from "../assets/icons/Senior-Icon.svg";
import Weight1Icon from "../assets/icons/Weight-1-Icon.svg";
import Weight2Icon from "../assets/icons/Weight-2-Icon.svg";
import Weight3Icon from "../assets/icons/Weight-3-Icon.svg";
import Weight4Icon from "../assets/icons/Weight-4-Icon.svg";
import WeightIcon from "../assets/icons/Weight-Icon.svg";
import SubscriptionIcon from "../assets/icons/Subscription-Icon.svg";
import BundleIcon from "../assets/icons/Bundle-Icon.svg";
import { postArticle, getArticleById, putArticle } from "../services/ArticleService";
import { getAllTrainerCategories } from "../services/CategoryService";
import { formatName } from "../utils/FormatUtils";
import { GlobalContext } from "../providers/GlobalContext";
import { putTrainerInfo } from "../services/TrainerService";
import { goBack } from "../utils/RoutingUtils";

export default function TrainerInfoEditScreen() {
  const {user, setUser} = useContext(GlobalContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [categories, setCategories] = useState([]);
  const trainer = user?.trainer?.length > 0 ? user?.trainer[0] : {};

  useEffect(() => {
    async function fetch() {
      const response = await getAllTrainerCategories();
      if(response.success) {
        console.log("Categories: ", response.body);
        setCategories(response.body);
      }
    }

    fetch();
  }, []);

  
  const values = [
    {
      index: 0,
      type: "image",
      title: "Profilová fotka",
      description: "Jak bude vypadat tvůj profil?",
      buttonText: "Nahrát fotku",
      exists: trainer.imageUri?.length > 0 ? true : false,
      value: trainer.imageUri?.length > 0 ? { src: trainer?.imageUri[0], title: "" } : null,
    },
    {
      index: 1,
      type: "text",
      title: "Kreativní slogan",
      description: "Slouží k přitáhnutí pozornosti klienta",
      placeholder: "Slogan",
      errorText: "Slogan musí mít 5 až 50 znaků",
      validator: (value) => value?.length >= 5 && value?.length <= 50 ,
      src: WeightIcon,
      value: trainer?.description || "",
    },
    {
      index: 2,
      type: "longText",
      title: "Popis Profilu",
      description: "Kdo jsi a co klientum můžeš nabídnout?",
      placeholder: "Popis profilu",
      errorText: "Popis musí mít 30 až 2000 znaků",
      validator: (value) => value?.length >= 30 && value?.length <= 2000,
      src: OtherIcon,
      value: trainer?.about || "",
    },
    {
      index: 3,
      type: "picker",
      title: "Zaměření v bodech",
      description: "Jaké body vystihují co umíš a nabízíš?",
      maxLength: 12,
      customValue: false,
      value: trainer.focus?.length > 0 ? true : null,
      values: trainer.focus?.length > 0 
      ? categories?.map(category => {return { text: category.name, src: category.imageUri, selected: trainer?.focus?.findIndex(cat => cat.name == category.name) != -1 }})
      : categories?.map(category => {return { text: category.name, src: category.imageUri, selected: false }}),
    },
    {
      index: 4,
      type: "picker",
      title: "Úspěchy v bodech",
      description: "Co všechno jsi v karierním životě dokázal?",
      maxLength: 12,
      customValue: true,
      value: trainer.achievements?.length > 0 ? true : null,
      values: [
        {
          text: "Rok zkušeností",
          src: SubscriptionIcon,
        },
        {
          text: "3 roky zkušeností",
          src: SubscriptionIcon,
        },
        {
          text: "5 let zkušeností",
          src: SubscriptionIcon,
        },
        {
          text: "10 let zkušeností",
          src: SubscriptionIcon,
        },
        {
          text: "Účast na soutěžích",
          src: SubscriptionIcon,
        },
        {
          text: "Výhry v soutěžích",
          src: SubscriptionIcon,
        },
        ...trainer?.achievements?.map(req => {return { text: req, src: SubscriptionIcon, selected: true }}) || []
      ]
    },
    {
      index: 5,
      type: "picker",
      title: "Kvalifikace v bodech",
      description: "Jaké vzdělání a kvalifikace máš?",
      maxLength: 12,
      customValue: true,
      value: trainer.qualification?.length > 0 ? true : null,
      values: [
        {
          text: "Karlova Univerzita FTVS",
          src: SubscriptionIcon,
        },
        {
          text: "Vysokoškolské vzdělání",
          src: SubscriptionIcon,
        },
        {
          text: "Vyšší odborné vzdělání",
          src: SubscriptionIcon,
        },
        {
          text: "Středoškolské vzdělání",
          src: SubscriptionIcon,
        },
        {
          text: "Certifikovaný trenér",
          src: SubscriptionIcon,
        },
        {
          text: "Certifikovaný Fizioporadce",
          src: SubscriptionIcon,
        },
        ...trainer?.qualification?.map(ach => {return { text: ach, src: SubscriptionIcon, selected: true }}) || []
      ]
    }
  ];

  function onNextCallback(values) {
    console.log("Next");
    console.log(values);
  }

  async function onFinishCallback(values) {
    console.log("Finish");
    console.log(values);

    if(isSubmitting) {
      return;
    }

    setIsSubmitting(true);

    const body = {
      image:  values[0]?.exists ? null : [{
        fileName: values[0]?.value?.title,
        file: values[0]?.value.src.substring(values[0]?.value.src.indexOf("base64,") + 7),
      }],
      description: values[1]?.value,
      about: values[2]?.value,
      focus: values[3]?.values?.filter(value => value.selected).map(value => {return { name: value.text }}),
      achievements: values[4]?.values?.filter(value => value.selected).map(value => value.text),
      qualifications: values[5]?.values?.filter(value => value.selected).map(value => value.text),
    }




    const response = await putTrainerInfo(body);
    
    if(response.success) {
      console.log("Success");

      goBack();
    }

    if(!response.success) {
      console.log("Error");
      console.log(response.message);
      alert(response.message);
    }


    setIsSubmitting(false);
  }

  if(categories?.length == 0 || !user?.trainer?.length > 0) {
    return <div></div>
  }

  console.log("Trainer: ", trainer.length);

  return (
    <StepScreen
      values={values}
      isLoading={isSubmitting}
      onNextCallback={onNextCallback}
      onFinishCallback={onFinishCallback}
    />
  )
}