import React from "react";
import "./MenuComponent.css";
import Icon from "./Icon";
import ArrowRightIcon from "../assets/icons/Arrow-Right-Icon.svg";

export const MenuComponent = ({ items }) => {

  return (
    <div className="menu_component">
      {items.map((item, index) => {
        if(item.type === "button") {
          return (
            <Button key={index} src={item.src} title={item.title} onClick={item.onClick} />
          );
        } 

        if(item.type === "box") {
          return (
            <Box key={index} src={item.src} title={item.title} value={item.value} onClick={item.onClick} />
          );
        } 
        
        if(item.type === "line") {
          return (
            <Line key={index} />
          );
        }

        return null;
      })}
    </div>
  );
}

export const Box = ({ src, title, value, onClick }) => {
  return (
    <div className="menu_component__box" onClick={onClick} >
      <div className="menu_component__box_content">
        <Icon src={src} alt={title} className="menu_component__box_icon" />
        <h5 className="menu_component__box_h5">{title}</h5>
      </div>
      <h5 className="menu_component__box_h5">{value}</h5>
    </div>
  );
}

export const Button = ({ src, title, onClick }) => {
  return (
    <div className="menu_component__box" onClick={onClick} >
      <div className="menu_component__box_content">
        <Icon src={src} alt={title} className="menu_component__box_icon" />
        <h5 className="menu_component__box_h5">{title}</h5>
      </div>
      <Icon src={ArrowRightIcon} alt="success" className="menu_component__box_icon" />
    </div>
  );
}

export const Line = ({  }) => {
  return (
    <div className="profile_page__line"/>
  );
}