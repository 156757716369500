import React from "react";
import "./SwitchHeader.css";
import Icon from "../Icon";

const Button = ({title, src, selected, onClick, style}) => {
  return (
    <div className={selected ? "switch_header__button_selected" : "switch_header__button"} onClick={onClick} style={style}>
        <Icon src={src} alt="icon" className="switch_header__button__icon"/>
        <h5 className={selected ? "switch_header__button__text_selected" : "switch_header__button__text"}>{title}</h5>
    </div>
  );
}

const Href = ({title, src, selected, onClick, style}) => {
  return (
    <div className={selected ? "switch_header__href_selected" : "switch_header__href"} onClick={onClick} style={style}>
        <Icon src={src} alt="icon" className={selected ? "switch_header__href__icon_selected" : "switch_header__href__icon"}/>
        <h5 className={selected ? "switch_header__href__text_selected" : "switch_header__href__text"}>{title}</h5>
    </div>
  );
}


export function DinamicSwitchHeader(props) {

  return (
    <div className="dinamic_switch_header">
      <div className="dinamic_switch_header__container">
      {props.values?.map((value, index) => <Href key={index} {...value} style={{minWidth: 100}}/>)}
      </div>
    </div>
  );
}

export function SwitchHeader(props) {

  return (
    <div className="switch_header">
      {props.values?.map((value, index) => <Button key={index} {...value}/>)}
    </div>
  );
}

export function DinamicSwitch(props) {  
  return (
    <div className="dinamic_switch" {...props}>
      <div className="dinamic_switch__container">
        {props.values?.map((value, index) => <Href key={index} {...value} style={{minWidth: 140}}/>)}
      </div>
    </div>
  );
}

export function Switch(props) {

  return (
    <div className="switch">
      {props.values?.map((value, index) => <Button key={index} {...value}/>)}
    </div>
  ); 
}
