import React from "react";
import "./PaymentEditPage.css";
import TextInput from "../../components/TextInput/TextInput";
import SubmitButton from "../../components/Buttons/Submit/SubmitButton";
import { Link } from "react-router-dom";
import Loader from "../../components/Loader";
import ButtonPrimary from "../../components/Buttons/Primary/ButtonPrimary";
import PaymentCardComponent from "../../components/PaymentCardComponent";

export default function PaymentEditPage(props) {
  const Error = ({ error }) => {
    if (!error) {
      return undefined;
    }

    return (
      <div className="payment_edit_page__message_container">
        <div className="payment_edit_page__message">
          {/* <AlertIcon className="payment_edit_page___icon"/> */}
          <p>{error}</p>
        </div>
      </div>
    );
  };

  return (
    <div className="payment_edit_page" style={{minHeight: window.innerHeight}}>

    <h3 className="payment_edit_page__title">Platební údaje</h3>
      {props.paymentCards.length > 0 && props.paymentCards?.map((card, index) => 
          <PaymentCardComponent
              theme={"light"}
              key={card.id}
              id={card.id}
              brand={card.brand}
              last4={card.last4}
              validToMonth={card.toMonth}
              validToYear={card.toYear}
              onDeleteClick={props.onDeleteClick}
            />
        )}
    
      
{/* 
      <div className="payment_edit_page__form">
        <ButtonPrimary title={"Historie Nákupů"} onClick={props.onShowMoreClick} type={"outlined"} className='trainer_profile_page__head_box_button'/>
      </div> */}
    </div>
  );
}
