import EmailIcon from "../assets/icons/Email-Icon.svg";
import PhoneIcon from "../assets/icons/Phone-Icon.svg";
import InstagramIcon from "../assets/icons/Instagram-Icon.svg";
import MessangerIcon from "../assets/icons/Messanger-Icon.svg";

export const SOCIAL_ICONS = {
  instagram: InstagramIcon,
  facebook: MessangerIcon,
  email: EmailIcon,
  phone: PhoneIcon,
}
