import React, { useState, useEffect, useContext } from "react";
import { LoadingContext } from "./LoadingContext";
import LoadingScreen from "../screens/LoadingScreen";

export default function LoadingContextProvider({ children }) {
  const [isLoading, setIsLoading] = useState(true);
  const [duration, setDuration] = useState(() => sessionStorage.getItem('hasLoaded') ? 0.8 : 2);
  const fullDuration = duration * 1000;
  const animationDuration = duration - 0.2;

  useEffect(() => {
    const hasLoaded = sessionStorage.getItem('hasLoaded');

    if(!hasLoaded) {
      sessionStorage.setItem('hasLoaded', 'true');
      setDuration(0.8);
    }
  }, []);

  // Use another effect to handle the automatic hiding of the loading screen after 1 seconds
  useEffect(() => {
    if (isLoading) {
      const timeout = setTimeout(() => {
        setIsLoading(false);
      }, fullDuration);

      return () => clearTimeout(timeout);
    }
  }, [isLoading]);
  

  return (
    <LoadingContext.Provider value={{ isLoading, setIsLoading }}>
      {isLoading && <LoadingScreen duration={animationDuration} />}
      <div style={isLoading ? { height: 0, overflow: "hidden", opacity: 0 } : {}}>
        {children}
      </div>
    </LoadingContext.Provider>
  );
}