import {get, post, put} from "./ApiService";


export async function getAllTrainers() {
  const response = await get(`/trainer/all`);
  if(response.status == 201 || response.status == 200 )  {
    const {body} = await response.json();
  
    if(body.length === 0) {
      return {success: false, message: "Načtení trenérů se nezdařilo, žádný trenéri neexistují"};
    }
    
    return {success: true, body, message: "Načtení trenérů proběhlo úspěšně"};
  }

  return {success: false, message: "Načtení trenérů se nezdařilo"};
}

export async function getTrainerById(id) {
  const response = await get(`/trainer/${id}`);
  if(response.status == 201 || response.status == 200 )  {
    const {body} = await response.json();
  
    if(body.length === 0) {
      return {success: false, message: "Načtení trenéra se nezdařilo, žádný trenér s tímto id neexistuje"};
    }
    
    return {success: true, body, message: "Načtení trenéra proběhlo úspěšně"};
  }

  return {success: false, message: "Načtení trenéra se nezdařilo"};
}

export async function getTrainerByName(name) {
  const response = await get(`/trainer/all?byUsername=${name}`);
  if(response.status == 201 || response.status == 200 )  {
    const {body} = await response.json();
  
    if(body.length == 0 || body[0]?.id == null) {
      return {success: false, message: "Načtení trenéra se nezdařilo, žádný trenér s tímto jménem neexistuje"};
    }

    if(body.length > 1) {
      return {success: false, message: "Načtení trenéra se nezdařilo, existuje více trenérů s tímto jménem"};
    }

    return await getTrainerById(body[0].id);
  }

  return {success: false, message: "Načtení trenéra se nezdařilo"};
}


export async function postBankAccount(bankToken) {
  const response = await post('/trainer/bank', { bankToken });
  if(response.status == 201 || response.status == 200 )  {
    const {body} = await response.json();
  
    return {success: true, body, message: "Uložení bankovního účtu proběhlo úspěšně"};
  }

  return {success: false, message: "Uložení bankovního účtu se nezdařilo"};
}


export async function registerTrainer(gender, dateOfBirth, city, street, postcode) {
  const response = await post('/trainer/register', {gender, dateOfBirth, city, street, postcode});
  if(response.status == 201 || response.status == 200 )  {
    const {body} = await response.json();
  
    return {success: true, body, message: "Registrace proběhla úspěšně"};
  }

  console.log(await response.json());
  return {success: false, message: "Registrace neproběhla úspěšně"};
}

export async function putTrainerInfo(body) {
  const response = await put('/trainer/me', body);
  if(response.status == 201 || response.status == 200 )  {
    const {body} = await response.json();
  
    return {success: true, body, message: "Úprava údajů proběhla úspěšně"};
  }

  console.log(await response.json());
  return {success: false, message: "Úprava údajů neproběhla úspěšně"};
}

export async function getTrainerBank() {
  const response = await get('/trainer/bank');
  if(response.status == 201 || response.status == 200 )  {
    const {body} = await response.json();
  
    return {success: true, body, message: "Načtení zůstatku proběhlo úspěšně"};
  }

  return {success: false, message: "Načtení zůstatku se nezdařilo"};
}

export async function postActivateAccount() {
  const response = await post('/trainer/activate');
  if(response.status == 201 || response.status == 200 )  {
    const {body} = await response.json();
  
    return {success: true, body, message: "Aktivace proběhla úspěšně"};
  }

  return {success: false, message: "Aktivace se nezdařila"};
}