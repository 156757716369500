import React, { useState, useContext } from 'react';
import HomePage from '../pages/HomePage';
import { useEffect } from 'react';
import { GlobalContext } from '../providers/GlobalContext';
import { getAllSubscriptions } from '../services/SubscriptionService';
import PackageListPage from '../pages/PackageListPage/PackageListPage';

function PackageListScreen() {
  const {user, setUser} = useContext(GlobalContext);
  const [subscriptions, setSubscriptions] = useState([]);
  const [isLoginModalVisible, setIsLoginModalVisible] = useState(false);

  const subscriptionFiltered = subscriptions?.filter((subscription) => subscription.status == "ACTIVE" && (subscription.type == "ONE_TIME_PURCHASE" || subscription.type == "FREE")) || [];

  let layoutType = "UNDEFINED";

  if (!user.username) {
    layoutType = "UNAUTHORIZED";
  }

  if (subscriptionFiltered?.length == 0 && user.username) {
    layoutType = "EMPTY";
  }

  if(subscriptionFiltered?.length > 0) {
    layoutType = "LIST";
  }

  useEffect(() => {
    getSubscriptions();
  }, []);

  async function getSubscriptions() {
    const response = await getAllSubscriptions();
    setSubscriptions(response.body);

    console.debug("get subscriptions result: ", response);
  }

  function onSubscripitonClickHandler(id) {
    window.location.href = `/subscription/${id}`;
  }


  function onButtonClickHandler() {
    if(layoutType == "UNAUTHORIZED") {
      setIsLoginModalVisible(true);
      return;
    }

    if(layoutType == "EMPTY") {
      window.location.href = "/shop/trainer";
      return;
    }

    if(layoutType == "LIST") {
      window.location.href = "/shop/trainer";
      return;
    }

    if(layoutType == "UNDEFINED") {
      return;
    }
  }


  return(
      <PackageListPage
          subscriptions={subscriptionFiltered}
          isLoginModalVisible={isLoginModalVisible}
          setIsLoginModalVisible={setIsLoginModalVisible}
          onSubscripitonClick={onSubscripitonClickHandler}
          onButtonClick={onButtonClickHandler}
          layoutType={layoutType}
      />
  );
}
export default PackageListScreen;