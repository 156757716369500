import React from 'react';
import Icon from "../../Icon";
import "./ButtonPrimary.css";

export default function ButtonPrimary(props) {
  const { 
    icon, 
    title, 
    onClick, 
    type, 
    className, 
    disabled, 
    onHandleScroll, 
    onHandleTouchMove, 
    onDisabledClick = () => {alert("Nejsou vyplněny všechny povinné hodnoty")},
    ...restProps } = props;

  const disabledClass = disabled ? "button_primary__disabled" : "";

  if(type == "outlined_light")  {
    return (
      <div 
        className={`button_primary__outlined_light ${className} ${disabledClass}`}  
        {...restProps} 
        onClick={!disabled ? onClick : onDisabledClick}
        onWheel={onHandleScroll}
        onTouchMove={onHandleTouchMove}
      >
         {icon && <Icon src={icon} alt="icon" className="button_primary__outlined_light__icon" />}
        <h5 className="button_primary__h3__outlined_light">{title}</h5>
      </div>
    )
  }


  if(type == "outlined")  {
    return (
      <div 
        className={`button_primary__outlined ${className} ${disabledClass}`}  
        {...restProps} 
        onClick={!disabled ? onClick : onDisabledClick}
        onWheel={onHandleScroll}
        onTouchMove={onHandleTouchMove}
      >
        <h5 className="button_primary__h3">{title}</h5>
      </div>
    )
  }

  return (
    <div 
      className={`button_primary__main ${className} ${disabledClass}`}  
      {...restProps} 
      onClick={!disabled ? onClick : onDisabledClick}
      onWheel={onHandleScroll}
      onTouchMove={onHandleTouchMove}
    >
      <h5 className="button_primary__h3">{title}</h5>
    </div>
  )
}