import {get, getReplace, post, put} from "./ApiService";

export async function getIsEmailRegistered(email) {
  const response = await get('/user/exists/'+ email);

  if(response.status == 200 || response.status == 201 || response.status == 202) {
    const { body } = await response.json();  
    return {success: true, body, message: "Načtení uživatele proběhla úspěšně"};
  }

  return {success: false, message: "Načtení uživatele se nezdařilo"};
}

export async function getRefresh() {
  const response = await get('/user/refresh');

  if(response.status == 200 || response.status == 201 || response.status == 202) {
    const { body } = await response.json();  
    return {success: true, body, message: "Obnovení tokenu proběhla úspěšně"};
  }

  return {success: false, message: "Obnovení tokenu se nezdařilo"};
}


export async function getUser() {
    const response = await get('/user/me');

    if(response.status == 200 || response.status == 201 || response.status == 202) {
      const { body } = await response.json();  
      return {success: true, body, message: "Načtení uživatele proběhla úspěšně"};
    }

    return {success: false, message: "Načtení uživatele se nezdařilo"};
}


export async function getGoogleUser() {
  const response = await get('/user/me', {
    headers: {
      "Authorization": 'Bearer ' + localStorage.getItem('tempToken')
    }
  });

  if(response.status == 200 || response.status == 201 || response.status == 202) {
    const { body } = await response.json();  
    return {success: true, body, message: "Načtení uživatele proběhla úspěšně"};
  }

  return {success: false, message: "Načtení uživatele se nezdařilo"};
}

export async function register(username, email, phoneNumber, password, affiliateUserId) {
  const response = await post('/user/register', { username, email, phoneNumber, password, affiliateUserId: Number(affiliateUserId) });
  if(response.status == 201 || response.status == 200 )  {
    const {body} = await response.json();
  
    return {success: true, body, message: "Registrace proběhla úspěšně"};
  }

  if(response.status == 409) {
    return {success: false, message: "Uživatel s tímto emailem již existuje"};
  }

  return {success: false, message: "Telefonní číslo neexistuje"};
}

export async function putUser(body) {
  const response = await put('/user/me', body);
  if(response.status == 201 || response.status == 200 )  {
    const {body} = await response.json();
  
    return {success: true, body, message: "Úprava uživatele proběhla úspěšně"};
  }

  return {success: false, message: "Úprava uživatele neproběhla úspěšně"};
}

export async function login(email, password) {
    const response = await post('/user/login', { email, password });
    if(response.status == 201 || response.status == 200 )  {
      const {body} = await response.json();
    
      return {success: true, body, message: "Přihlášení proběhlo úspěšně"};
    }
 
    return {success: false, message: "Nesprávné přihlašovací údaje"};
  }



export async function loginGoogle(accessToken, affiliateUserId) {
  const response = await post('/user/login/google', { accessToken, affiliateUserId });
  if(response.status == 201 || response.status == 200 )  {
    const {body} = await response.json();
  
    return {success: true, body, status: response.status, message: "Přihlášení pomocí google proběhlo úspěšně"};
  }

  if(response.status == 409) {
    const {message} = await response.json();
    console.log(message);
    return {success: false, status: response.status, message: message};
  }

  return {success: false, status: response.status, message: "Nesprávný přihlašovací token"};
}


