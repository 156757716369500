import {get, post, put, remove} from "./ApiService";
import UserIcon from "../assets/icons/User-Icon.svg";
import MoneyIcon from "../assets/icons/Money-Icon.svg";
import Weight1Icon from "../assets/icons/Weight-1-Icon.svg";
import Weight2Icon from "../assets/icons/Weight-2-Icon.svg";
import Weight3Icon from "../assets/icons/Weight-3-Icon.svg";
import Weight4Icon from "../assets/icons/Weight-4-Icon.svg";
import MaleIcon from "../assets/icons/Male-Icon.svg";
import FemaleIcon from "../assets/icons/Female-Icon.svg"
import OtherIcon from "../assets/icons/Other-Icon.svg";
import ForbidenIcon from "../assets/icons/Forbiden-Icon.svg";
import StudentIcon from "../assets/icons/Student-Icon.svg";
import AdultIcon from "../assets/icons/Adult-Icon.svg";
import OlderAdultIcon from "../assets/icons/Older-Adult-Icon.svg";
import SeniorIcon from "../assets/icons/Senior-Icon.svg";

export async function postFilter(body) {
  const response = await post('/filter', body);
  if(response.status == 201 || response.status == 200 )  {
    const {body} = await response.json();
  
    return {success: true, body, message: "Úspěšně nalezeny výsledky"};
  }

  return {success: false, message: "Filtrace se nezdařila"};
}

export async function getFilter(type) {
  const filterTrainerValues = [
    {
      index: 0,
      type: "selection",
      title: "Kdo jsi?",
      description: "Abychom vědeli jak tě máme oslovovat",
      values: [
        {
          index: 0,
          title: "Muž",
          src: MaleIcon,
        },
        {
          index: 1,
          title: "Žena",
          src: FemaleIcon,
        },
        // {
        //   index: 2,
        //   title: "Jiné",
        //   src: OtherIcon,
        // },
        {
          index: 2,
          title: "Nechci uvádět",
          src: ForbidenIcon,
        },
      ]
    },
    {
      index: 1,
      type: "selection",
      title: "Kolik ti je let?",
      description: "Abychom vědeli kam zapadáš",
      values: [
        {
          index: 0,
          title: "15 - 26 let",
          src: StudentIcon,
        },
        {
          index: 1,
          title: "27 - 39 let",
          src: AdultIcon,
        },
        {
          index: 2,
          title: "40 - 59",
          src: OlderAdultIcon,
        },
        {
          index: 3,
          title: "60 a více",
          src: SeniorIcon,
        },
      ]
    },
    {
      index: 2,
      type: "selection",
      title: "Na jaké jsi úrovni?",
      description: "Abychom vědeli jaké máš zkušenosti",
      values: [
        {
          index: 0,
          title: "Nováček",
          description: "Cvičení se nevěnuji. Chci začít od základů.",
          src: Weight1Icon,
        },
        {
          index: 1,
          title: "Pokročilý",
          description: "Zkušenosti už mám, ale chci být ještě lepší. Cvičím 1 - 3 roky.",
          src: Weight2Icon,
        },
        {
          index: 2,
          title: "Znalý",
          description: "Mám dobrý přehled, avšak chci dosáhnout další urovně. Cvičím 4 a více let.",
          src: Weight3Icon,
        },
        {
          index: 3,
          title: "Profesionál",
          description: "Cvičení se již věnuji profesionálně. Závodím nebo chci závodit.",
          src: Weight4Icon,
        },
      ]
    },
    {
      index: 3,
      type: "selection",
      title: "Proč chceš cvičit?",
      description: "Abychom vědeli co je pro tebe důležité",
      values: [
        {
          index: 0,
          title: "Zdraví",
          description: "Ze zdravotních důvodů. Rád bych se uzdravil a zregeneroval.",
          src: Weight2Icon,
        },
        {
          index: 1, 
          title: "Bezpečí a zábava",
          description: "Chci se dostat do formy. Užít si cvičení a naučit se nové věci.",
          src: Weight1Icon,
        },
        {
          index: 2,
          title: "Znalost těla",
          description: "Toužím porozumět své fyzické schránce. Snažit se najít smysl ve cvičení.",
          src: Weight3Icon,
        },
        {
          index: 3,
          title: "Postava a výkon",
          description: "Chci skvělou postavu. Jsem připraven dosáhnout svého maxima.",
          src: Weight4Icon,
        },
      ]
    },
    {
      index: 4,
      type: "selection",
      title: "Jaký styl preferuješ?",
      description: "Abychom vědeli co pro tebe máme připravit",
      values: [
        {
          index: 0,
          title: "Coaching",
          description: "Chci s trenérem komunikovat získávat rady a zpětnou vazbu a cvičit sám.",
          src: Weight1Icon,
        },
        {
          index: 1,
          title: "Balíček",
          description: "Chci aby mi trenér připravil balíček cvičení a já si ho budu cvičit sám.",
          src: Weight2Icon,
        },
        {
          index: 2,
          title: "Konzultace",
          description: "Chci se poradit s trenérem a nechat si poradit jaký styl tréninku je pro mě nejlepší.",
          src: Weight3Icon,
        },
        {
          index: 3,
          title: "Zatím nevím",
          description: "Chci zobrazit všechny možnosti a pak se rozhodnout.",
          src: Weight4Icon,
        },
      ]
    },
  ];

  const filterSubscriptionValues = filterTrainerValues.slice(0, 4);

  const filterPackageValues = filterTrainerValues.slice(0, 4);

  if(type == "trainer") {
    return {data: filterTrainerValues};
  }

  if(type == "subscription") {
    return {data: filterSubscriptionValues};
  }

  if(type == "package") {
    return {data: filterPackageValues};
  }

  return [];
}