import {get, post, put, remove} from "./ApiService";

export async function getSubscriptionById(id) {
  const response = await get(`/subscription/${id}`);
  if(response.status == 201 || response.status == 200 )  {
    const {body} = await response.json();
    
    return {success: true, body, message: "Načtení subscripiton proběhlo úspěšně"};
  }

  return {success: false, message: "Načtení subscription se nezdařilo"};
}

export async function getAllSubscriptions() {
  const response = await get(`/subscription/client/all`);
  if(response.status == 201 || response.status == 200 )  {
    const {body} = await response.json();
  
    if(body.length === 0) {
      return {success: false, message: "Načtení subscriptions se nezdařilo, žádný subscriptions neexistuje"};
    }
    
    return {success: true, body, message: "Načtení subscripitons proběhlo úspěšně"};
  }

  return {success: false, message: "Načtení subscriptions se nezdařilo"};
}


export async function getAllTrainerSubscriptions() {
  const response = await get(`/subscription/trainer/all`);
  if(response.status == 201 || response.status == 200 )  {
    const {body} = await response.json();
  
    if(body.length === 0) {
      return {success: false, message: "Načtení subscriptions se nezdařilo, žádný subscriptions neexistuje"};
    }
    
    return {success: true, body, message: "Načtení subscripitons proběhlo úspěšně"};
  }

  return {success: false, message: "Načtení subscriptions se nezdařilo"};
}


export async function postSubscription(articleId) {
  const response = await post(`/subscription`, {
    articleId: Number(articleId),
    comment: "Předplatné",
  });
  if(response.status == 201 || response.status == 200 )  {
    const {body} = await response.json();
  
    return {success: true, body, message: "Podání předplatného proběhlo úspěšně"};
  }

  return {success: false, message: "Podání předplatného se nezdařilo"};
}

export async function putSubsctiotion(id, body) {
  const response = await put(`/subscription/${id}`, body);
  if(response.status == 201 || response.status == 200 )  {
    const {body} = await response.json();
  
    return {success: true, body, message: "Aktualizace subscription proběhlo úspěšně"};
  }

  return {success: false, message: "Aktualizace subscription se nezdařila"};
}

export async function uploadContent(id, body) {
  const response = await post(`/subscription/${id}/content`, body);
  if(response.status == 201 || response.status == 200 )  {
    const {body} = await response.json();
  
    return {success: true, body, message: "Nahrání obsahu proběhlo úspěšně"};
  }

  return {success: false, message: "Nahrání obsahu se nezdařilo"};
}

export async function deleteContent(id, contentId) {
  const response = await remove(`/subscription/${id}/content/${contentId}`);
  if(response.status == 201 || response.status == 200 )  {
    const {body} = await response.json();
  
    return {success: true, body, message: "Smazání obsahu proběhlo úspěšně"};
  }

  return {success: false, message: "Smazání obsahu se nezdařilo"};
}