import React, { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom"
import FilterPage from "../pages/FilterPage/FilterPage";
import UseDimensions from "../hooks/UseDimensions";
import { getFilter } from "../services/FilterService";
import StepScreen from "./StepScreen";
import MaleIcon from "../assets/icons/Male-Icon.svg";
import FemaleIcon from "../assets/icons/Female-Icon.svg";
import OtherIcon from "../assets/icons/Other-Icon.svg";
import ForbidenIcon from "../assets/icons/Forbiden-Icon.svg";
import StudentIcon from "../assets/icons/Student-Icon.svg";
import AdultIcon from "../assets/icons/Adult-Icon.svg";
import OlderAdultIcon from "../assets/icons/Older-Adult-Icon.svg";
import SeniorIcon from "../assets/icons/Senior-Icon.svg";
import Weight1Icon from "../assets/icons/Weight-1-Icon.svg";
import Weight2Icon from "../assets/icons/Weight-2-Icon.svg";
import Weight3Icon from "../assets/icons/Weight-3-Icon.svg";
import Weight4Icon from "../assets/icons/Weight-4-Icon.svg";
import WeightIcon from "../assets/icons/Weight-Icon.svg";
import SubscriptionIcon from "../assets/icons/Subscription-Icon.svg";
import BundleIcon from "../assets/icons/Bundle-Icon.svg";
import { postArticle, getArticleById, putArticle } from "../services/ArticleService";
import { getAllArticleCategories } from "../services/CategoryService";
import { formatName } from "../utils/FormatUtils";
import { GlobalContext } from "../providers/GlobalContext";

export default function CreatePackageScreen() {
  const { id } = useParams();
  const {user, setUser} = useContext(GlobalContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [article, setArticle] = useState(null);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    async function fetch() {
      if(id) {
        const response = await getArticleById(id);
        if(response.success) {
          console.log("Article: ", response.body);
          setArticle(response.body);
        }
      }

      const response = await getAllArticleCategories();
      if(response.success) {
        console.log("Categories: ", response.body);
        setCategories(response.body);
      }
    }

    fetch();
  }, []);

  
  const values = [
    {
      index: 0,
      type: "selection",
      title: "Typ produktu",
      description: "O jaký typ produktu se jedná?",
      value: article ? article?.subtype == "TRAINING" ? 0 : article?.type == "COACHING" ? 1 : article?.type == "PACKAGE" ? 2 : 3 : null,
      values: [
        {
          index: 0,
          title: "Osobní Trénink",
          description: "Jednorázově zaplacené tréninky, které klienti absolvují osobně s tebou. Typicky obsahuje balíček 10 tréninků, skupinové tréninky nebo tréninky pro děti.",
          src: SubscriptionIcon,
        },
        {
          index: 1,
          title: "Coaching",
          description: "Měsíční předplatné, ve kterém se svými klienty pravidelně komunikuješ. Typicky obsahuje tréninkový plán a jídelníček na míru společně s dalšími materiály a instrukcemi, které se dynamicky mění dle potřeb klienta.",
          src: SubscriptionIcon,
        },
        {
          index: 2,
          title: "Balíček na míru",
          description: "Jednorázově zaplacený produkt na zakázku, který obsahuje jeden či několik materiálů. Typicky je to tréninkový plán na míru, jídelníček na míru, sada ukázkových videí nebo balíček rad ke cvičení.",
          src: BundleIcon,
        },
        {
          index: 3,
          title: "Předem vytvořený Balíček",
          description: "Předem vytvořený produkt, který obsahuje jeden či několik materiálů. Klienti si ho mohou stáhnout hned po zakoupení. Tento balíček může obsahovat například obecný tréninkový plán, sada receptů nebo soubor rad a tipů k cvičení.",
          src: BundleIcon,
        },
      ]
    },
    {
      index: 1,
      type: "selection",
      title: "Typ platby",
      description: "O jaký typ platby se jedná?",
      value: article ? article?.type == "FREE" ? 2 : article?.type == "ONE_TIME_PURCHASE" ? 1 : 0 : null,
      values: [
        {
          index: 0,
          title: "Měsíční Předplatné",
          description: "Každý měsíc klient platí za přístup k tvému produktu. Tento typ platby je vhodný pro produkty, které se pravidelně mění nebo kde klienti potřebují pravidelnou podporu.",
          src: SubscriptionIcon,
        },
        {
          index: 1,
          title: "Jednorázová Platba",
          description: "Klient platí jednorázově za přístup k tvému produktu. Tento typ platby je vhodný pro produkty, které se nemění nebo kde klienti nevyžadují pravidelnou podporu.",
          src: SubscriptionIcon,
        },
        {
          index: 2,
          title: "Zdarma",
          description: "Produkt je zdarma a klienti se k němu dostanou ihned po zadání svých údajů. Tento typ platby je vhodný pro produkty, které slouží jako ukázka tvé práce nebo jako marketingový nástroj.",
          src: BundleIcon,
        },
      ]
    },
    {
      index: 2,
      type: "text",
      title: "Název produktu",
      description: "Jak se bude tvůj produkt jmenovat?",
      placeholder: "Název",
      errorText: "Název musí mít 5 až 40 znaků",
      validator: (value) => value?.length >= 5 && value?.length <= 40 ,
      src: WeightIcon,
      value: article ? article?.name : "",
    },
    {
      index: 3,
      type: "longText",
      title: "Popis produktu",
      description: "Jaký bude obsah tvého produktu?",
      placeholder: "Popis produktu",
      errorText: "Název musí mít 30 až 2000 znaků",
      validator: (value) => value?.length >= 30 && value?.length <= 2000,
      src: OtherIcon,
      value: article ? article?.about : "",
    },
    {
      index: 4,
      type: "image",
      title: "Fotka produtku",
      description: "Jak bude tvůj produkt vypadat?",
      text: "Vyber si fotku, která bude sloužit jako přebal produtku",
      buttonText: "Nahrát fotku",
      exists: article ? true : false,
      value: article ? { src: article?.imageUri[0], title: "" } : null,
    },
    {
      index: 5,
      type: "content",
      title: "Obsah produktu",
      description: "Soubory tvořící obsah produktu, doukumenty, obrázky, videa, ...",
      maxLength: 1,
      if: (values) => values[0]?.value == 3, // only for prepared package
      exists: article ? true : false,
      value: article ? article?.contentUri?.map(example => {return { text: example?.substring(example?.length -20), src: example }}) : null,
    },
    {
      index: 6,
      type: "picker",
      title: "Co produkt obsahuje?",
      description: "Jaký obsah bude produkt obsahovat?",
      maxLength: 12,
      customValue: false,
      value: article ? true : null,
      values: article 
      ? categories?.map(category => {return { text: category.name, src: category.imageUri, selected: article?.categories?.findIndex(cat => cat.name == category.name) != -1 }})
      : categories?.map(category => {return { text: category.name, src: category.imageUri, selected: false }}),
    },
    {
      index: 7,
      type: "picker",
      title: "Co bude klient potřebovat?",
      description: "Které věci bude klient potřebovat?",
      maxLength: 12,
      customValue: true,
      value: article ? true : null,
      values: [
        {
          text: "Přístup do posilovny",
          src: SubscriptionIcon,
        },
        {
          text: "Prostor pro cvičení",
          src: SubscriptionIcon,
        },
        {
          text: "Týdně 1h volného času",
          src: SubscriptionIcon,
        },
        {
          text: "Zkušenosti s tréninkem",
          src: WeightIcon,
        },
        {
          text: "Vlastní váhy",
          src: SubscriptionIcon,
        },
        ...article?.requirements?.map(req => {return { text: req, src: SubscriptionIcon, selected: true }}) || []
      ]
    },
    {
      index: 8,
      type: "picker",
      title: "Čeho klient dosáhne?",
      description: "Co produkt klientovi přinese?",
      maxLength: 12,
      customValue: true,
      value: article ? true : null,
      values: [
        {
          text: "Zvýšení síly",
          src: SubscriptionIcon,
        },
        {
            text: "Větší vytrvalost",
            src: SubscriptionIcon,
        },
        {
            text: "Lepší flexibilita",
            src: SubscriptionIcon,
        },
        {
            text: "Redukce tělesného tuku",
            src: SubscriptionIcon,
        },
        {
            text: "Navýšení svalové hmoty",
            src: SubscriptionIcon,
        },
        {
            text: "Definice postavy",
            src: SubscriptionIcon,
        },
        {
            text: "Kardiovaskulární zdraví",
            src: SubscriptionIcon,
        },
        {
            text: "Větší energie a vitalita",
            src: SubscriptionIcon,
        },
        {
            text: "Kvalitní spánek",
            src: SubscriptionIcon,
        },
        {
            text: "Kvalitní regenerace",
            src: SubscriptionIcon,
        },
        {
            text: "Snížení stresu",
            src: SubscriptionIcon,
        },
        {
            text: "Dosáhnutí váhového cíle",
            src: SubscriptionIcon,
        },
        {
            text: "Lepší koordinace těla",
            src: SubscriptionIcon,
        },
        {
            text: "Správné držení těla",
            src: SubscriptionIcon,
        },
        {
            text: "Posílení imunity",
            src: SubscriptionIcon,
        },
        {
            text: "Mentální zdraví",
            src: SubscriptionIcon,
        },
        {
            text: "Správné fungování svalů",
            src: SubscriptionIcon,
        },
        {
            text: "Zlepšení srdečního rytmu",
            src: SubscriptionIcon,
        },
        {
            text: "Zvyšování sebedůvěry",
            src: SubscriptionIcon,
        },
        {
            text: "Zlepšení emoční stability",
            src: SubscriptionIcon,
        },
        {
            text: "Zlepšení koordinace těla",
            src: SubscriptionIcon,
        },
        {
            text: "Posílení imunitního systému",
            src: SubscriptionIcon,
        },
        {
            text: "Zlepšení zdraví srdce",
            src: SubscriptionIcon,
        },
        {
            text: "Snížení rizika onemocnění",
            src: SubscriptionIcon,
        },
        {
            text: "Zlepšení trávení",
            src: SubscriptionIcon,
        },
        {
            text: "Zlepšení držení těla",
            src: SubscriptionIcon,
        },
        {
            text: "Lepší pokožka",
            src: SubscriptionIcon,
        },
        {
            text: "Zvýšení krevního oběhu",
            src: SubscriptionIcon,
        },
        {
            text: "Zlepšení metabolismu",
            src: SubscriptionIcon,
        },
        {
            text: "Zlepšení sebekontroly",
            src: SubscriptionIcon,
        },
        ...article?.achievements?.map(ach => {return { text: ach, src: SubscriptionIcon, selected: true }}) || []
      ]
    },
    {
      index: 9,
      type: "number",
      title: "Cena produktu",
      description: "Kolik bude produkt stát v Kč?",
      placeholder: "Cena v Kč",
      errorText: "Cena musí být v rozmezí 200 až 30000 Kč",
      validator: (value) => value >= 200 && value < 30000,
      if: (values) => values[1]?.value != 2, // only for paid packages
      src: WeightIcon,
      value: article ? article.price  : "",
    },
    {
      index: 10,
      type: "longText",
      title: "Vzkaz klientovi po zakoupení",
      description: "Jaké údaje si má klient připravit, nebo jak ho budeš kontaktovat",
      placeholder: "Připrav si prosím váhu a výšku. Zavolám ti brzy a děkuji, že jsi ve mě vložil důvěru.",
      errorText: "Vzkaz musí mít maximálně 2000 znaků",
      validator: (value) => value?.length <= 2000 ,
      src: WeightIcon,
      value: article ? article.instructions : "",
    },
    {
      index: 11,
      type: "selection",
      title: "Viditelnost produktu",
      description: "Jak bude pro klienty produkt viditelný?",
      value: article ? (article.status == "ACTIVE" || article.status == "PENDING") ? article?.isRecomended ? 0 : 1 : 2 : null,
      values: [
        {
          index: 0,
          title: "Doporučený produkt",
          description: "Doporučený produkt může být pouze jeden a bude se zobrazovat na první pozici v seznamu produktů.",
          src: WeightIcon,
        },
        {
          index: 1,
          title: "Standardní produkt",
          description: "Standardní produkt je produkt, který není doporučený a zobrazuje se standardně v seznamu produktů.",
          src: WeightIcon,
        },
        {
          index: 2,
          title: "Skrytý produkt",
          description: "Skrytý produkt je produkt, který není zobrazen v seznamu produktů, je dostupný pouze přes odkaz.",
          src: WeightIcon,
        },
      ]
    },
  ];

  function onNextCallback(values) {
    console.log("Next");
    console.log(values);
  }

  async function onFinishCallback(values) {
    console.log("Finish");
    console.log(values);

    if(isSubmitting) {
      return;
    }

    setIsSubmitting(true);

    const body = {
      subType: values[0]?.value == 0 ? "TRAINING" : values[0]?.value == 1 ? "COACHING" : values[0]?.value == 2 ? "PACKAGE" : "PREPARED_PACKAGE",
      type: values[1]?.value == 0 ? "MONTHLY_SUBSCRIPTION" : values[1]?.value == 1 ? "ONE_TIME_PURCHASE" : "FREE",
      name: values[2]?.value,
      about: values[3]?.value,
      image:  values[4]?.exists ? null : [{
        fileName: values[4]?.value?.title,
        file: values[4]?.value.src.substring(values[4]?.value.src.indexOf("base64,") + 7),
      }],
      content: values[0]?.value == 3 ? values[5]?.exists ? null : values[5]?.value?.map(value => {return { fileName: value.title, file: value.src.substring(value.src.indexOf("base64,") + 7)}}) : null,
      categories: values[6]?.values?.filter(value => value.selected).map(value => {return { name: value.text }}),
      requirements: values[7]?.values?.filter(value => value.selected).map(value => value.text),
      achievements: values[8]?.values?.filter(value => value.selected).map(value => value.text),
      price: values[1]?.value != 2 ? Number(values[9]?.value) : undefined,
      instructions: values[10]?.value,
      status: values[11].value == 2 ? "INACTIVE" : "ACTIVE",
      isRecomended: values[11].value == 0,
    }

    console.log("Create article body: ", body);
    if(article) {
      const response = await putArticle(body, article?.id);
      if(response.success) {
        console.log("Success");
        if(body.status != "ACTIVE") {
          window.location.replace(`/${formatName(user.username)}`);
        }
        if(body.status == "ACTIVE") {
          window.location.replace(`/${formatName(user.username)}/${formatName(body.name)}`);
        }
      }

      if(!response.success) {
        console.log("Error");
        console.log(response.message);
        alert(response.message);
      }
    }

    if(!article) {
      const response = await postArticle(body);
      if(response.success) {
        console.log("Success");
        if(body.status != "ACTIVE") {
          window.location.replace(`/${formatName(user.username)}`);
        }
        if(body.status == "ACTIVE") {
          window.location.replace(`/${formatName(user.username)}/${formatName(body.name)}`);
        }
      }

      if(!response.success) {
        console.log("Error");
        console.log(response.message);
        alert(response.message);
      }
    }

    setIsSubmitting(false);
  }

  if(categories?.length == 0) {
    return <div></div>
  }

  return (
    <StepScreen
      values={values}
      isLoading={isSubmitting}
      onNextCallback={onNextCallback}
      onFinishCallback={onFinishCallback}
    />
  )
}