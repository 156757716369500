import * as cs from "./cs.json";
import * as en from "./en.json";

export const translations = {
  en: {
    translation: en
  },
  cs: {
    translation: cs
  }
};
