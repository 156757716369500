import React from "react";
import './ArticleDetailPage.css';  // Make sure to rename the CSS file
import { Trans } from "react-i18next";
import i18n from '../providers/I18nProvider';
import IconBoxGrid from "../components/Boxes/IconBoxGrid";
import ButtonPrimary from "../components/Buttons/Primary/ButtonPrimary";
import SocialBoxGrid from "../components/Boxes/SocialBoxGrid";
import ProductBoxScroll from "../components/Boxes/ProductBoxScroll";
import ProcessComponent from "../components/ProcessComponent";
import {BottomNavbar} from "../components/Navbar/BottomNavbar";
import ProcessDetailComponent from "../components/ProcessDetailComponent";
import TrainerBox from "../components/Boxes/TrainerBox";
import LoginModal from "../components/modal/LoginModal";
import Icon from "../components/Icon";
import { formatPrice } from "../utils/FormatUtils";

function ArticleDetailPage(props) {

    const ShowMoreBox = () => {
        return (
            <div className="article_detail_page__head_box_show_more">
                {props.type == "MONTHLY_SUBSCRIPTION" && <h3 className="article_detail_page__head_box_show_more_h2">V Coachingu najdeš</h3>}
                {props.type != "MONTHLY_SUBSCRIPTION" && <h3 className="article_detail_page__head_box_show_more_h2">V balíčku najdeš</h3>}
                <IconBoxGrid values={props.categories} theme={"light"}/>
                <h3 className="article_detail_page__head_box_show_more_h2">Čeho dosáhneš</h3>
                <IconBoxGrid values={props.achievements} theme={"light"}/>
                <h3 className="article_detail_page__head_box_show_more_h2">Co budeš potřebovat</h3>
                <IconBoxGrid values={props.requirements} theme={"light"}/>
                {/* <h3 className="article_detail_page__head_box_show_more_h2">O balíčku</h3>
                <p className="article_detail_page__head_box_show_more_h4">{props.about}</p> */}
            </div>       
        );
    };

    return (
        <>
            <div className="article_detail_page">
                <div className="article_detail_page__head">
                    <div className="article_detail_page__head_header">
                        <Icon 
                            className="article_detail_page__head_header_image"
                            alt={`article ${props.name}`} 
                            src={props.articleImage}
                        />
                        <div className="article_detail_page__head_header_shadow"/>
                        <div className='article_detail_page__head_header_shadow_desktop'/>
                        <h1 className="article_detail_page__head_header_h1">{props.name}</h1>
                    </div>

                    <div className="article_detail_page__head_box">
                        <p className='article_detail_page__head_box_h4'>{props.about}</p>
                        <ShowMoreBox/>
                        {/* <ButtonPrimary title={"Stáhnout ukázku"} onClick={props.onDownloadExampleClick} type={"outlined"} className="article_detail_page__head_box_button"/> */}
              
                        <div className="article_detail_page__head_box_show_more_desktop">
                            <div className="article_detail_page__head_box_show_more_desktop_box">
                                <div>
                                {props.type == "MONTHLY_SUBSCRIPTION" && <h3 className="article_detail_page__head_box_show_more_h2">V Coachingu najdeš</h3>}
                                {props.type != "MONTHLY_SUBSCRIPTION" && <h3 className="article_detail_page__head_box_show_more_h2">V balíčku najdeš</h3>}
                                    <IconBoxGrid values={props.categories} theme={"light"} type={"desktop"}/>
                                </div>
                                <div>
                                <h3 className="article_detail_page__head_box_show_more_h2">Čeho dosáhneš</h3>
                                    <IconBoxGrid values={props.achievements} theme={"light"} type={"desktop"}/>
                                </div>
                                <div>
                                <h3 className="article_detail_page__head_box_show_more_h2">Co budeš potřebovat</h3>
                                    <IconBoxGrid values={props.requirements} theme={"light"} type={"desktop"}/>
                                </div>
                            </div>
                        </div>       
                    </div>

                </div>
                <div className="article_detail_page__body">
                    <h3 className="article_detail_page__body_h2">Co se stane po objednání?</h3>
                    <ProcessDetailComponent type={props.type}/>
                    {props.type == "MONTHLY_SUBSCRIPTION" && <h3 className="article_detail_page__body_trainer_h2">Tento coaching vytvořil</h3>}
                    {props.type != "MONTHLY_SUBSCRIPTION" && <h3 className="article_detail_page__body_trainer_h2">Tento balíček vytvořil</h3>}
                    <TrainerBox  
                        title={props.trainer?.name}
                        description={props.trainer?.description}
                        email={props.trainer?.email}
                        phone={props.trainer?.phoneNumber}
                        src={props.trainer?.imageUri?.length > 0 && props.trainer?.imageUri[0]}
                        onClick={props.onTrainerClick}
                        />
                </div>
            {props.isOwner && <BottomNavbar title={`${formatPrice(props.price)}`} description={props.type == "MONTHLY_SUBSCRIPTION" ? "Měsíčně": "Jednorázově"} buttonText={"Upravit"} onClick={props.onEditClick}/>}
            {(!props.isOwner && props.isActive) && <BottomNavbar title={`${formatPrice(props.price)}`} description={props.type == "MONTHLY_SUBSCRIPTION" ? "Měsíčně": "Jednorázově"} buttonText={"Zakoupit"} onClick={props.onPurchaseClick}/>}
            {(!props.isOwner && !props.isActive) && <BottomNavbar title={props.type == "MONTHLY_SUBSCRIPTION" ? "Neaktivní coaching": "Neaktivní balíček"} description={props.type == "MONTHLY_SUBSCRIPTION" ? "Coaching není aktivní": "Balíček není aktivní"} buttonText={"Nedostupný"} onClick={() => {}} disabled/>}
            </div>
            <LoginModal 
                redirect={`checkout_${props.type == "MONTHLY_SUBSCRIPTION" ? "subscription" : "package"}_${props.id}`}
                isVisible={props.isLoginModalVisible} 
                setIsVisible={props.setIsLoginModalVisible}
            />
        </>
    );
}

export default ArticleDetailPage;
