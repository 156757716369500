import React, { useState, useContext } from "react";

import { GlobalContext } from "../providers/GlobalContext";
import { login, loginGoogle } from "../services/UserService";
import { useGoogleLogin } from '@react-oauth/google';
import LoadingPage from "../pages/LoadingPage/LoadingPage";

export default function LoadingScreen({
  duration = 0.6
}) {

  return (
    <LoadingPage
      duration={duration}
    />
  );
}
