import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from "react-ga4";

const AnalyticsWrapper = ({ children }) => {
    const location = useLocation();

    const TRACKING_ID = "G-NNLHPNL1E5";

    const initGA = () => {
        ReactGA.initialize([{trackingId: TRACKING_ID}]);
    };

    const logPageView = () => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: window.document.title });
    };

    useEffect(() => {
        initGA();
    }, []); 

    useEffect(() => {
        logPageView();
    }, [location]); 

    return <>{children}</>;
};

export default AnalyticsWrapper;
