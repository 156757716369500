import React from 'react';
import './StepPage.css';
import {BottomNavbarProgress} from '../../components/Navbar/BottomNavbar';
import { ChooseInputDescription } from '../../components/TextInput/ChooseInput';
import TextInput from '../../components/TextInput/TextInput';
import EmailIcon from '../../assets/icons/Email-Icon.svg';
import LongTextInput from '../../components/TextInput/LongTextIntput';
import EmptySubsciptionBox from '../../components/Boxes/EmptySubscriptionBox';
import ImageInput from '../../components/TextInput/ImageInput';
import ContentBoxGrid from '../../components/Boxes/ContentBoxGrid';
import ContentInput from '../../components/TextInput/ContentInput';
import PickerInput from '../../components/TextInput/PickerInput';

function StepPage(props) {
  const { currentValue } = props;

  const currentType = {
    selection: <ChooseInputDescription 
      values={currentValue?.values} 
      selected={props.value} 
      onClick={props.onSelectClick} />,
    text: <TextInput 
      value={props.value}
      onChange={props.onValueChange}
      icon={currentValue.src} 
      type="text"
      theme="outlined_light"
      placeholder={currentValue.placeholder}
      validator={currentValue.validator}
      underlineText={currentValue.underlineText}
      errorText={currentValue.errorText}/>,
    number: <TextInput 
      value={props.value}
      onChange={props.onValueChange}
      icon={currentValue.src} 
      type="text"
      theme="outlined_light"
      placeholder={currentValue.placeholder}
      validator={currentValue.validator}
      underlineText={currentValue.underlineText}
      errorText={currentValue.errorText}/>,
    longText: <LongTextInput 
      value={props.value}
      onChange={props.onValueChange}
      icon={currentValue.src} 
      type="text"
      theme="outlined_light"
      placeholder={currentValue.placeholder}
      validator={currentValue.validator}
      underlineText={currentValue.underlineText}
      errorText={currentValue.errorText}/>,
    image: <ImageInput  
      src={props.value?.src}
      title={currentValue.text} 
      buttonText={currentValue.buttonText} 
      onClick={props.onUploadClick}/>,
    content: <ContentInput 
        values={props.value} 
        maxLength={currentValue.maxLength}
        onAddClick={props.onAddClick}
        onRemoveClick={props.onRemoveClick}
      />,
    picker: <PickerInput 
        values={currentValue.values}
        maxLength={currentValue.maxLength}
        onSelectClick={props.onPickerSelectClick}
        onNewClick={props.onPickerNewClick}
        customValue={currentValue.customValue}
      />,
  }

  return (
      <div className="page"  style={{minHeight: props.height - 64}}>
          <div className="page__layout">          
            <h2 className="page__title">{currentValue?.title}</h2>
            <h4 className="page__subtitle">{currentValue?.description}</h4>
            {currentType[currentValue?.type]}
          </div>
          <BottomNavbarProgress 
            isLoading={props.isLoading}
            backButtonText={"Zpět"}
            submitButtonText={props.submitButtonText}
            backButtonDisabled={props.backButtonDisabled}
            submitButtonDisabled={props.submitButtonDisabled}
            onBackClick={props.onBackClick}
            onSubmitClick={props.onSubmitClick}
          />
      </div>
    );
}

export default StepPage;
