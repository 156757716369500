import {get, post} from "./ApiService";


export async function getAllArticleCategories() {
  const response = await get(`/category/all`);
  if(response.status == 201 || response.status == 200 )  {
    const {body} = await response.json();
  
    if(body.length === 0) {
      return {success: false, message: "Načtení kategorii se nezdařilo, žádna kategorie neexistuje"};
    }
    
    const filteredCategories = body.filter(category => category.type == "article");
    
    return {success: true, body: filteredCategories, message: "Načtení kategorii proběhlo úspěšně"};
  }

  return {success: false, message: "Načtení kategorii se nezdařilo"};
}


export async function getAllTrainerCategories() {
  const response = await get(`/category/all`);
  if(response.status == 201 || response.status == 200 )  {
    const {body} = await response.json();
  
    if(body.length === 0) {
      return {success: false, message: "Načtení kategorii se nezdařilo, žádna kategorie neexistuje"};
    }
    
    const filteredCategories = body.filter(category => category.type == "trainer_focus");
    
    return {success: true, body: filteredCategories, message: "Načtení kategorii proběhlo úspěšně"};
  }

  return {success: false, message: "Načtení kategorii se nezdařilo"};
}