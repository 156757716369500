import "./LoginModal.css";
import React, { useState, useEffect, useContext } from 'react';
import ButtonPrimary from '../../components/Buttons/Primary/ButtonPrimary';
import TextInput from "../../components/TextInput/TextInput";
import EmailIcon from "../../assets/icons/Email-Grey-Icon.svg";
import GoogleIcon from "../../assets/icons/Google-Icon.svg";
import { GlobalContext } from "../../providers/GlobalContext";
import { getUser, login, loginGoogle } from "../../services/UserService";
import { useGoogleLogin } from '@react-oauth/google';
import Loader from "../../components/Loader";
import SuccessIcon from "../../assets/icons/Success-Icon.svg";
import { getIsEmailRegistered } from "../../services/UserService";
import BaseModal from "./BaseModal";
import Icon from "../Icon";
import { isValidBrowser } from "../../utils/RoutingUtils";
import { GOOGLE_CLIENT_ID } from "../../constants/Api";


export default function UseLoginBottomModal({redirect, isVisible, setIsVisible}) {

  
    return (
        <BaseModal isVisible={isVisible} setIsVisible={setIsVisible}>
            <LoginView redirect={redirect} onSuccess={() => setIsVisible(false)} />
        </BaseModal>
    )
}

export const LoginView = ({
  redirect,
  onSuccess, 
  affiliateId, 
  title = "Pokračuj přihlášením",
  subtitle = "Jeden krok při registraci, velký krok pro tebe",
  type = "small"
}) => {
  const { user, setUser } = useContext(GlobalContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [wrongPassword, setWrongPassword] = useState("");
  const [modalState, setModalState] = useState("idle");
  
  useEffect(() => {
    let timeout;

    if(user?.id || user?.isLoggedIn) {
      setModalState("success");
      timeout = setTimeout(async () => {
        const userData = await getUser();
        const redirectUrl = redirect ? "/" + redirect.replaceAll("_", "/") : "/";
        const isTrainer = userData?.body?.trainer?.length > 0;

        if(isTrainer) {
          window.location = "/trainer/dashboard";
          onSuccess();
          return;
        }

        window.location = redirectUrl;
        onSuccess();
      }, 1000);
    }

    return () => {
      clearTimeout(timeout);
    }
  }, [user]);

  
  async function responseGoogle(response) {

    const result = await loginGoogle(response.access_token, affiliateId);

    // user is registered with email
    if(!result.success && result.status == 409 ) {
      setEmail(result.message);
      setModalState("login-google-email");
      return;
    }

    if (!result.success) {
      console.log(result.message);  
      setModalState("idle");
      return;
    }

    if(!result.body.verified) {
      setUser({
        isLoggedIn: false,
        token: "/",
        tempToken: result.body.token,
        language: "cs",
      });

      if(affiliateId) {
        window.location.href = "/register/" + redirect + "/google/" + affiliateId;
        return;
      }

      window.location.href = "/register/" + redirect + "/google"; 
      return;
    }
    
    setUser({
      isLoggedIn: true,
      token: result.body.token,
      tempToken: "/",
      name: "Username",
      language: "cs",
    });
    
    setModalState("sucess");
  }

    const googleFailure = (error) => {
      console.error('Failed to login with Google:', error);
    };
  
    const handleGoogleLogin = useGoogleLogin({
      onSuccess: responseGoogle,
      onFailure: googleFailure,
      scope: 'openid profile email',
      flow: 'implicit',
    });

    const onGoogleButtonClick = () => {
      if(!isValidBrowser()) {
        setModalState("login-error-google");
        return;
      }

      setModalState("loading");
      handleGoogleLogin();
    }

    const onEmailChange = (event) => {
      setEmail(event.target.value);
    }

    const onPasswordChange = (event) => {
      setPassword(event.target.value);
    }
  
    const onEmailButtonClick = async () => {
      setModalState("loading");
      const {success, body, message} = await getIsEmailRegistered(email);
      if(!success) {
        console.error("error: " + message);
        return;
      }

      // route to register if email is not registered
      if(!body.isRegistered) {

        if(affiliateId) {
          window.location.href = "/register/" + redirect + "/" + email + "/" + affiliateId;
          return;
        }

        window.location.href = "/register/" + redirect + "/" + email ;
        return;
      }

      if(body.type == "google") {
        setModalState("login-email-google");
        return;
      }

      // route to login if email is registered
      setModalState("login-email");
    }

    const onLoginButtonClick = async () => {
      setModalState("loading");
      const {success, body, message} = await login(email, password);
      if(!success) {
        console.error("error: " + message);
        setModalState("login-email");
        setWrongPassword(password);
        return;
      }
  
      setUser({
        isLoggedIn: true,
        token: body.token,
        name: "Username",
        language: "cs",
      });

      setModalState("sucess");
    }
    
    const Separator = () => {
      return (
        <div className="login_bottom_modal__separator">
          <div className="login_bottom_modal__separator_line"></div>
          <p className="login_bottom_modal__separator_text">nebo</p>
          <div className="login_bottom_modal__separator_line"></div>
        </div>
      )
    }


    const isEmailValid = email.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g);

    const modalClass = type == "small" ? "login_bottom_modal" : "login_bottom_modal__large";
    const titleClass = type == "small" ? "login_bottom_modal__title" : "login_bottom_modal__title_large";

    if(modalState == "loading") {
      return (
        <div className='login_bottom_modal__loading'>
          <Loader width={64}/>
        </div>
      )
    }

    if(modalState == "success") {
      return (
        <div className='login_bottom_modal__loading'>
          <Icon src={SuccessIcon} className="loggin_bottom_modal__icon"/>
        </div>
      )
    }

  if(modalState == "idle") {
    return (
      <div className={modalClass}>
        <div className={titleClass}>
          <h3 className='login_bottom_modal__title_h2'>{title}</h3>
          <h5 className='login_bottom_modal__title_h3'>{subtitle}</h5>
        </div>
        <div className="login_bottom_modal_layout">
          <ButtonPrimary onClick={onGoogleButtonClick} icon={GoogleIcon} title={"Přihlášení pomocí Google"} type={"outlined_light"}/>
          <Separator/>
          <TextInput value={email} onChange={onEmailChange} icon={EmailIcon} placeholder={"E-mail"} type={"email"} theme={"outlined_light"} validator={(value) => value.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g)} errorText="E-mail není ve nesprávném formátu"/>
          <ButtonPrimary onClick={onEmailButtonClick} disabled={!isEmailValid} title={"Pokračovat"}/>
        </div>
      </div>
    )
  }

  if(modalState == "login-email" ) {
    const passwordValidator = () => {
      return wrongPassword != password;
    }

    return (
      <div className={modalClass}>
        <div className={titleClass}>
          <h3 className='login_bottom_modal__title_h2'>Přihlášení přes e-mail</h3>
          <h5 className='login_bottom_modal__title_h3'>Rádi tě zase vidíme, vítej zpět!</h5>
        </div>
        <div className="login_bottom_modal_layout">
          <h5 className='login_bottom_modal__email_h3'>{email}</h5>
          <TextInput value={password} onChange={onPasswordChange} icon={EmailIcon} placeholder={"Password"} type={"password"} theme={"outlined_light"}  validator={passwordValidator} errorText="Nesprávné heslo"/>
          <ButtonPrimary onClick={onLoginButtonClick} disabled={!isEmailValid} title={"Pokračovat"}/>
        </div>
      </div>
    )
  }

  if(modalState == "login-email-google" ) {
    return (
      <div className={modalClass}>
        <div className={titleClass}>
          <h3 className='login_bottom_modal__title_h2'>Přihlášení přes e-mail</h3>
          <h5 className='login_bottom_modal__title_h3'>Tvůj účet je již zaregistrován přes Google</h5>
        </div>
        <div className="login_bottom_modal_layout">
          <h5 className='login_bottom_modal__email_h3'>{email}</h5>
          <ButtonPrimary onClick={onGoogleButtonClick} icon={GoogleIcon} title={"Přihlášení pomocí Google"} type={"outlined_light"}/>
        </div>
      </div>
    )
  }


  if(modalState == "login-error-google" ) {
    return (
      <div className={modalClass}>
        <div className={titleClass}>
          <h3 className='login_bottom_modal__title_h2'>Otevři v prohlížeči</h3>
          <h5 className='login_bottom_modal__title_h3'>
            Pro přihlášení pomocí Google je potřeba otevřít Nextcoach ve svém webovém prohlížeči.
          </h5>
        </div>
        <div className="login_bottom_modal_layout">
          <h5 className='login_bottom_modal__email_h3'>
            Otevři v prohlížeči <br/> <strong>nextcoach.cz</strong>
          </h5>
        </div>
      </div>
    )
  }


  if(modalState == "login-google-email" ) {
    const passwordValidator = () => {
      return wrongPassword != password;
    }

      return (
        <div className={modalClass}>
          <div className={titleClass}>
            <h3 className='login_bottom_modal__title_h2'>Přihlášení přes e-mail</h3>
            <h5 className='login_bottom_modal__title_h3'>Tvůj účet je již zaregistrován přes Email</h5>
          </div>
          <div className="login_bottom_modal_layout">
            <h5 className='login_bottom_modal__email_h3'>{email}</h5>
            <TextInput value={password} onChange={onPasswordChange} icon={EmailIcon} placeholder={"Password"} type={"password"} theme={"outlined_light"}  validator={passwordValidator} errorText="Nesprávné heslo"/>
            <ButtonPrimary onClick={onLoginButtonClick} disabled={!isEmailValid} title={"Pokračovat"}/>
          </div>
        </div>
      )
  }

}