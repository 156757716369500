import React from "react";
import "./Description.css";
import Icon from "./Icon";


const DescriptionFull = ({values, style}) => {

  const Box = React.memo(({ src, title, description }) => {
    return (
      <div className="description__box">
        {src  && <Icon src={src} alt="icon" className="description__box_icon"/>}
        <div>
          <h5 className="description__box_h3">{title}</h5>
          <p className="description__box_h4">{description}</p>
        </div>
      </div>
    );
  });

  return (
    <div className="description" style={style}>
      {values.map((value, index) => 
        <Box key={index} {...value} />
      )}
    </div>
  );
};


const DescriptionHalf = ({values, style}) => {

  const Box = React.memo(({ src, title, description }) => {
    return (
      <div className="description_half__box">
        {src  && <Icon src={src} alt="icon" className="description__box_icon"/>}
        <div>
          <h5 className="description__box_h3">{title}</h5>
          <p className="description__box_h4">{description}</p>
        </div>
      </div>
    );
  });

  return (
    <div className="description_half" style={style}>
      {values.map((value, index) => 
        <Box key={index} {...value} />
      )}
    </div>
  );
};


export const DescriptionFullRow = React.memo(DescriptionFull);
export const DescriptionHalfRow = React.memo(DescriptionHalf);
