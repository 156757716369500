import React, { useState, useContext, useEffect } from 'react';
import ClientProfilePage from '../pages/ProfileEditInfoPage/ProfileEditInfoPage';
import { GlobalContext } from '../providers/GlobalContext';
import { getUser, putUser } from '../services/UserService';
import ProfileEditInfoPage from '../pages/ProfileEditInfoPage/ProfileEditInfoPage';
import parsePhoneNumberFromString from 'libphonenumber-js';

export default function ProfileEditInfoScreen() {
  const {user, setUser} = useContext(GlobalContext);
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const hasPassword = user.type == "email" ? true : false;

  useEffect(() => {
    getUserData();
  }, [])

  async function getUserData() {
    const response = await getUser();

    if(!response.success) {
      console.error(response.message)
      setError(response.message)
      return;
    }

    setUsername(response.body.username);
    setEmail(response.body.email);
    setPhone(response.body.phoneNumber.replace(/(\d{3})(\d{3})(\d{3})(\d{3})/, "$1 $2 $3 $4"));
    setPassword(response.body.type == "email" ? "********" : "");

    console.debug("get user result: ", response);
  }

  async function onButtonClick() {
    setIsLoading(true);

    const body = {
      username: username ? username : undefined,
      email: email ? email : undefined,
      phoneNumber: phone ? phone : undefined,
      password: password && password != "********" ? password : undefined
    }

    const registerResult = await putUser(body);
  
    if(!registerResult.success) {
      console.log(registerResult.message)
      setError(registerResult.message)
      setIsLoading(false);
      return;
    }


    setUser({...user, ...registerResult.body})

    setError("")
    setIsLoading(false);
    window.location.href = "/profile";
    
  }

  function onUsernameChange(usernameValue) {
    setUsername(usernameValue.target.value);
  }

  function onEmailChange(emailValue) {
    setEmail(emailValue.target.value);
  }

  function onPhoneChange(phoneValue) {
    const value = phoneValue.target.value;
    const oldValue = phone;

    const lastChar = value.charAt(value.length-1);
    const isDeleting = oldValue.length > value.length;

    if(isDeleting) {
      setPhone(value);
      return;
    }

    if(phone.length == 0) {
      lastChar.match(/[0-9]/g) ? setPhone("+420 " + lastChar) : setPhone("+420 ");
      return;
    }

    if(value.length == 4 || value.length == 8 || value.length == 12) {
      setPhone(value + " ");
      return;
    }

    if(value.length == 5 || value.length == 9 || value.length == 13) {
      setPhone(value.substring(0, value.length-1) + " " + lastChar);
      return;
    }

    if(lastChar.match(/[0-9]/g) && value.length <= 16) {
      setPhone(value);
      return;
    }

  }

  function onPasswordChange(password) {
    setPassword(password.target.value);
  }


  const isFormEmailValid = (
    username.length > 2 
    && username.length < 30
    && username?.split(" ").length >= 1 
    && username?.split(" ").length <= 2
    && username?.split(" ").pop() != ""
    && email.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g) 
    && phone.match(/^\+\d+ \d{3} \d{3} \d{3}$/)
    && (password.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d!@#$%^&*\-]{8,}$/g) || password == "********")
  )

  const isFormGoogleValid = (
    username.length > 2 
    && username.length < 30
    && username?.split(" ").length >= 1 
    && username?.split(" ").length <= 2
    && username?.split(" ").pop() != ""
    && email.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g) 
    && phone.match(/^\+\d+ \d{3} \d{3} \d{3}$/)
    && parsePhoneNumberFromString(phone)?.isValid() || false
  )

  const isFormValid = hasPassword ?  isFormEmailValid : isFormGoogleValid;

  if(!user) {
    return null;
  }

  return (
    <ProfileEditInfoPage
      username={username}
      email={email}
      phone={phone}
      password={password}
      showPassword={hasPassword}
      onUsernameChange={onUsernameChange}
      onEmailChange={onEmailChange}
      onPhoneChange={onPhoneChange}
      onPasswordChage={onPasswordChange}
      onButtonClick={onButtonClick}
      isLoading={isLoading}
      error={error}
      submitDisabled={!isFormValid}
    />
  );
}
