import "./IconBox.css";
import Icon from "../Icon";

export default function IconBox(props) {
  const {icon, src, text, onClick, theme, type, active,  style, ...restProps} = props;
  
  const srcLink = (src && !icon ) ? src : "";

  const themeClass = theme == "dark" ? "icon_box__text_dark" : "icon_box__text_light";
  let typeMainClass;
  let typeIconClass;

  switch (type) {
    case "round":
      typeMainClass = "icon_box__rounded";
      typeIconClass = "icon_box__icon__rounded";
      break;
    case "round_dark":
      typeMainClass = "icon_box__rounded_dark";
      typeIconClass = "icon_box__icon__rounded_dark";
      break;
    case "transparent":
      typeMainClass = "icon_box__transparent";
      typeIconClass = "icon_box__icon__transparent";
      break;
    default:
      typeMainClass = "icon_box";
      typeIconClass = "icon_box__icon";
      break;
  }

  if (active && type != "round_dark") {
    typeMainClass += " icon_box__active";
    typeIconClass += " icon_box__icon__active";
  }

  if (active && type == "round_dark") {
    typeMainClass += " icon_box__active_dark";
    typeIconClass += " icon_box__icon__active_dark";
  }
  
  return (
    <div className={typeMainClass} style={style} {...restProps} onClick={onClick}>
      {icon && <Icon className="icon_box__icon"/>}
      {src && <Icon className={typeIconClass} src={srcLink} alt={text} />}

      {text && <p className={themeClass}>{text}</p>}
    </div>
  )
}