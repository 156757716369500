import {RotatingLines} from "react-loader-spinner"; 

export default function Loader({isVisible = true, width = 20})  {
    
  return (<RotatingLines
      strokeColor="grey"
      strokeWidth="5"
      animationDuration="0.75"
      width={width}
      visible={isVisible}/>);
};