import React, {useState, useRef, useEffect} from "react";
import "./TextInput.css";
import Icon from "../Icon";
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import dayjs from "dayjs";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { Datepicker } from 'flowbite-react';
import { TextField } from "@mui/material";

export default function TextInput(props) {
  const [isFocused, setIsFocused] = useState(false);
  const inputRef = useRef(null);
  const icon = props?.icon;

  const handleFocus = () => {
    if(props?.type == "password") {
      onValueChange({target: {value: ""}})
    }

    if (inputRef.current) {
      const inputTop = inputRef.current.getBoundingClientRect().top; // Distance from input to top of viewport
      const screenCenter = window.innerHeight / 2; // Half of the viewport height
      const scrollY = window.pageYOffset + inputTop - screenCenter; // Adjust to position input at screen center
  
      window.scrollTo({ top: scrollY, behavior: 'smooth' });
    }
    setIsFocused(true)

    props.onFocus && props.onFocus();
  };

  const handleBlur = () => {
    setIsFocused(false)
  };

  
  const onValueChange = (value) => {
    if(props?.type == "number" && value.target.value != "") {
      if((/^\d+$/.test(value.target.value) )) {
        props.onChange(value);
      }
      return;
    }

    props.onChange(value);
  }

  // this is a hack to show placeholder in date input, it is bad !!!
  if(props?.type == "date" && props?.value == "") {
    setTimeout(() => {
      document.querySelector('.MuiInputBase-input').value = props.placeholder;
    }, 0);
  }

  const isValid = props.validator ? (props.validator(props.value) || props.value?.length == 0 || !props.value || isFocused) : true;

  if(props.theme == "outlined_light") {

    const DateInput =
      <MobileDatePicker
          ref={inputRef}
          required
          name={props.name}
          minDate={new dayjs(new Date().setFullYear(new Date().getFullYear() - 100))}
          maxDate={new dayjs(new Date().setFullYear(new Date().getFullYear() - 15))} 
          value={new dayjs(props.value)}
          onChange={(event) => onValueChange({target: {value: event.toDate()}})}
          onOpen={handleFocus}
          onAccept={handleBlur}
          onClose={handleBlur}
          onError={handleBlur}
          sx={{
            '.MuiOutlinedInput-notchedOutline': {
              border: 'none',
            },
            '.MuiOutlinedInput-root': {
              color: (!props.value || props.value == "") ? '#B7BDC9' : '#4D4C52',
              '& .MuiOutlinedInput-input': {
                padding: '12px 0px', 
              },
            },
          }}
          slotProps={{
            layout: {
              sx: {
                '.MuiPickersToolbar-root': {
                  display: 'none',
                },
              }
            }
          }} 
        />;

  const TextInput = <input
      ref={inputRef}
      required
      className="text_input__outlined_light__input_form"
      type={props.type}
      placeholder={props.placeholder}
      value={props.value}
      onChange={onValueChange}
      onFocus={handleFocus}
      onBlur={handleBlur}
    />;

    return (
      <div className="text_input__container" style={props.underlineText && { height: 68, ...props.style }}>
        <div className={isValid ? isFocused ? "text_input__outlined_light_focused" : "text_input__outlined_light" : "text_input__outlined_light_error"}>
          {icon && <Icon src={icon} alt="icon" className={isFocused ? "text_input__outlined_light__icon_focused" : "text_input__outlined_light__icon"} />}
          {props.type == "date" 
          ? DateInput
          : TextInput }
        </div>
        {(isValid && props.underlineText) && <p className="text_input__outlined_light__underline_text">{props.underlineText}</p>}
        {!isValid && <p className="text_input__outlined_light__error_text">{props.errorText}</p>}
      </div>
    );
  }
  

  return (
    <div className="text_input" style={props.style}>
      {icon && <Icon src={icon} className="text_input__icon" />}
      <input
        ref={inputRef}
        required
        className="input_form"
        type={props.type}
        placeholder={props.placeholder}
        value={props.value}
        onChange={onValueChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        {...props}
      ></input>
    </div>
  );
  
}
