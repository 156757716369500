import "./SocialBoxGrid.css";
import SocialBox from "./SocialBox";
import { SOCIAL_ICONS } from "../../constants/Icons";

export default function SocialBoxGrid(props) {
  
  if(!props.values || props.values.length < 1) {
    return <></>;
  }

  return (
    <div className="social_box_grid">
      {props.values.map((value, index) => 
        <SocialBox key={index} text={value.text} src={SOCIAL_ICONS[value.text]} onClick={props.onClick}/>
      )}
    </div>
  )
   
  
}