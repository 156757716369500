// import { getUser } from '../services/userService';
import React, { useState, useEffect, useContext, useRef } from 'react';
import CommonQuestionsPage from '../pages/CommonQuestionsPage/CommonQuestionsPage';

function CommonQuestionsScreen() {
    
    return (
        <CommonQuestionsPage
        />
    );
}

export default CommonQuestionsScreen;
