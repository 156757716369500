import React, { useState, useContext, useEffect } from 'react';
import ArticleDetailPage from '../pages/ArticleDetailPage';
import { GlobalContext } from '../providers/GlobalContext';
import { useParams } from 'react-router-dom';
import { formatName, formatUrl } from '../utils/FormatUtils';
import ArchivementIcon from "../assets/icons/Achievement-Icon.svg";
import QualificationIcon from "../assets/icons/Qualification-Icon.svg";
import { getSubscriptionById } from '../services/SubscriptionService';
import SubscriptionDetailPage from '../pages/SubscriptionDetailPage/SubscriptionDetailPage';
import {getTrainerById} from '../services/TrainerService';

function SubscriptionDetailScreen() {
  const {user, setUser} = useContext(GlobalContext);
  const { id } = useParams();
  
  const [subscription, setSubscription] = useState({});
  const [subscriptionTrainer, setSubscriptionTrainer] = useState({}); 
  const [subscriptionArticle, setSubscriptionArticle] = useState({});

  useEffect(() => {
    getSubscription(id);
  }, []);

  async function getSubscription(id) {
    const response = await getSubscriptionById(id);

    const subscription = response.body;
    const article = response.body.article;

    setSubscription(subscription);
    setSubscriptionArticle(article);

    console.debug("get subscription by id result: ", subscription);

    const trainerResponse = await getTrainerById(response.body.trainer.id);

    setSubscriptionTrainer(trainerResponse.body);

    console.debug("get trainer by id result: ", trainerResponse.body);
  }

  function showMoreHandler() {
    // TODO stahnout ukazku
  }

  function onRelatedArticleClickHandler(relatedArticleName) {
    // TODO podle jmena trenera
    // window.location.href = `${formatName(article.name)}/${formatName(relatedArticleName)}`;
  }

  function onPurchaseClickHandler() {
    window.location.href = `/checkout/${subscriptionArticle.id}`;
  }

  function onTrainerClickHandler() {
    const formattedString = subscriptionTrainer.name
      .normalize("NFD") // Normalize the string to decompose accented characters
      .replace(/[\u0300-\u036f]/g, "") // Remove diacritics
      .toLowerCase() // Convert to lowercase
      .replace(/\s+/g, "-") // Replace spaces with hyphens
      .replace(/[^a-zA-Z0-9-]/g, ""); // Remove non-alphanumeric characters except hyphen

    window.location.href = `/${formattedString}`;
  }

  function onContactClickHandler() {
    window.location.href = `tel:${subscriptionTrainer.phoneNumber}`;
  }

  function onSubscribeEditClickHandler() {
    window.location.href = `/subscription/edit/${id}`;
  }

  function onReportClickHandler() {
    window.location.href = `mailto:info@nextcoach.cz?subject=Nahlášení%20objednávky%20-%20${subscription.id}%20-%20${subscriptionArticle.name}`;
  }

  function onReceiptClickHandler() {
    const latestReceiptUrl = subscription.purchases?.sort((a, b) => a.validTo - b.validFrom)[0].receiptUrl || "";
    window.location.href = latestReceiptUrl;
  }

  function onDocumentClickHandler(documentUrl) {
    const formatedUrl = formatUrl(documentUrl);
    console.log("documentUrl", documentUrl);
    console.log("formatedUrl", formatedUrl);

    // if it ends with .pdf
    if(formatedUrl.endsWith(".pdf")) {
      window.location.href = "/document/" + formatedUrl;
      return;
    }

    window.location.href = documentUrl;
  }


  const contentMapped = subscription.content?.map((content) => {
    return {
      text: content.name,
      contentUri: content.contentUri,
    }
  }) || [];

  const achievementMapped = subscriptionArticle.achievements?.map((achievement) => {
    return {
      text: achievement,
      src: ArchivementIcon,
    }
  }) || [];


  const requirementMapped = subscriptionArticle.requirements?.map((requirement) => {
    return {
      text: requirement,
      src: QualificationIcon,
    }
  }) || [];

  const relatedArticlesMapped = subscriptionArticle.relatedArticles?.map((relatedArticle) => {
    return {
      title: relatedArticle.name,
      description: relatedArticle.description,
      src: relatedArticle.imageUri?.[0] || "",
    }
  }) || [];

  const article = subscriptionTrainer.articles?.find((article) => article.id === subscriptionArticle.id);

  const nextPaymentDate = subscription.purchases?.sort((a, b) => a?.validTo - b?.validFrom)[0]?.validTo || "";

  return (
    <SubscriptionDetailPage
      id={subscriptionArticle.id}
      name={subscriptionArticle.name}
      about={subscriptionArticle.about}
      price={subscriptionArticle.price}
      content={contentMapped}
      comment={subscription.comment}
      updatedAt={subscription.updatedAt}
      createdAt={subscription.createdAt}
      nextPaymentDate={nextPaymentDate}
      deliveryStatus={subscription.status == "EXPIRED" ? "EXPIRED" : subscription.deliveryStatus}
      subscriptionImage={subscriptionArticle?.imageUri?.[0]}
      trainer={subscriptionTrainer}
      article={article}
      client={subscription.client}
      relatedArticles={relatedArticlesMapped}
      isTrainer={user?.id === subscriptionTrainer.id}
      onShowMoreClick={showMoreHandler}
      onRelatedArticleClick={onRelatedArticleClickHandler}
      onPurchaseClick={onPurchaseClickHandler}
      onTrainerClick={onTrainerClickHandler}
      onContactClick={onContactClickHandler}
      onSubscribeEditClick={onSubscribeEditClickHandler}
      onReportClick={onReportClickHandler}
      onReceiptClick={onReceiptClickHandler}
      onDocumentClick={onDocumentClickHandler}
    />
  );
}

export default SubscriptionDetailScreen;
