import React, { useState, useContext, useEffect } from 'react';
import ArticleDetailPage from '../pages/ArticleDetailPage';
import { GlobalContext } from '../providers/GlobalContext';
import { getTrainerByName } from '../services/TrainerService';
import { useParams } from 'react-router-dom';
import { formatName } from '../utils/FormatUtils';
import ArchivementIcon from "../assets/icons/Achievement-Icon.svg";
import QualificationIcon from "../assets/icons/Qualification-Icon.svg";
import TrainerAffiliatePage from '../pages/TrainerAffiliatePage/TrainerAffiliatePage';
import UseDimensions from '../hooks/UseDimensions';

export default function TrainerAffiliate() {
  const {user, setUser} = useContext(GlobalContext);
  const { username } = useParams();
  const { height } = UseDimensions();
  
  const [trainer, setTrainer] = useState({}); 

  useEffect(() => {
    getTrainerArticle(username);
  }, []);

  async function getTrainerArticle(username) {
    const response = await getTrainerByName(username);


    setTrainer(response.body);
    
    console.debug("get trainer by id result: ", response.body);
  }

  const trainerName = formatName(trainer.name || "");

  console.log(trainerName);
  
  return (
    <TrainerAffiliatePage 
      id={trainer.id}
      username={trainer.name}
      height={height}
      redirect={trainerName}
    />
  );
}
