import React, { useContext, useEffect, useRef, useState } from "react";
import { useStripe } from "@stripe/react-stripe-js";
import { getTrainerBank, postActivateAccount } from "../services/TrainerService";
import TrainerBankPage from "../pages/TrainerBankPage/TrainerBankPage";
import { GlobalContext } from "../providers/GlobalContext";
import DashboardPage from "../pages/DashboardPage/DashboardPage";
import BundleIcon from "../assets/icons/Bundle-Icon.svg";
import SubscriptionIcon from "../assets/icons/Subscription-Icon.svg";
import { getRefresh } from "../services/UserService";
import { formatName } from "../utils/FormatUtils";
import { getAllTrainerSubscriptions } from "../services/SubscriptionService";
import ProfilePage from "../pages/ProfilePage/ProfilePage";

export default function ProfileScreen() {
  const { user, setUser } = useContext(GlobalContext);
  
  const isTrainer = user?.trainer?.length > 0 ? true : false;


  function onEditInfoClick() {
    window.location.href = "/profile/edit/info";
  }

  function onEditPaymentClick() {
    window.location.href = "/profile/edit/bank";
  }

  function onEditPublicInfoClick() {
    window.location.href = "/trainer/edit/info";
  }

  function onEditPrivateInfoClick() {
    window.location.href = "/trainer/edit/bank";
  }

  function onEditCertificateClick() {
    window.location.href = "/trainer/edit/certificate";
  }

  function onSupportClick() {
    window.location.href = "/contactUs";
  }

  function onDataProtectionClick() {
    window.location.href = "/privacy";
  }

  function onConditionClick() {
    window.location.href = "/conditions";
  }

  function onIamTrainerClick() {
    window.location.href = "/trainer/welcome";
  }

  function onQuestionsClick() {
    window.location.href = "/questions";
  }

  function onLogoutClick() {
    setUser({token : "/"});
    window.location = "/";
  }

  return (
    <ProfilePage
      isTrainer={isTrainer}
      onEditInfoClick={onEditInfoClick}
      onEditPaymentClick={onEditPaymentClick}
      onEditPublicInfoClick={onEditPublicInfoClick}
      onEditPrivateInfoClick={onEditPrivateInfoClick}
      onEditCertificateClick={onEditCertificateClick}
      onSupportClick={onSupportClick}
      onDataProtectionClick={onDataProtectionClick}
      onConditionClick={onConditionClick}
      onIamTrainerClick={onIamTrainerClick}
      onQuestionsClick={onQuestionsClick}
      onLogoutClick={onLogoutClick}
    />
  );
}
