// import { getUser } from '../services/userService';
import React, { useState, useEffect, useContext, useRef } from 'react';
import { GlobalContext } from '../providers/GlobalContext';
import TrainerWelcomePage from '../pages/TrainerWelcomePage/TrainerWelcomePage';

function TrainerWelcomeScreen() {
    const { user, setUser } = useContext(GlobalContext);
    const [sliderPrice, setSliderPrice] = useState({ x: 27, y: 0 });
    const [slider, setSlider] = useState({ x: 70, y: 0 });
    const [isLoginModalVisible, setIsLoginModalVisible] = useState(false);

    const basePrice = Math.round((sliderPrice.x + 3) * 50);
    const count = Math.round(slider.x / 4.8);
    const finalPrice = basePrice * count;
    const isTrainer = Boolean(user?.trainer?.length > 0 );

    function onClick() {
        const isAutenticated = Boolean(user?.username || user.isLogged)
        if(isTrainer) {
            window.location.href = `/trainer/dashboard`;
            return;
        }
        
        if (isAutenticated) {
            window.location.href = `/trainer/register`;
            return;
        }

        setIsLoginModalVisible(true);        
    }

    return (
        <TrainerWelcomePage
           slider={slider}
           setSlider={setSlider}
           sliderPrice={sliderPrice}
           setSliderPrice={setSliderPrice}
           basePrice={basePrice}
           finalPrice={finalPrice}
           count={count}
           onClick={onClick}
           isLoginModalVisible={isLoginModalVisible}
           setIsLoginModalVisible={setIsLoginModalVisible}
           isTrainer={isTrainer}
        />
    );
}

export default TrainerWelcomeScreen;
