// import { getUser } from '../services/userService';
import React, { useState, useEffect, useContext, useRef } from 'react';
import HomePage from '../pages/HomePage';
import { GlobalContext } from '../providers/GlobalContext';
import UseDimensions from '../hooks/UseDimensions';
import MainVideo from "../assets/videos/Main-Video.mp4";
import PackageImage from "../assets/images/Package-Image.webp";
import FreePackageImage from "../assets/images/Free-Package-Image.webp";
import OnlineCoachingImage from "../assets/images/Online-Coaching-Image.webp";
import { useNavigate } from 'react-router-dom';


function HomeScreen() {
    const { user, setUser } = useContext(GlobalContext);
    const [isLoaded, setIsLoaded] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);
    const homePageRef = useRef(null);
    const {height} = UseDimensions();
    const lastScrollPos = useRef(0);
    const navigate = useNavigate();

    useEffect(() => {
        const handleScroll = () => {
            if (homePageRef.current) {
                const { scrollTop, offsetHeight } = homePageRef.current;
                const index = (scrollTop / offsetHeight);

                // for footer
                if(index > 4) {
                    setCurrentIndex(-1);
                    return;
                }

                // for better scroll experience
                if(index % 1 < 0.1 || index % 1 > 0.9) {
                    setCurrentIndex(Math.round(index));
                    return;
                }

                setCurrentIndex(index);
            }
        };

        // set body overflow to hidden
        document.body.style.overflow = 'hidden';

        // Add event listener to the scrollable container
        const homePageElement = homePageRef.current;
        homePageElement.addEventListener('scroll', handleScroll);

        return () => {
            homePageElement.removeEventListener('scroll', handleScroll);
            document.body.style.overflow = 'auto';
        };
    }, []);


    const updateScrollDirection = (currentScrollPos) => {
        const isScrollingDown = currentScrollPos > lastScrollPos.current;
        console.log(isScrollingDown ? 'Scrolling Down' : 'Scrolling Up');
        lastScrollPos.current = currentScrollPos;
      };
    
    const onHandleScroll = (event) => {
        if (homePageRef && homePageRef.current) {
            const currentScrollPos = homePageRef.current.scrollTop;
            updateScrollDirection(currentScrollPos);
            homePageRef.current.scrollBy({ top: event.deltaY, behavior: 'smooth' });
        }
    };

    const onHandleTouchMove = (event) => {
        if (homePageRef && homePageRef.current) {
            event.preventDefault();

            // Calculate current scroll position based on touch movement
            const touchPos = (event.targetTouches[0].clientY);
            const touchDelta = lastScrollPos.current - touchPos;
            const currentScrollPos = homePageRef.current.scrollTop + touchDelta;

            updateScrollDirection(currentScrollPos);
            homePageRef.current.scrollBy({ top: touchDelta, behavior: 'smooth' });

            lastScrollPos.current = touchPos; // Update last touch position
        }
    };

    const onStartJourneyClick = () => {
        window.location.href = "/shop/trainer";
    }

    const onTrainingClick = () => {
        window.location.href = "/shop/training";
    }

    const onBundleClick = () => {
        window.location.href = "/shop/package";
    }

    const onSubscriptionClick = () => {
        window.location.href = "/shop/subscription";
    }

    const onCompletClick = () => {
        window.location.href = "/shop/package";
    }

    const onTrainerClick = () => {
        window.location.href = "/shop/trainer";
    }


    const onIamTrainerClick = () => {
        window.location.href = "/trainer/welcome";
    }

    const videos = [
        MainVideo,
        PackageImage,
        OnlineCoachingImage,
        PackageImage,
        FreePackageImage
    ];

    return (
        <HomePage
            pageHeight={height}
            pageIndex={currentIndex}
            pageRef={homePageRef}
            isLoaded={isLoaded}
            setIsLoaded={setIsLoaded}
            videos={videos}
            onStartJourneyClick={onStartJourneyClick}
            onTrainingClick={onTrainingClick}
            onBundleClick={onBundleClick}
            onSubscriptionClick={onSubscriptionClick}
            onIamTrainerClick={onIamTrainerClick}
            onTrainerClick={onTrainerClick}
            onCompletClick={onCompletClick}
            onHandleScroll={onHandleScroll}
            onHandleTouchMove={onHandleTouchMove}
        />
    );
}

export default HomeScreen;
