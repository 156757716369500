import React from "react";
import './SubscriptionListPage.css';
import EmptySubsciptionBox from "../../components/Boxes/EmptySubscriptionBox";
import SubscriptionBox from "../../components/Boxes/SubscriptionBox";
import LoginModal from "../../components/modal/LoginModal";
import {SwitchHeader} from "../../components/Navbar/SwitchHeader";
import SubscriptionIcon from "../../assets/icons/Subscription-Icon.svg";
import BundleIcon from "../../assets/icons/Bundle-Icon.svg";

function SubscriptionListPage(props) {
  const {height, trainingLayoutType, subscriptionLayoutType, packageLayoutType, trainings, subscriptions, packages, onSubscripitonClick, onButtonClick, headerValues, layout} = props;


  if(layout == "TRAINING") {
    return (
      <>
      <SwitchHeader values={headerValues}/>
        <div className="subscription_list_page" style={{minHeight: height - 64}} >
          <div className="subscription_list_page__layout"> 
            {trainings?.map((subscription, key) => 
            <SubscriptionBox
              id={subscription.id}
              title={subscription.article.name}
              deliveryStatus={subscription.deliveryStatus}
              src={subscription.article.imageUri[0] || ""}
              key={subscription.id}
              hidden={subscription.status != "ACTIVE"}
              onClick={onSubscripitonClick}
              />
            )}
            {trainingLayoutType == "LIST" && <EmptySubsciptionBox title={"Žádné další tréninky nemáš"} buttonText={"Do obchodu"} onClick={onButtonClick}/>}
            {trainingLayoutType == "EMPTY" && <EmptySubsciptionBox title={"Žádné tréninky nemáš"} buttonText={"Do obchodu"} onClick={onButtonClick}/>}
            {trainingLayoutType == "UNAUTHORIZED" && <EmptySubsciptionBox title={"Pro zobrazení svých tréninků musíš být přihlášen"} buttonText={"Přihlásit se"} onClick={onButtonClick}/>}
          </div>
        </div>
        <LoginModal
          redirect={"subscription"}
          isVisible={props.isLoginModalVisible}
          setIsVisible={props.setIsLoginModalVisible}
      />
    </>
    );
  }

  if(layout == "SUBSCRIPTION") {
    return (
      <>
      <SwitchHeader values={headerValues}/>
        <div className="subscription_list_page" style={{minHeight: height - 64}} >
          <div className="subscription_list_page__layout"> 
            {subscriptions?.map((subscription, key) => 
            <SubscriptionBox
              id={subscription.id}
              title={subscription.article.name}
              deliveryStatus={subscription.deliveryStatus}
              src={subscription.article.imageUri[0] || ""}
              key={subscription.id}
              hidden={subscription.status != "ACTIVE"}
              onClick={onSubscripitonClick}
              />
            )}
            {subscriptionLayoutType == "LIST" && <EmptySubsciptionBox title={"Žádné další předplatné nemáš"} buttonText={"Do obchodu"} onClick={onButtonClick}/>}
            {subscriptionLayoutType == "EMPTY" && <EmptySubsciptionBox title={"Žádné předplatné nemáš"} buttonText={"Do obchodu"} onClick={onButtonClick}/>}
            {subscriptionLayoutType == "UNAUTHORIZED" && <EmptySubsciptionBox title={"Pro zobrazení svých předplatných musíš být přihlášen"} buttonText={"Přihlásit se"} onClick={onButtonClick}/>}
          </div>
        </div>
        <LoginModal
          redirect={"subscription"}
          isVisible={props.isLoginModalVisible}
          setIsVisible={props.setIsLoginModalVisible}
      />
    </>
    );
  }

  return (
    <>
      <SwitchHeader values={headerValues}/>
      <div className="subscription_list_page" style={{minHeight: height - 64}}>
        <div className="subscription_list_page__layout"> 
          {packages?.map((subscription, key) => 
          <SubscriptionBox
            id={subscription.id}
            title={subscription.article.name}
            deliveryStatus={subscription.deliveryStatus}
            src={subscription.article.imageUri[0] || ""}
            key={subscription.id}
            hidden={subscription.status != "ACTIVE"}
            onClick={onSubscripitonClick}
            />
          )}
          {packageLayoutType == "LIST" && <EmptySubsciptionBox title={"Žádné další balíčky nemáš"} buttonText={"Do obchodu"} onClick={onButtonClick}/>}
          {packageLayoutType == "EMPTY" && <EmptySubsciptionBox title={"Vyzvedni si balíček zdarma"} buttonText={"Vyzvednout"} onClick={onButtonClick}/>}
          {packageLayoutType == "UNAUTHORIZED" && <EmptySubsciptionBox title={"Pro zobrazení svých balíčků musíš být přihlášen"} buttonText={"Přihlásit se"} onClick={onButtonClick}/>}
        </div>
      </div>
      <LoginModal
        redirect={"subscription"}
        isVisible={props.isLoginModalVisible}
        setIsVisible={props.setIsLoginModalVisible}
      />
    </>
  );

}
export default SubscriptionListPage;
