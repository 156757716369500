import React from "react";
import './CheckoutPage.css';
import { PaymentElement, Elements, useStripe, useElements } from '@stripe/react-stripe-js';
import ButtonPrimary from "../components/Buttons/Primary/ButtonPrimary";
import TrainerBox from "../components/Boxes/TrainerBox";
import Loader from "../components/Loader";
import PaymentCardComponent from "../components/PaymentCardComponent";
import SuccessIcon from "../assets/icons/Success-White-Icon.svg";
import Icon from "../components/Icon";
import { formatPrice } from "../utils/FormatUtils";
import SmallTrainerBox from "../components/Boxes/SmallTrainerBox";
import { Link } from "react-router-dom";

function CheckoutPage(props) {

    const options = {
        terms: {
          card: 'never',
          applePay: 'never',
          googlePay: 'never',
        }
    }
      

    return (
        <div className="checkout_page">
           <SmallTrainerBox 
              title={props.article?.name}
              description={`${formatPrice(props.price / 100, true)} / ${props.type == "subscription" ? "měsíčně" : "jednorázově"}`}
              src={props.article?.imageUri?.length > 0 && props.article?.imageUri[0]}
              onClick={() => {}}
              />

          <div className="checkout_page__separator"/>

          {props.showElement 
          ? <PaymentElement options={options} /> 
          : props.type != "free" 
          ? <PaymentCardComponent
              id={props.card.id}
              brand={props.card.brand}
              last4={props.card.last4}
              validToMonth={props.card.toMonth}
              validToYear={props.card.toYear}
              theme={"light"}
              onDeleteClick={props.onDeleteClick}
            /> : null
          }
              
          {props.type == "subscription" 
          ? <p className="checkout_page__h4">
            <span className="">Kliknutím na tlačítko "Zaplatit Kartou" souhlasíte s platebními podmínkami </span>
            <Link onClick={props.onConditionsClick}  className="">NextCoach Payments</Link>
            <span className=""> a s tím, že NextCoach automaticky účtuje měsíční poplatek (aktuálně {formatPrice(props.price / 100, true)}) vaší platební metodou, dokud předplatné nezrušíte. Můžete kdykoli zrušit, abyste se vyhnuli budoucím poplatkům. K zrušení přejděte do detailu zakoupeného předplatného a klikněte na "Zrušit předplatné". </span>
          </p>
          : <p className="checkout_page__h4">
          <span className="">Kliknutím na tlačítko "Zaplatit Kartou" souhlasíte s platebními podmínkami </span>
          <Link onClick={props.onConditionsClick} className="">NextCoach Payments</Link>
          <span className="">. </span>
        </p>
      }
          <ButtonPrimary 
            title={props.screenState == "loading" ? <Loader /> : props.screenState == "success" ? <Icon width={20} src={SuccessIcon}/> :  "Zakoupit"} 
            onClick={props.onSubmitClick} 
          />
        </div>
    );
}
export default CheckoutPage;
