import React, { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom"
import FilterPage from "../pages/FilterPage/FilterPage";
import UseDimensions from "../hooks/UseDimensions";
import { getFilter } from "../services/FilterService";
import StepScreen from "./StepScreen";
import MaleIcon from "../assets/icons/Male-Icon.svg";
import FemaleIcon from "../assets/icons/Female-Icon.svg";
import OtherIcon from "../assets/icons/Other-Icon.svg";
import ForbidenIcon from "../assets/icons/Forbiden-Icon.svg";
import StudentIcon from "../assets/icons/Student-Icon.svg";
import AdultIcon from "../assets/icons/Adult-Icon.svg";
import OlderAdultIcon from "../assets/icons/Older-Adult-Icon.svg";
import SeniorIcon from "../assets/icons/Senior-Icon.svg";
import Weight1Icon from "../assets/icons/Weight-1-Icon.svg";
import Weight2Icon from "../assets/icons/Weight-2-Icon.svg";
import Weight3Icon from "../assets/icons/Weight-3-Icon.svg";
import Weight4Icon from "../assets/icons/Weight-4-Icon.svg";
import WeightIcon from "../assets/icons/Weight-Icon.svg";
import SubscriptionIcon from "../assets/icons/Subscription-Icon.svg";
import BundleIcon from "../assets/icons/Bundle-Icon.svg";
import { postArticle, getArticleById, putArticle } from "../services/ArticleService";
import { getSubscriptionById, putSubsctiotion, uploadContent, deleteContent } from "../services/SubscriptionService";
import { formatName } from "../utils/FormatUtils";
import { GlobalContext } from "../providers/GlobalContext";
import { putTrainerInfo } from "../services/TrainerService";

export default function SubscriptionEditScreen() {
  const { id } = useParams();
  const {user, setUser} = useContext(GlobalContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [subscripiton, setSubscription] = useState(null);

  const statusValues = [
    { 
      index: 0,
      status: "PENDING", 
      title: "Zpracovává se",
      description: "Trenér přijal objednávku.",
      src: SubscriptionIcon,
    },
    { 
      index: 1,
      status: "CONSULTATION", 
      title: "Konzultace",
      description: "S trenérem je naplánována konzultace.",
      src: SubscriptionIcon,
     },
    { 
      index: 2,
      status: "IMPLEMENTING", 
      title: "V procesu tvorby",
      description: "Trenér pro tebe vytváří balíček.",
      src: SubscriptionIcon,
    },
    { 
      index: 3,
      status: "ACTIVE", 
      title: "Balíček aktivní",
      description: "Balíček nyní můžeš využívat naplno.",
      src: SubscriptionIcon,
    },
  ]

  useEffect(() => {
    async function fetch() {
      const response = await getSubscriptionById(id);
      if(response.success) {
        console.log("Subscription: ", response.body);
        setSubscription(response.body);
      }
    }

    fetch();
  }, []);


  
  const values = [
    {
      index: 0,
      type: "selection",
      title: "Stav objednávky",
      description: "Jaký je stav objednávky?",
      value: subscripiton ? statusValues.find(deliveryStatus => deliveryStatus.status == subscripiton.deliveryStatus)?.index : null,
      values: statusValues,
    },
    {
      index: 1,
      type: "longText",
      title: "Instrukce",
      description: "Instrukce k dalšímu kroku",
      placeholder: "Insktrukce",
      errorText: "Instrukce musí mít 10 až 2000 znaků",
      validator: (value) => value?.length >= 10 && value?.length <= 2000,
      src: OtherIcon,
      value: subscripiton?.comment || "",
    },
    {
      index: 2,
      type: "content",
      title: "Obsah balíčku",
      description: "Spravuj obsah balíčku",
      maxLength: 12,
      minLength: 0,
      exists: subscripiton?.content?.length > 0 ? true : false,
      value: subscripiton ? subscripiton?.content?.map(content => {return { text: content.name, title: content.name, src: content.contentUri }}) : null,
    },
  ];

  function onNextCallback(values) {
    console.log("Next");
    console.log(values);
  }

  async function onFinishCallback(values) {
    console.log("Finish");
    console.log(values);

    if(isSubmitting) {
      return;
    }

    setIsSubmitting(true);

    const body = {
      deliveryStatus: statusValues.find(statusValue => statusValue.index == values[0]?.value)?.status || undefined,
      comment: values[1]?.value,
    }

    const response = await putSubsctiotion(id, body);
    

    if(!response.success) {
      console.log("Error");
      console.log(response.message);
      alert(response.message);
    }

    // insert content update
    const contentBodies = values[2]?.value?.map(value => {return { name: value.title, fileName: value.title, file: value.src.substring(value.src.indexOf("base64,") + 7)}}) || [];
    
    const contentToUpload = contentBodies.filter(content => !subscripiton?.content?.map(content => content.name).includes(content.name));
    const contentToDelete = subscripiton?.content?.filter(content => !contentBodies.map(content => content.fileName).includes(content.name));

    console.log("Content bodies: ", contentBodies);
    console.log("Content to upload: ", contentToUpload);
    console.log("Content to delete: ", contentToDelete);

    const deletedContentPromisses = contentToDelete.map((content) => { return deleteContent(id, content.id) });
    const uploadedContentPromisses = contentToUpload.map((content) => { return uploadContent(id, content) });

    const uploadedContentResponses = await Promise.all([...uploadedContentPromisses, deletedContentPromisses]);
    const success = uploadedContentResponses.reduce((acc, response) => acc && response.success, true);

    if(response.success) {
      console.log("Success");

      window.location.href = `/subscription/${id}`;
    }

    if(!response.success) {
      console.log("Error");
      console.log(response.message);
      alert(response.message);
    }

    setIsSubmitting(false);
  }

  if(subscripiton == null) {
    return <div></div>
  }

  return (
    <StepScreen
      values={values}
      isLoading={isSubmitting}
      onNextCallback={onNextCallback}
      onFinishCallback={onFinishCallback}
    />
  )
}