import React, {useState, useRef} from "react";
import "./LongTextInput.css";
import Icon from "../Icon";
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import dayjs from "dayjs";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { Datepicker } from 'flowbite-react';
import { TextField } from "@mui/material";

export default function LongTextInput(props) {
  const [isFocused, setIsFocused] = useState(false);
  const inputRef = useRef(null);
  const icon = props?.icon;

  const handleFocus = () => {
    if (inputRef.current) {
      const inputTop = inputRef.current.getBoundingClientRect().top; // Distance from input to top of viewport
      const screenCenter = window.innerHeight / 2; // Half of the viewport height
      const scrollY = window.pageYOffset + inputTop - screenCenter; // Adjust to position input at screen center
  
      window.scrollTo({ top: scrollY, behavior: 'smooth' });
    }
    setIsFocused(true)
  };
  const handleBlur = () => {
    setIsFocused(false)
  };

  const isValid = props.validator ? (props.validator(props.value) || props.value?.length == 0 || !props.value || isFocused) : true;


    const DateInput = <MobileDatePicker
        ref={inputRef}
        required
        name={props.name}
        minDate={new dayjs(new Date().setFullYear(new Date().getFullYear() - 100))}
        maxDate={new dayjs(new Date().setFullYear(new Date().getFullYear() - 15))} 
        placeholder={props.placeholder}
        value={new dayjs(props.value)}
        onChange={(event) => props.onChange({target: {value: event.toDate()}})}
        onOpen={handleFocus}
        onAccept={handleBlur}
        onClose={handleBlur}
        onError={handleBlur}
        sx={{
          '.MuiOutlinedInput-notchedOutline': {
            border: 'none',
          },
          '.MuiOutlinedInput-root': {
            padding: 0, 
            color: (!props.value || props.value == "") ? '#B7BDC9' : '#4D4C52',
            '& .MuiOutlinedInput-input': {
              padding: '12px 0px', 
            },
          },
        }}
        slotProps={{
          layout: {
            sx: {
              '.MuiPickersToolbar-root': {
                display: 'none',
              },
            }
          }
        }} 
      />;

  const TextInput = <textarea
      ref={inputRef}
      required
      className="long_text_input__outlined_light__input_form"
      type={props.type}
      placeholder={props.placeholder}
      value={props.value}
      onChange={props.onChange}
      onFocus={handleFocus}
      onBlur={handleBlur}
    />;

    return (
      <div className="long_text_input__container" style={props.underlineText &&{ height: 68 }}>
        <div className={isValid ? isFocused ? "long_text_input__outlined_light_focused" : "long_text_input__outlined_light" : "long_text_input__outlined_light_error"}>
          {props.type == "date" ? DateInput : TextInput }
        </div>
        {(isValid && props.underlineText) && <p className="long_text_input__outlined_light__underline_text">{props.underlineText}</p>}
        {!isValid && <p className="long_text_input__outlined_light__error_text">{props.errorText}</p>}
      </div>
    );
  }
  
