import React from 'react';
import { motion, useAnimation } from 'framer-motion';

export default function InfiniteLoader({duration}) {
  const logarithmicEase = [0.05, 0.1, 0.25, 1];
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '4px' }}>
      <motion.div
        initial={{ width: '0%', height: '4px', borderRadius: '2px', backgroundColor: 'white' }}
        animate={{
          width: ["0%", "100%"],
        }}
        transition={{
          duration: duration,
          times: [0, 1],
          ease: logarithmicEase
        }}
      />
    </div>
  );
}