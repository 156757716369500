import React, { useState, useContext, useEffect } from "react";
import RegisterTrainerPage from "../pages/RegisterTrainerPage/RegisterTrainerPage";
import { GlobalContext } from "../providers/GlobalContext";
import { register } from "../services/UserService";
import { registerTrainer } from "../services/TrainerService";
import FemaleIcon from "../assets/icons/Female-Icon.svg";
import MaleIcon from "../assets/icons/Male-Icon.svg";

function RegisterTrainerScreen() {
  const { user, setUser } = useContext(GlobalContext);
  const [gender, setGender] = useState("male");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [street, setStreet] = useState("");
  const [city, setCity] = useState("");
  const [postCode, setPostCode] = useState("");
  const [screenState, setScreenState] = useState("idle");
  const [error, setError] = useState(null);

  useEffect(() => {
    if(user?.trainer?.length > 0) {
      window.location.href = "/home";
      return
    }
  }, [user]);

  async function onButtonClick() {
    setScreenState("loading");

    const cleanedPostCode = postCode.replace(" ", "");

    const trainerResult = await registerTrainer(gender, dateOfBirth, street, city, cleanedPostCode);

    if (!trainerResult.success) {
      console.log(trainerResult.message);
      setError(trainerResult.message);
      setScreenState("idle");
      return;
    }

    setUser({
      isLoggedIn: true,
      token: trainerResult.body.token,
      name: "Username",
      language: "cs"
    })

    setError("")
    setScreenState("success");

    setTimeout(() => {
      window.location.href = "/trainer/init";
    }, 1500);
  }

  function onGenderChange(event) {
    setGender(event.target.value);
  }

  function onDateOfBirthChange(event) {  
    console.log(event.target.value);  
    setDateOfBirth(event.target.value);
  }

  function onStreetChange(event) {
    setStreet(event.target.value);
  }

  function onCityChange(event) {
    setCity(event.target.value);
  }

  function onPostCodeChange(event) {
    const value = event.target.value;

    const is4CharSpace = value.length >= 4 && value.charAt(3) == " ";
    const maxLength = is4CharSpace ? 6 : 5;

    if ((!/^\d{0,3} ?\d*$/.test(event.target.value) && event.target.value != "") || event.target.value.length > maxLength) {
      return;
    }

    setPostCode(event.target.value);
  }

  function onConditionsClick() {
    window.location.href = "/conditionsBusiness";
  }

  const values = [
    {title: "Muž", src: MaleIcon, selected: gender == "male", onClick: () => setGender("male")},
    {title: "Žena", src: FemaleIcon, selected: gender == "female", onClick: () => setGender("female")},
  ];

  const isValid = gender != "" 
    && ( dateOfBirth != "" && new Date(dateOfBirth) > new Date(new Date().setFullYear(new Date().getFullYear() - 100)) && new Date(dateOfBirth) < new Date(new Date().setFullYear(new Date().getFullYear() - 15))) 
    && street?.length > 2 && street?.length < 30 
    && city?.length > 2 && city?.length < 30
    && postCode?.length >= 4 && postCode.charAt(3) == " " ? postCode?.length == 6 : postCode?.length == 5


  return (
    <RegisterTrainerPage
      gender={gender}
      dateOfBirth={dateOfBirth}
      street={street}
      city={city}
      postCode={postCode}
      onGenderChange={onGenderChange}
      onDateOfBirthChange={onDateOfBirthChange}
      onStreetChange={onStreetChange}
      onCityChange={onCityChange}
      onPostCodeChange={onPostCodeChange}
      onButtonClick={onButtonClick}
      onConditionsClick={onConditionsClick}
      screenState={screenState}
      error={error}
      values={values}
      submitDisabled={!isValid}
    />
  );
}
export default RegisterTrainerScreen;
