import React from "react";
import { motion } from "framer-motion";
import "./Question.css";
import BrandIcon from "../assets/icons/Brand-Icon.svg";
import ProgressIcon from "../assets/icons/Progress-Icon.svg";
import DownIcon from "../assets/icons/Down-Icon.svg";
import Icon from "./Icon";

const Question = (props) => {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <motion.div 
      className="question"
      onClick={() => setIsOpen(!isOpen)}
      >
        <div className="question__header">
          <h5 className="question__header_title">{props.title}</h5>
          <motion.div
            initial={{ rotate: 0 }}
            animate={{ rotate: isOpen ? 180 : 0 }}
            transition={{ duration: 0.5 }}
          >
            <Icon src={DownIcon} alt="icon" className="question__header_icon"/>
          </motion.div>
        </div>
      <motion.div 
        className="question__description"
        initial={{ height: 0, opacity: 0 }}
        animate={{ height: isOpen ? "auto" : 0, opacity: isOpen ? 1 : 0 }}
        transition={{ duration: 0.5, ease: "easeInOut" }}
      >
        <p className="question__description_p">{props.description}</p>
      </motion.div>
    </motion.div>
  );
};

export default React.memo(Question);
