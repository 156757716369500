import "./EmptySmallTrainerBox.css";


export default function EmptySmallTrainerBox(props) {
  const {title, ...restProps} = props;
  
  return (
    <div className="empty_small_trainer_box" {...restProps}>

        <h5 className="empty_small_trainer_box__box_h5">{title}</h5>

    </div>
  )
}