import EmptySubsciptionBox from "../Boxes/EmptySubscriptionBox";
import SubscriptionBox from "../Boxes/SubscriptionBox";

export default function ImageInput({src, text, buttonText, onClick}) {
  
  if(!src) {
    return <div className="image_input" style={{width: "100%", display: "flex", justifyContent: "center"}}>
      <EmptySubsciptionBox 
      title={text} 
      buttonText={buttonText} 
      onClick={onClick}
      style={{height: "calc(100vw - 48px)", width: "calc(100vw - 48px)", maxWidth: "450px", maxHeight: "450px"}}
      />
    </div>
  }

  return (
    <div className="image_input" style={{width: "100%", display: "flex", justifyContent: "center"}}>
      <SubscriptionBox
        id={text}
        title={""}
        deliveryStatus={null}
        src={src}
        onClick={onClick}
        style={{height: "calc(100vw - 48px)", width: "calc(100vw - 48px)", maxWidth: "450px", maxHeight: "450px"}}
      />
    </div>
  )
}