import React, { useState, useContext, useEffect } from "react";
import { GlobalContext } from "./GlobalContext";
import { getUser } from "../services/UserService";
import i18n from "./I18nProvider";

export default function GlobalContextProvider({children})  {
  const [user, setUser] = useState({});

  useEffect(() => {
    getUserData();
  }, []);

  async function getUserData() {
    const token = localStorage.getItem('token');
    const language = localStorage.getItem('language');

    if(!token) {
      return;
    }

    const userData = await getUser();
    console.debug("user", userData);

    if(!userData.success) {
      return;
    }

    setUserContext({ ...userData.body, token, language});
  }
  
  const setUserContext = (user) => {
    if(user?.token) {
      localStorage.setItem('token', user.token);
    }

    if(user?.tempToken) {
      localStorage.setItem('tempToken', user.tempToken);
    }

    if(user?.language) {
      localStorage.setItem('language', user.language);
      i18n.changeLanguage(user.language);
    }

    setUser((prewUser) => {return {...prewUser, ...user}});
  }

  
  return (
    <GlobalContext.Provider value={{user, setUser: setUserContext}}>
      {children}
    </GlobalContext.Provider>
  )
}