import "./SmallTrainerBox.css";
import EmailIcon from "../../assets/icons/Email-Icon.svg";
import PhoneIcon from "../../assets/icons/Phone-Icon.svg";
import Icon from "../Icon";
import { formatPhone, breakAfterWord } from "../../utils/FormatUtils";

export default function SmallTrainerBox(props) {
  const {src, title, description, email, showEmailIcon = true, showPhoneIcon = true, phone, onClick, type, ...restProps} = props;
  
  const srcLink = src ? src : "";


  return (
    <div 
      className={type == 'light' ? "light_small_trainer_box" : "small_trainer_box"} 
      {...restProps} 
      onClick={() => onClick(title)}
    >
      {src && <Icon className={type == "light" ? "light_small_trainer_box__image" : "small_trainer_box__image"} src={srcLink} alt={title} />}
      <div className={type == 'light' ? 'light_small_trainer_box__left_shadow'  : 'small_trainer_box__left_shadow'}/>      

     
      <div className="small_trainer_box__box">
        <h5 className={type == "light" ? "light_small_trainer_box__box_title" : "small_trainer_box__box_title"}>{title}</h5>
        <p className={type == "light" ? "light_small_trainer_box__box_description" : "small_trainer_box__box_description"}>{breakAfterWord(description, 50)}</p>
      </div>

      <div className='small_trainer_box__bottom_shadow'/>
    </div>
  )
}