import "./ProductBoxScroll.css";
import { SOCIAL_ICONS } from "../../constants/Icons";
import ProductBox from "./ProductBox";

export default function ProductBoxScroll(props) {
  if(!props.values || props.values.length < 1) {
    return (
      <div className="product_box_scroll_empty">
        {props.type == "MONTHLY_SUBSCRIPTION" && <h5 className="product_box_scroll_empty_h5">Trenér zatím nenabízí žádný coaching</h5>}
        {props.type != "MONTHLY_SUBSCRIPTION" && <h5 className="product_box_scroll_empty_h5">Trenér zatím nenabízí žádný produkt</h5>}
      </div>
    );
  }

  return (
    <div className="product_box_scroll">
      {props.values.map((value, index) => 
        <ProductBox key={index} onClick={props.onClick} type={value.type} {...value} />
      )}
    </div>
  )
   
  
}