import React from "react";
import "./TrainerAffiliatePage.css";
import LoginModal, { LoginView } from "../../components/modal/LoginModal";
import SubmitButton from "../../components/Buttons/Submit/SubmitButton";
import Icon from "../../components/Icon";

export default function TrainerAffiliatePage(props) {
  const { height, id, username, redirect } = props;

  return (
    <>
      <div className="trainer_affiliate_page" style={{ minHeight: height - 64 }}>
        <div className="trainer_affiliate_page_info_container" style={{ minHeight: height - 64 }}>
          <LoginView 
          title={username + " ti dal exkluzivní příležitost být NextCoach."}
          subtitle={"Registrovat se můžeš pomocí Google či E-mailu"}
          type={"big"}
          redirect={redirect} 
          onSuccess={() => {}} 
          affiliateId={id} 
          />
        </div>
      </div>
    </>
  );
}
