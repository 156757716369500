import React from "react";
import './SubscriptionDetailPage.css'; 
import { Trans } from "react-i18next";
import i18n from '../../providers/I18nProvider';
import IconBoxGrid from "../../components/Boxes/IconBoxGrid";
import ButtonPrimary from "../../components/Buttons/Primary/ButtonPrimary";
import ProductBoxScroll from "../../components/Boxes/ProductBoxScroll";
import ProcessComponent from "../../components/ProcessComponent";
import {BottomNavbar} from "../../components/Navbar/BottomNavbar";
import DeliveryStatusComponent from "../../components/DeliveryStatusComponent";
import ContentBoxGrid from "../../components/Boxes/ContentBoxGrid";
import TrainerBox from "../../components/Boxes/TrainerBox";
import LoginModal from "../../components/modal/LoginModal";
import Icon from "../../components/Icon";
import { MenuComponent } from "../../components/MenuComponent";
import FiRrUsdCircle from "../../assets/icons/rrfi/fi-rr-usd-circle.svg";
import FiRrClipboardList from "../../assets/icons/rrfi/fi-rr-clipboard-list.svg";
import FiRrTimeCheck from "../../assets/icons/rrfi/fi-rr-time-check.svg";
import FiRrStamp from "../../assets/icons/rrfi/fi-rr-stamp.svg";
import FiRrBullhorn from "../../assets/icons/rrfi/fi-rr-bullhorn.svg";
import FiRrBadgeDollar from "../../assets/icons/rrfi/fi-rr-badge-dollar.svg";
import FiRrBoxOpenFull from "../../assets/icons/rrfi/fi-rr-box-open-full.svg";
import FiRrFileImage from "../../assets/icons/rrfi/fi-rr-file-image.svg";
import FiRrInputText from "../../assets/icons/rrfi/fi-rr-input-text.svg";
import FiRrAttributePen from "../../assets/icons/rrfi/fi-rr-attribution-pen.svg";
import FiRrCommentAlt from "../../assets/icons/rrfi/fi-rr-comment-alt.svg";
import FiRrWhistle from "../../assets/icons/rrfi/fi-rr-whistle.svg";
import FiRrBoxAlt from "../../assets/icons/rrfi/fi-rr-box-alt.svg";
import FiRrBoxCheck from "../../assets/icons/rrfi/fi-rr-box-check.svg";
import FiRrSquareStar from "../../assets/icons/rrfi/fi-rr-square-star.svg";
import FiRrCoins from "../../assets/icons/rrfi/fi-rr-coins.svg";
import FiRrReceipt from "../../assets/icons/rrfi/fi-rr-receipt.svg";
import FiRrCalendar from "../../assets/icons/rrfi/fi-rr-calendar.svg";
import FiRrCalendarPen from "../../assets/icons/rrfi/fi-rr-calendar-pen.svg";
import FiRrCalendarClock from "../../assets/icons/rrfi/fi-rr-calendar-clock.svg";
import { formatDate, formatPrice } from "../../utils/FormatUtils";

function SubscriptionDetailPage(props) {

    const Items = {
        FREE: [
            {type: "box", src: FiRrCalendar, title: "Datum objednání", value: formatDate(props.createdAt)},
            {type: "box", src: FiRrCalendarPen, title: "Poslední změna", value: formatDate(props.updatedAt)},
            {type: "box", src: FiRrCoins, title: "Platba", value: formatPrice(props.price)},
            {type: "line"},
            {type: "button", src: FiRrAttributePen, title: "Nahlásit problém", onClick: props.onReportClick},
        ],
        ONE_TIME_PURCHASE: [
            {type: "box", src: FiRrCalendar, title: "Datum objednání", value: formatDate(props.createdAt)},
            {type: "box", src: FiRrCalendarPen, title: "Poslední změna", value: formatDate(props.updatedAt)},
            {type: "box", src: FiRrCoins, title: "Platba", value: formatPrice(props.price)},
            {type: "line"},
            {type: "button", src: FiRrReceipt, title: "Účtenka", onClick: props.onReceiptClick},
            {type: "line"},
            {type: "button", src: FiRrAttributePen, title: "Nahlásit problém", onClick: props.onReportClick},
        ],
        MONTHLY_SUBSCRIPTION: [
            {type: "box", src: FiRrCalendar, title: "Datum zahájení", value: formatDate(props.createdAt)},
            {type: "box", src: FiRrCalendarPen, title: "Poslední změna", value: formatDate(props.updatedAt)},
            {type: "box", src: FiRrCalendarClock, title: "Další platba", value: formatDate(props.nextPaymentDate)},
            {type: "box", src: FiRrCoins, title: "Měsíčně", value: formatPrice(props.price)},
            {type: "line"},
            {type: "button", src: FiRrReceipt, title: "Účtenka", onClick: props.onReceiptClick},
            {type: "line"},
            {type: "button", src: FiRrAttributePen, title: "Nahlásit problém", onClick: props.onReportClick},
        ],
}

    const ShowMoreBox = () => {
        return (
            <div className="subscription_detail_page__head_box_show_more">
            
                <h3 className="trainer_profile_page__head_box_show_more_h2">Soubory</h3>
                <div className="subscription_detail_page__head_box_show_more_box">
                    <ContentBoxGrid values={props.content} onClick={props.onDocumentClick} />
                </div>
                <h3 className="trainer_profile_page__head_box_show_more_h2">Instrukce</h3>
                <p className="trainer_profile_page__head_box_show_more_h4">{props.comment}</p>
            </div>       
        );
    };

    const TrainerBody = () => {
        return (
        <div className="subscription_detail_page__body">
            <h3 className="subscription_detail_page__trainer_h2">Tvůj Trenér</h3>
            <TrainerBox  
                title={props.trainer?.name}
                description={props.trainer?.description}
                email={props.trainer?.email}
                phone={props.trainer?.phoneNumber}
                src={props.trainer?.imageUri?.length > 0 && props.trainer?.imageUri[0]}
                onClick={props.onTrainerClick}
                />

            <h3 className="subscription_detail_page__trainer_h2">Informace</h3>
            <MenuComponent items={Items[props.article?.type] || []}/>
        </div>);
    }

    const ClientBody = () => {
        return (
            <div className="subscription_detail_page__body">
                <h3 className="subscription_detail_page__trainer_h2">Tvůj Klient</h3>
                <TrainerBox  
                    title={props.client?.name}
                    description={props.client?.description}
                    email={props.client?.email}
                    phone={props.client?.phoneNumber}
                    // src={props.trainer?.imageUri?.length > 0 && props.trainer?.imageUri[0]}
                    // onClick={props.onTrainerClick}
                    />

                <h3 className="subscription_detail_page__trainer_h2">Informace</h3>
                <MenuComponent items={Items[props.article?.type] || []}/>
            </div>
        );
    }

    return (
        <div className="subscription_detail_page">
            <div className="subscription_detail_page__head">
                <div className="subscription_detail_page__head_header">
                    <Icon 
                        className="subscription_detail_page__head_header_image"
                        alt={`subscription ${props.name}`} 
                        src={props.subscriptionImage}
                    />
                    <div className="subscription_detail_page__head_header_shadow"/>
                    <h1 className="subscription_detail_page__head_header_h1">{props.name}</h1>
                </div>
                <div className="subscription_detail_page__head_box">
                    <DeliveryStatusComponent deliveryStatus={props.deliveryStatus} />
                    <ShowMoreBox/>
                    {props.isTrainer 
                    ? <ButtonPrimary title={"upravit"} onClick={props.onSubscribeEditClick} type={"outlined"} className="subscription_detail_page__head_box_button"/>
                    : <ButtonPrimary title={"Požádat o úpravu"} onClick={props.onContactClick} type={"outlined"} className="subscription_detail_page__head_box_button"/>
                    }
                </div>
            </div>
            {props.isTrainer ? <ClientBody/> : <TrainerBody/>}
            {props.isTrainer &&  <BottomNavbar title={`Upravit`} description={"Zpracovat objednávku"} buttonText={"Upravit"} onClick={props.onSubscribeEditClick}/>}
        </div>
    );
}

export default SubscriptionDetailPage;
