import {get, post, put} from "./ApiService";


export async function getAllArticles() {
  const response = await get(`/article/all`);
  if(response.status == 201 || response.status == 200 )  {
    const {body} = await response.json();
  
    return {success: true, body, message: "Načtení produktu proběhlo úspěšně"};
  }

  return {success: false, message: "Načtení produktu se nezdařilo"};
}


export async function getArticleById(articleId) {
  const response = await get(`/article/` + articleId);
  if(response.status == 201 || response.status == 200 )  {
    const {body} = await response.json();
  
    return {success: true, body, message: "Načtení produktu proběhlo úspěšně"};
  }

  return {success: false, message: "Načtení produktu se nezdařilo"};
}



export async function postArticle(article) {
  const response = await post(`/article`, article);
  if(response.status == 201 || response.status == 200 )  {
    const {body} = await response.json();
  
    return {success: true, body, message: "Založení produktu proběhlo úspěšně"};
  }

  return {success: false, message: "Založení produktu se nezdařilo"};
}

export async function putArticle(article, id) {
  const response = await put(`/article/` + id, article);
  if(response.status == 201 || response.status == 200 )  {
    const {body} = await response.json();
  
    return {success: true, body, message: "Změna produktu proběhla úspěšně"};
  }

  return {success: false, message: "Změna produktu se nezdařila"};
}