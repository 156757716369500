import "./SocialBox.css";
import Icon from "../Icon";

export default function SocialBox(props) {
  const {icon, src, text, onClick, ...restProps} = props;
  
  let srcLink = (src && !icon ) ? src : "";

  return (
    <div className="social_box" {...restProps} onClick={() => onClick(text)}>
      {icon && <Icon className="social_box__icon"/>}
      {src && <Icon className="social_box__icon" src={srcLink} alt={text}/>}
    </div>
  )
}