import IconBoxGrid from "./IconBoxGrid";
import "./SubscriptionBox.css";
import DeliveryStatusComponent from "../DeliveryStatusComponent";
import Icon from "../Icon";

export default function SubscriptionBox(props) {
  const {id, src, title, description, deliveryStatus, onClick, hidden, ...restProps} = props;
  
  const srcLink = src ? src : "";

  return (
    <div className="subscription_box" onClick={() => onClick(id)} style={props.hidden ? {filter: "grayscale(100%)"}: {}} {...restProps}>
      <Icon className="subscription_box__image" src={srcLink} alt={title} style={{objectFit: 'cover', height: '100%', width: '100%'}}/>
      <div className='subscription_box__top_shadow'/>      

     
      <div className="subscription_box__box">
        <h3 className="subscription_box__box_title">{title}</h3>
      </div>

      <div className="subscription_box__delivery_status">
       {deliveryStatus && <DeliveryStatusComponent deliveryStatus={hidden ? "EXPIRED" : deliveryStatus} />}
      </div>

      <div className='subscription_box__bottom_shadow'/>
    </div>
  )
}