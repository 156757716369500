import React from "react";
import "./RegisterTrainerPage.css";
import TextInput from "../../components/TextInput/TextInput";
import SubmitButton from "../../components/Buttons/Submit/SubmitButton";
import { Link } from "react-router-dom";
import Loader from "../../components/Loader";
import EmailIcon from "../../assets/icons/Email-Grey-Icon.svg";
import SuccessIcon from "../../assets/icons/Success-White-Icon.svg";
import ButtonPrimary from "../../components/Buttons/Primary/ButtonPrimary";
import Icon from "../../components/Icon";
import { Switch } from "../../components/Navbar/SwitchHeader";
import FiRrCalendarDay from "../../assets/icons/rrfi/fi-rr-calendar-day.svg";
import FiRrCity from "../../assets/icons/rrfi/fi-rr-city.svg";
import FiRrHome from "../../assets/icons/rrfi/fi-rr-home.svg";
import FiRrMapMarkerHome from "../../assets/icons/rrfi/fi-rr-map-marker-home.svg";

export default function RegisterTrainerPage(props) {
  const Error = ({ error }) => {
    if (error) {
      return (
        <div className="register-page_message" >
        <p>{error}</p>
        </div>
      );
    }
  }

  return (
    <div className="register_trainer_page" style={{minHeight: window.innerHeight}}>
      <h3 className="register_trainer_page_h2">Trenérská registrace</h3>
      
      <h5 className="register_trainer_page_h2">Osobní údaje</h5>
      <Switch values={props.values}/>
      <TextInput
        value={props.dateOfBirth}
        onChange={props.onDateOfBirthChange}
        placeholder={"Datum narození"}
        type="date"
        icon={FiRrCalendarDay} 
        theme={"outlined_light"} 
        validator={(value) => value != "" &&  new Date(value) > new Date(new Date().setFullYear(new Date().getFullYear() - 100))
          && new Date(value) < new Date(new Date().setFullYear(new Date().getFullYear() - 15))} 
        underlineText="Aby klienti věděli, kolik ti je let"
        errorText="Datum narození musí být mezi 15 a 100 lety"
      />

      <h5 className="register_trainer_page_h2">Adresa bydliště</h5>
      <TextInput
        value={props.city}
        onChange={props.onCityChange}
        type="text"
        placeholder={"Město"}
        icon={FiRrCity} 
        theme={"outlined_light"} 
        validator={(value) => value?.length > 2 && value?.length < 30} 
        underlineText="Město trvalého bydliště"
        errorText="Město není ve správném formátu"
      />
      <TextInput
        value={props.street}
        onChange={props.onStreetChange}
        type="text"
        placeholder={"Ulice a č.p."}
        icon={FiRrHome} 
        theme={"outlined_light"} 
        validator={(value) => value?.length > 2 && value?.length < 30} 
        underlineText="Ulice trvalého bydliště"
        errorText="Ulice není ve správném formátu"
      />
      <TextInput
        value={props.postCode}
        onChange={props.onPostCodeChange}
        type="text"
        placeholder={"PSČ"}
        icon={FiRrMapMarkerHome} 
        theme={"outlined_light"} 
        validator={(value) => value?.length >= 4 && value.charAt(3) == " " ? value?.length == 6 : value?.length == 5} 
        underlineText="PSČ trvalého bydliště"
        errorText="Postovní směrovací číslo není ve správném formátu"
      />

      <p className="register_page_consents">
        <span className="register_page_consents_span">Kliknutím na tlačítko "Zaregistrovat" souhlasíte s obchodními podmínkami </span>
        <Link onClick={props.onConditionsClick} className="register_page_consents_link">NextCoach Business</Link>
        <span className="register_page_consents_span"> a </span>
        <Link to="https://stripe.com/en-cz/legal/ssa/CZ" className="register_page_consents_link">Stripe Services</Link>
        <span className="register_page_consents_span"> a </span>
        <Link to="https://stripe.com/en-cz/legal/connect-account" className="register_page_consents_link">Connect Account</Link>
        .
      </p>

      <Error error={props.error} />

      <ButtonPrimary
        title={props.screenState == "loading" ? <Loader /> : props.screenState == "success" ? <Icon width={20} src={SuccessIcon}/> :  "Zaregistrovat"} 
        onClick={props.onButtonClick}
        disabled={props.submitDisabled} 
      />

    </div>
  );
}
