import React from "react";
import "./TrainerBankPage.css";
import TextInput from "../../components/TextInput/TextInput";
import SubmitButton from "../../components/Buttons/Submit/SubmitButton";
import { Link } from "react-router-dom";
import ButtonPrimary from "../../components/Buttons/Primary/ButtonPrimary";
import Loader from "../../components/Loader";
import SuccessIcon from "../../assets/icons/Success-Icon.svg";
import FiRrBank from "../../assets/icons/rrfi/fi-rr-bank.svg";
import { Icon } from "@mui/material";

export default function RegisterPage(props) {
  const Error = ({ error }) => {
    if (error) {
      return (
        <div className="register-page_message" >
        <p>{error}</p>
        </div>
      );
    }
  }

  return (
    <div className="trainer_bank_page" style={{minHeight: window.innerHeight}}>
      <h3 className="trainer_bank_page_h2">Bankovní informace</h3>
      

        {/* <TextInput
          value={props.accountNumberPrefix}
          onChange={props.handleAccountNumberPrefixChange}
          type="number"
          placeholder={"Předčíslí bankovního účtu"}
          icon={FiRrBank} 
          theme={"outlined_light"} 
          validator={(value) => value?.length < 10} 
          underlineText="Abychom věděli, kam ti poslat výdělek"
          errorText="Maximální délka předčíslí je 10 znaků"
          onFocus={props.onFocus}
        />
     */}

    <div className="trainer_bank_page_account_number"> 

        <TextInput
          value={props.accountNumber}
          onChange={props.handleAccountNumberChange}
          type={props.exists ? "text" : "text"}
          placeholder={"Číslo účtu"}
          icon={FiRrBank} 
          theme={"outlined_light"} 
          validator={(value) => value?.length > 3 && value?.length <= 20} 
          underlineText="Abychom věděli, kam ti poslat výdělek"
          errorText="Číslo bankovního účtu musí mít délku 4 až 20 znaků"
          onFocus={props.onFocus}
        />

        <TextInput
          value={props.accountNumberBank}
          onChange={props.handleAccountNumberBankChange}
          type={props.exists ? "text" : "number"}
          placeholder={"Kód banky"}
          icon={FiRrBank} 
          theme={"outlined_light"} 
          validator={(value) => value?.length == 4} 
          underlineText=" "
          errorText="Kód banky musí mít délku 4 znaky"
          onFocus={props.onFocus}
          style={{width: "75%"}}
        />

    </div>

      <p className="trainer_bank_page_consents">Na tvůj bankovní učet budeme každé pondělí automaticky vyplácet zisk z prodaných produktů. Bude vyplácen se zpožděním podle typu produktu ( Obecný balíček 7 dní, Balíček na míru 14 dní a Online Coaching 1 měsíc ) z důvodu zásad o reklamacích.</p>

      <Error error={props.error} />

      <ButtonPrimary
        title={props.screenState == "loading" ? <Loader /> : props.screenState == "success" ? <Icon width={20} src={SuccessIcon}/> :  "Uložit"} 
        onClick={props.onButtonClick}
        disabled={props.submitDisabled} 
      />

    </div>
  );
}
