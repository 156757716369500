import React from "react";
import "./ProfilePage.css";
import parsePhoneNumberFromString from "libphonenumber-js";
import TextInput from "../../components/TextInput/TextInput";
import SubmitButton from "../../components/Buttons/Submit/SubmitButton";
import { Link } from "react-router-dom";
import Loader from "../../components/Loader";
import ProfileIcon from "../../assets/icons/Profile-Icon.svg";
import WalletIcon from "../../assets/icons/Wallet-Icon.svg";
import SupportIcon from "../../assets/icons/Support-Icon.svg";
import ConditionIcon from "../../assets/icons/Condition-Icon.svg";
import SecureIcon from "../../assets/icons/Security-Icon.svg";
import LogoutIcon from "../../assets/icons/Logout-Icon.svg";
import ButtonPrimary from "../../components/Buttons/Primary/ButtonPrimary";
import ArrowRightIcon from "../../assets/icons/Arrow-Right-Icon.svg";
import Icon from "../../components/Icon";

export default function ProfilePage(props) {
  const Box = ({ src, title, onClick }) => {
    return (
      <div className="profile_page__box" onClick={onClick} >
        <div className="profile_page__box_content">
          <Icon src={src} alt={title} className="profile_page__box_icon" />
          <h5 className="profile_page__box_h5">{title}</h5>
        </div>
        <Icon src={ArrowRightIcon} alt="success" className="profile_page__box_icon" />
      </div>
    );
  }

  const Line = ({  }) => {
    return (
      <div className="profile_page__line"/>
    );
  }

  return (
    <div className="profile_page" style={{minHeight: window.innerHeight}}>
      <Box src={ProfileIcon} title={"Osobní údaje"} onClick={props.onEditInfoClick} />
      <Box src={WalletIcon} title={"Platební údaje"} onClick={props.onEditPaymentClick} />
      <Line />
      {props.isTrainer ? 
        <>
          <Box src={ProfileIcon} title={"Informace o profilu"} onClick={props.onEditPublicInfoClick} />
          <Box src={SecureIcon} title={"Bankovní údaje"} onClick={props.onEditPrivateInfoClick} />
          {/* <Box src={ConditionIcon} title={"Správa Certifikátů"} onClick={props.onEditCertificateClick} /> */}
          <Line />
        </> : 
        <>
          <Box src={ProfileIcon} title={"Jsem trenér"} onClick={props.onIamTrainerClick} />
          <Line />
        </>
      }
      <Box src={SupportIcon} title={"Často kladené dotazy"} onClick={props.onQuestionsClick} />
      {/* <Box src={SupportIcon} title={"Kontaktovat"} onClick={props.onSupportClick} /> */}
      <Box src={SecureIcon} title={"Ochrana osobních ůdajů"} onClick={props.onDataProtectionClick} />
      <Box src={ConditionIcon} title={"Obchodní podmínky"} onClick={props.onConditionClick} />
      <Box src={LogoutIcon} title={"Odhlásit se"} onClick={props.onLogoutClick} />
    </div>
  );
}
