import React, {useEffect} from "react";
import "./Footer.css";
import SocialBoxGrid from "../Boxes/SocialBoxGrid";
import { navigate } from "../../utils/RoutingUtils";

export function Footer(props) {
  const socials = [
    {text: "facebook"},
    {text: "instagram"},
    // {text: "phone"},
    {text: "email"},
  ];

  function onClick(link) {
    navigate(link, true);
  }

  function onSocialClick(social) {
    switch (social) {
      case "email":
        window.location.href = `mailto:info@nextcoach.cz`;
        break;
      case "facebook":
        window.location.href = `https://www.facebook.com/profile.php?id=61559553327449`;
        break;
      case "instagram":
        window.location.href = `https://www.instagram.com/nextcoach.cz/`;
        break;
      default:
        break;
    }
    
  }

  const HiddenLinks = [
    /^\/home/, 
    /^\/filter\/\w+\/(\d+,)*\d+\/result$/,
    /^\/filter\/\w+\/(\d,?)+$/,
    /^\/filter\/\w+$/,
    /^\/profile\/edit\/\w+$/,
    /^\/trainer\/edit\/\w+$/,
    /^\/trainer\/package\/create/,
    /^\/trainer\/package\/update\/\w+$/,
  ];

  if( HiddenLinks.find((link) => link.test(window.location.pathname)) && !props.show) {
    return null
  };

  return (
    <div className="footer">
      <div className="footer__top">
        <div className="footer__section">
          <h5 className="footer__section_h5">NextCoach</h5>
          <p className="footer__section_p" onClick={() => onClick("/trainer/welcome")} >Jsem trenér</p>
          <p className="footer__section_p" onClick={() => onClick("/conditions")} >Obchodní podmínky</p>
          <p className="footer__section_p" onClick={() => onClick("/privacy")} >Zpracování osobních údajů</p>
        </div>
        <div className="footer__section">
          <h5 className="footer__section_h5">Podpora</h5>
          <p className="footer__section_p" onClick={() => onClick("/questions")} >Často kladené dotazy</p>
          {/* <p className="footer__section_p" onClick={() => onClick("/contactUs")} >Kontakt</p> */}
        </div>
      </div>

      <div className="footer__bottom">
        <SocialBoxGrid values={socials} onClick={onSocialClick}/>
         <p className="footer_bottom_p">© 2024 NextCoach  |  Secured by SSL</p>
      </div>
    </div>
  );
}