import "./ContentBox.css";
import FileIcon from "../../assets/icons/File-Icon.svg";
import FileUploadIcon from "../../assets/icons/File-Upload-Icon.svg";
import Icon from "../Icon";

export default function ContentBox(props) {
  const { text, contentUri, onClick, ...restProps} = props;

  const className = props.theme ? "content_box_" + props.theme : "content_box";
  
  if(props.type == "empty") {
    return (
    <div className={className + "__upload"} onClick={onClick} {...restProps}>
      <Icon className={className+ "__icon"} src={FileUploadIcon} alt={contentUri}/>
      <h5 className={className+ "__h3"}>Nahrát soubor</h5>
    </div>
    );
  }

  if(props.type == "content") {
    return (
      <div className={className + ""} onClick={onClick} {...restProps}>
      <Icon className={className+ "__icon"} src={FileIcon} alt={contentUri}/>
      <h5 className={className+ "__h3"}>{text}</h5>
    </div>);
  }

  return (
    <div className={className + ""} {...restProps}  onClick={() => onClick(contentUri)} download>
      <Icon className={className+ "__icon"} src={FileIcon} alt={contentUri}/>
      <h5 className={className+ "__h3"}>{text}</h5>
    </div>
  )
}