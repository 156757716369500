import React from "react";
import "./BadRequestPage.css";
import LoginModal from "../../components/modal/LoginModal";
import SubmitButton from "../../components/Buttons/Submit/SubmitButton";
import Icon from "../../components/Icon";

function BadRequestPage(props) {
  const { height, errCode, errIcon, errTitle, errMessage, rerouteHome } = props;

  return (
    <>
      <div className="bad_request_page" style={{ minHeight: height - 64 }}>
        <div className="bad_request_page_info_container">
          <h1 className="bad_request_page_info_container_h1">{errCode}</h1>
          <Icon src={errIcon} />
          <h3 className="bad_request_page_info_container_h3">{errTitle}</h3>
          <h5 className="bad_request_page_info_container_h5">{errMessage}</h5>
          <SubmitButton
            className="bad_request_page_back_button"
            title="Zpět na hlavní stránku"
            onClick={rerouteHome}
          />
        </div>
      </div>
      <LoginModal
        redirect={"home"}
        isVisible={props.isLoginModalVisible}
        setIsVisible={props.setIsLoginModalVisible}
      />
    </>
  );
}
export default BadRequestPage;
