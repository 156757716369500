import React, { useEffect, useState, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom"
import FilterPage from "../pages/FilterPage/FilterPage";
import UseDimensions from "../hooks/UseDimensions";
import { getFilter, postFilter } from "../services/FilterService";
import FilterResultPage from "../pages/FilterResultPage/FilterResultPage";


export default function FilterResultScreen() {
  const {type, ids} = useParams();
  const [trainers, setTrainers] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const homePageRef = useRef(null); 
  const {height} = UseDimensions();  

  useEffect(() => {
    const idsArray = ids?.split(",") || [];

    const steps = idsArray.map((id, index) => {
      return {
        index: index,
        value: parseInt(id),
      }
    })
    
    const body = {
      type: type,
      steps: steps,
    }

    Promise.all([postFilter(body), new Promise((resolve, reject) => setTimeout(() => resolve(), 3000))])
      .then(([response]) => {
        if(!response.success) {
          console.error(response.message);
          return;
        }
        
        console.log(response.body);
        setTrainers(response.body);
      })
  }, []);

  useEffect(() => {
    const handleScroll = () => {
        if (homePageRef.current) {
            const { scrollTop, offsetHeight } = homePageRef.current;
            const index = Math.round(scrollTop / offsetHeight);
            setCurrentIndex(index);
        }
    };

    // Add event listener to the scrollable container
    const homePageElement = homePageRef.current;
    homePageElement.addEventListener('scroll', handleScroll);

    // set body overflow to hidden
    document.body.style.overflow = 'hidden';

    return () => {
      homePageElement.removeEventListener('scroll', handleScroll);
      document.body.style.overflow = 'auto';
  };
  }, []);

  function onTrainerClick(name) {
    let formattedName = name.normalize("NFD").replace(/[\u0300-\u036f]/g, ""); 
    formattedName = formattedName.replaceAll(" ", "-").toLowerCase(); 

    window.location.href = "/" + formattedName;
}


  return (
    <FilterResultPage
      pageHeight={height}
      pageIndex={currentIndex}
      pageRef={homePageRef}
      isLoaded={trainers.length > 0}
      trainers={trainers}
      onTrainerClick={onTrainerClick}
    />
  )
}