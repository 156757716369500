export function goBack() {
  const previousLocation = document.referrer;
  if (previousLocation !== null) {
    window.location.href = previousLocation;
  } else {
    window.location.href = "/";
  }
}

export function navigate(link, shouldLongLoad = false) {
  // if(shouldLongLoad) {
  //   sessionStorage.removeItem('hasLoaded');
  // }

  window.location.href = link;
}

export const isValidBrowser = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  return !(userAgent.includes("Instagram") || userAgent.includes("instagram"));
};