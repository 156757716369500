import React from "react";
import "./RegisterPage.css";
import parsePhoneNumberFromString from "libphonenumber-js";
import TextInput from "../../components/TextInput/TextInput";
import SubmitButton from "../../components/Buttons/Submit/SubmitButton";
import { Link } from "react-router-dom";
import Loader from "../../components/Loader";
import ButtonPrimary from "../../components/Buttons/Primary/ButtonPrimary";
import SuccessIcon from "../../assets/icons/Success-White-Icon.svg";
import Icon from "../../components/Icon";
import EmailIcon from "../../assets/icons/Email-Grey-Icon.svg";
import FiRrUser from "../../assets/icons/rrfi/fi-rr-user.svg";
import FiRrEnvelope from "../../assets/icons/rrfi/fi-rr-envelope.svg";
import FiRrLock from "../../assets/icons/rrfi/fi-rr-lock.svg";
import FiRrPhonePlus from "../../assets/icons/rrfi/fi-rr-phone-plus.svg";


export default function RegisterPage(props) {
  const Error = ({ error }) => {
    if (error) {
      return (
        <div className="register-page_message" >
        <p>{error}</p>
        </div>
      );
    }
  }

  return (
    <div className="register_page" style={{minHeight: window.innerHeight}}>
      <h3 className="register_page_h2">Dokončení Registrace</h3>
       <TextInput 
          value={props.username}
          onChange={props.onUsernameChange}
          placeholder={"Jméno a Přijmení"}
          icon={FiRrUser} 
          type="text"
          theme="outlined_light"
          validator={(value) => value?.length > 2 && value?.length < 30 &&  value?.split(" ").length > 1 && value?.split(" ").length <= 3 && value?.split(" ").pop() != ""} 
          underlineText="Abychom věděli, jak tě oslovovat"
          errorText="Uživatelské jméno musí být formátu Jméno Příjmení a mít 3 až 30 znaků"
        />
      

        <TextInput
          value={props.email}
          onChange={props.onEmailChange}
          placeholder={"E-mail"}
          type="email"
          icon={FiRrEnvelope} 
          theme={"outlined_light"} 
          validator={(value) => value.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g)} 
          underlineText="Abys mohl dostávat potvrzení objednávek"
          errorText="E-mail není ve nesprávném formátu"
        />

        <TextInput
          value={props.phone}
          onChange={props.onPhoneChange}
          type="tel"
          placeholder={"Telefonní číslo"}
          icon={FiRrPhonePlus} 
          theme={"outlined_light"} 
          validator={(value) => value.match(/^\+\d+ \d{3} \d{3} \d{3}$/) && parsePhoneNumberFromString(value)?.isValid()}
          underlineText="Aby tě trenér měl jak kontaktovat"
          errorText="Telefonní číslo není ve správném formátu"
        />

       {props.showPassword && <TextInput
          value={props.password}
          onChange={props.onPasswordChage}
          placeholder={"Heslo"}
          type="password"
          icon={FiRrLock} 
          theme={"outlined_light"} 
          validator={(value) => value.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d!@#$%^&*\-]{8,}$/g)} 
          underlineText="Abys měl vše v bezpečí"
          errorText="Heslo musí obsahovat velké písmeno, číslo a mít alespoň 8 znaků"
        />}
    
      <p className="register_page_consents">
        <span className="register_page_consents_span">Kliknutím na tlačítko "Zaregistrovat" souhlasíte s obchodními podmínkami </span>
        <Link onClick={props.onConditionsClick} className="register_page_consents_link">Nextcoach Standard</Link>
        <span className="register_page_consents_span"> a se </span>
        <Link onClick={props.onPrivacyClick} className="register_page_consents_link">Zpracováním osobních údajů</Link>
        .
      </p>

      <Error error={props.error} />

      <ButtonPrimary 
        title={props.screenState == "loading" ? <Loader /> : props.screenState == "success" ? <Icon width={20} src={SuccessIcon}/> :  "Zaregistrovat"} 
        onClick={props.onButtonClick}
        disabled={props.submitDisabled} 
      />

    </div>
  );
}
