import React, { useState, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { GlobalContext } from "../providers/GlobalContext";
import UseDimensions from "../hooks/UseDimensions";
import DocumentPage from "../pages/DocumentPage/DocumentPage";

function DocumentScreen(props) {
  const { user, setUser } = useContext(GlobalContext);
  const {path} = useParams(); 
  const [isLoginModalVisible, setIsLoginModalVisible] = useState(false);
  const { height } = UseDimensions();

  const finalPath = "https://" + path.replace(/:/g, "/");

  return (
    <DocumentPage
      path={finalPath}
    />
  );
}
export default DocumentScreen;
