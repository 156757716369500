import React, { useContext, useEffect, useRef, useState } from "react";
import { useStripe } from "@stripe/react-stripe-js";
import { getTrainerBank, postActivateAccount } from "../services/TrainerService";
import TrainerBankPage from "../pages/TrainerBankPage/TrainerBankPage";
import { GlobalContext } from "../providers/GlobalContext";
import DashboardPage from "../pages/DashboardPage/DashboardPage";
import BundleIcon from "../assets/icons/Bundle-Icon.svg";
import SubscriptionIcon from "../assets/icons/Subscription-Icon.svg";
import { getRefresh, getUser } from "../services/UserService";
import { formatName } from "../utils/FormatUtils";
import { getAllTrainerSubscriptions } from "../services/SubscriptionService";
import { useLocation } from 'react-router-dom';

export default function DashboardScreen() {
  const { user, setUser } = useContext(GlobalContext);
  const [subscriptions, setSubscriptions] = useState([]);
  const [balance, setBalance] = useState({});
  const [layout, setLayout] = useState("SUBSCRIPTION");
  const location = useLocation();
  const trainer = user?.trainer?.length > 0 ? user.trainer[0] : null;
  
  const activationStatus = getActivationStatus(trainer)
  
  useEffect(() => {
    getBalance();
    getSubscriptions();
    getUserData();
  }, [location, location.pathname]);

  async function getUserData() {
    const userData = await getUser();
    console.debug("user", userData);

    if(!userData.success || !(userData.body?.trainer?.length > 0)) {
      window.location.href = "/error/403";
      return;
    }

    setUser({ ...userData.body });
  }
  
  async function getBalance() {
    const response = await getTrainerBank();

    if (!response.success) {
      console.error(response.message);
      return;
    }

    setBalance(response.body);

    console.debug("get balance result: ", response);
  }

  async function getSubscriptions() {
    const response = await getAllTrainerSubscriptions();

    if (!response.success) {
      console.error(response.message);
      return;
    }

    setSubscriptions(response.body);

    console.debug("get subscriptions result: ", response);
  }

  function onTrainerClickHandler() {
    const formattedString = trainer.name
      .normalize("NFD") // Normalize the string to decompose accented characters
      .replace(/[\u0300-\u036f]/g, "") // Remove diacritics
      .toLowerCase() // Convert to lowercase
      .replace(/\s+/g, "-") // Replace spaces with hyphens
      .replace(/[^a-zA-Z0-9-]/g, ""); // Remove non-alphanumeric characters except hyphen

    window.location.href = `/${formattedString}`;
  }


  function getActivationStatus(trainer) {
    // if trainer is active dont dont validate
    if(trainer?.status == "ACTIVE") {
      return null;
    }

    if (!(trainer?.imageUri?.length > 0)) {
      return "PUBLIC_INFO";
    }

    if (!balance.bankAccount || balance.bankAccount == "") {
      return "PRIVATE_INFO";
    }

    // TEMP FIX !!!
    // if(!trainer?.isVerified && !(user?.certifcateUri?.length > 0)) {
    //   return "CERTIFICATE_UPLOAD";
    // }

    if(!trainer?.isVerified) {
      return "CERTIFICATE_APPROVAL";
    }

    if(trainer?.status != "ACTIVE") {
      return "ACTIVATION";
    }

    return null;
  }

  async function onActivationClickHandler() {
    if(activationStatus == "PUBLIC_INFO") {
      window.location.href = "/trainer/edit/info";
    }

    if(activationStatus == "PRIVATE_INFO") {
      window.location.href = "/trainer/edit/bank";
    }

    if(activationStatus == "CERTIFICATE_UPLOAD") {
      window.location.href = "/trainer/edit/certificate";
    }

    if(activationStatus == "CERTIFICATE_APPROVAL") {
      alert("Certifikát ještě nebyl schválen");
    }

    if(activationStatus == "ACTIVATION") {
      const responseToken = await getRefresh();

      setUser({
        isLoggedIn: true,
        token: responseToken?.body?.token,
        name: "Username",
        language: "cs",
      });
      
      const response = await postActivateAccount();

      if(response.success) {
        window.location.reload();
      }
    }
  }

  function onEditClick() {
    window.location.href = "/trainer/edit/info";
  }

  function onToProfileClick() {
    window.location.href = `/${formatName(trainer.name)}`;
  }

  function onSubscriptionClick(id) {
    window.location.href = `/subscription/${id}`;
  }

  function onPackageClick(id) {
    window.location.href = `/subscription/${id}`;
  }
  
  const headerValues = [
    {title: "Klienti", src: SubscriptionIcon, selected: layout == "SUBSCRIPTION", onClick: () => setLayout("SUBSCRIPTION")},
    {title: "Objednávky", src: BundleIcon, selected: layout == "PACKAGE", onClick: () => setLayout("PACKAGE")},
  ];

  const packagesFiltered = subscriptions.filter((subscription) => subscription.type == "ONE_TIME_PURCHASE" || subscription.type == "FREE");

  const subscriptionsFiltered = subscriptions.filter((subscription) => subscription.type == "MONTHLY_SUBSCRIPTION");



  return (
    <DashboardPage
      layout={layout}
      balance={balance.amount}
      trainer={trainer}
      headerValues={headerValues}
      activationStatus={activationStatus}
      subscriptions={subscriptionsFiltered}
      packages={packagesFiltered}
      onTrainerClick={onTrainerClickHandler}
      onActivationClick={onActivationClickHandler}
      onEditClick={onEditClick}
      onToProfileClick={onToProfileClick}
      onSubscriptionClick={onSubscriptionClick}
      onPackageClick={onPackageClick}
    />
  );
}
