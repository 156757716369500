import ContentBoxGrid from "../Boxes/ContentBoxGrid";


export default function ContentInput({values, text, maxLength, onAddClick, onRemoveClick}) {
  
  const finalValues = maxLength == values?.length ? 
    (values || [])?.map((val => { return {...val, type: "content" , onClick: () => onRemoveClick(val.text)}}))
    :[...(values || [])?.map((val => { return {...val, type: "content" , onClick: () => onRemoveClick(val.text)}})), {type: "empty", onClick: onAddClick}];

  return (
    <ContentBoxGrid
      values={finalValues}
      theme={"outlined_light"}
    />
  )
}