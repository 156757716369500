import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom"
import FilterPage from "../pages/FilterPage/FilterPage";
import UseDimensions from "../hooks/UseDimensions";
import { getFilter } from "../services/FilterService";
import StepPage from "../pages/stepPage/StepPage";
import { breakAfterWord } from "../utils/FormatUtils";
import WeightIcon from "../assets/icons/Weight-Icon.svg";
import imageCompression from 'browser-image-compression';

export default function StepScreen({values, onFinishCallback, isLoading}) {
  const {height} = UseDimensions();
  const [filterValues, setFilterValues] = useState(values);
  const [index, setIndex] = useState(0);
  const [value, setValue] = useState(filterValues[index]?.value);

  const currentValue = filterValues?.find((value) => value?.index == index);

  function progressFilter() {
      if(value == null) {
        return 
      }

      // scroll to top
      window.scrollTo(0, 0);

      // update filter values
      setFilterValues(filterValues => filterValues.map((val, ind) => {
        if(ind != index) {
          return val
        }
        return {
          ...val,
          value: value,
        }
      }));

      const newIndex = __findNextIndex(index, filterValues.map((val, ind) => {
        if(ind != index) {
          return val
        }
        return {
          ...val,
          value: value,
        }
      }));

      // first and last step
      if(index == 0 && newIndex >= filterValues.length) {
        onFinishCallback(filterValues.map((val, ind) => {
          if(ind != index) {
            return val
          }
          return {
            ...val,
            value: value,
          }
        }));
        return;
      }

      // first step
      if(index == 0) {
        setValue(filterValues[newIndex]?.value);
        setIndex(newIndex);
        return;
      }

      // last step
      if(newIndex >= filterValues.length) {
        onFinishCallback(filterValues.map((val, ind) => {
          if(ind != index) {
            return val
          }
          return {
            ...val,
            value: value,
          }
        }));
        return;
      }

      // other steps
      setIndex(newIndex);
      setValue(filterValues[newIndex]?.value);
  };


  function backFilter() {
    // first step
    if(index == 0) {
      return;
    }

    // scroll to top
    window.scrollTo(0, 0);

    const prewIndex = __findPreviousIndex(index, filterValues.map((val, ind) => {
      if(ind != index) {
        return val
      }
      return {
        ...val,
        value: value,
      }
    }));

    setFilterValues(filterValues => filterValues.map((val, ind) => {
      if(ind != index) {
        return val
      }
      return {
        ...val,
        value: value,
      }
    }));
    setValue(filterValues[prewIndex]?.value);
    setIndex(prewIndex);
  }

  function onSelectClick(index) {
    setValue(index);
  }

  function onValueChange(value) {
    if(currentValue?.type == "number" && (!/^\d+$/.test(value.target.value) && value.target.value != "")) {
      setValue(value => value)
      return;
    }

    setValue(value.target.value);
  }

  function onUploadClick() {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = "image/jpeg, image/png";
  
    // Append the input to the document body
    document.body.appendChild(fileInput);
    fileInput.style.display = 'none'; // Hide the input
    fileInput.click();
  
    fileInput.onchange = async (event) => {
      const file = event.target.files[0];
      if (!file) {
        // Optionally, remove the input if no file is selected
        document.body.removeChild(fileInput);
        return;
      }
  
      // Set options for compression
      const options = {
        maxSizeMB: 0.5, // Maximum file size in MB
        maxWidthOrHeight: 1920, // Compressed file's maximum dimension
        useWebWorker: true // Enables multi-threading if available
      };
  
      try {
        const compressedFile = await imageCompression(file, options);
        const reader = new FileReader();
        reader.onload = (event) => {
          const base64 = event.target.result;

          setValue({ src: base64, title: compressedFile.name });
          
          // Optionally, remove the input after use
          document.body.removeChild(fileInput);
        };
        reader.readAsDataURL(compressedFile);
      } catch (error) {
        console.error('Error during image compression:', error);
        // Optionally, remove the input in case of an error
        document.body.removeChild(fileInput);
      }
    };
  
    // Update the filter values, setting exists to false for the current index
    setFilterValues(filterValues => filterValues.map((val, ind) => {
      if (ind !== index) {
        return val;
      }
      return {
        ...val,
        exists: false,
      };
    }));
  }
  

  function onAddClick() {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = "*";

    document.body.appendChild(fileInput);
    fileInput.style.display = 'none'; 
    fileInput.click();

    fileInput.onchange = (event) => {
      const file = event.target.files[0];

      if (!file) {
        document.body.removeChild(fileInput);
        return;
      }

      const reader = new FileReader();
      reader.onload = (event) => {
        const base64 = event.target.result;
        const name = breakAfterWord(file.name, 20);

        const newValue = {text: name, title: file.name, src: base64,};
        setValue((value) => value != null ? [...value, newValue] : [newValue]);

        document.body.removeChild(fileInput);
      }
      reader.readAsDataURL(file);
    }
    // set exists to false
    setFilterValues(filterValues => filterValues.map((val, ind) => {
      if(ind != index) {
        return val
      }
      return {
        ...val,
        exists: false,
      }
    }));
  }

  function onRemoveClick(text) {

    setValue(value => value.filter((val, ind) => val.text != text));
  }


  function onPickerSelectClick(valueIndex) {
    const selectedCount = filterValues[index]?.values?.filter(value => value.selected)?.length;
    const isAdding = !filterValues[index]?.values[valueIndex]?.selected;

    if(selectedCount == filterValues[index]?.maxLength && isAdding) {
      return;
    }

    setValue(isAdding ? true : selectedCount == 1 ? null : true); 
    setFilterValues(filterValues => filterValues.map((val, ind) => {
      if(ind != index) {
        return val
      }
      
      val.values = val?.values?.map((val, i) => {
        if(i != valueIndex) {
          return val
        }

        return {...val, selected: !val.selected};
      })

      return val;
    }));
    
  }

  function onPickerNewClick(text) {
    setFilterValues(filterValues => filterValues.map((val, ind) => {
      if(ind != index) {
        return val
      }
      
      val.values.push({text: text, src: WeightIcon, selected: true});
      setValue(true); 

      return val;
    }));
  }

  
  function __findNextIndex(index, filterValues) {
    if (filterValues[index + 1]?.if == null || filterValues[index + 1]?.if(filterValues)) {
      return index + 1;
    }
  
    if(index + 1 >= filterValues.length) {
      return index + 1;
    }
  
    return __findNextIndex(index + 1, filterValues);
  }
  
  function __findPreviousIndex(index, filterValues) {
    if (filterValues[index - 1]?.if == null || filterValues[index - 1]?.if(filterValues)) {
      return index - 1;
    }
  
    if(index - 1 < 0) {
      return index - 1;
    }
  
    return __findPreviousIndex(index - 1, filterValues);
  }

  return (
    <StepPage
      height={height}
      currentValue={currentValue}
      backButtonDisabled={index == 0}
      submitButtonDisabled={(value == null || value?.length == 0 || (currentValue?.validator ? !currentValue?.validator(value) : false)) && currentValue?.minLength != 0} 
      value={value}
      submitButtonText={index + 1 == filterValues.length ? "Dokončit" : "Pokračovat"}
      isLoading={isLoading}
      onSelectClick={onSelectClick}
      onBackClick={backFilter}
      onSubmitClick={progressFilter}
      onUploadClick={onUploadClick}
      onValueChange={onValueChange}
      onAddClick={onAddClick}
      onRemoveClick={onRemoveClick}
      onPickerSelectClick={onPickerSelectClick}
      onPickerNewClick={onPickerNewClick}
    />
  )
}