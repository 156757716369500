import React from "react";
import './TrainerProfilePage.css';
import { Trans } from "react-i18next";
import i18n from '../../providers/I18nProvider'
import IconBoxGrid from "../../components/Boxes/IconBoxGrid";
import ButtonPrimary from "../../components/Buttons/Primary/ButtonPrimary";
import SocialBoxGrid from "../../components/Boxes/SocialBoxGrid";
import ProductBoxScroll from "../../components/Boxes/ProductBoxScroll";
import ProcessComponent from "../../components/ProcessComponent";
import {BottomNavbar, BottomNavbarDouble} from "../../components/Navbar/BottomNavbar";
import VerificationIcon from "../../assets/icons/Verification-Icon.svg";
import Icon from "../../components/Icon";
import {Switch, DinamicSwitch} from "../../components/Navbar/SwitchHeader";
import CrossRedIcon from "../../assets/icons/Cross-Red-Icon.svg";
import MoneyIcon from "../../assets/icons/Money-Icon.svg";
import ProgressIcon from "../../assets/icons/Progress-Icon.svg";
import { DescriptionHalfRow } from "../../components/Description";
import { MenuComponent } from "../../components/MenuComponent";
import { formatDate, formatPrice } from "../../utils/FormatUtils";
import FiRrCoins from "../../assets/icons/rrfi/fi-rr-coins.svg";
import FiRrReceipt from "../../assets/icons/rrfi/fi-rr-receipt.svg";
import FiRrCalendar from "../../assets/icons/rrfi/fi-rr-calendar.svg";
import FiRrCalendarPen from "../../assets/icons/rrfi/fi-rr-calendar-pen.svg";
import FiRrCalendarClock from "../../assets/icons/rrfi/fi-rr-calendar-clock.svg";
import SocialBoxList from "../../components/Boxes/SocialBoxList";

function TrainerProfilePage(props) {
    const nameParts = props.name?.split(' ') || [];

    return (
       <div className='trainer_profile_page'>
            <div className='trainer_profile_page__head'>
                <div className='trainer_profile_page__head_header'>
                    <Icon 
                        className='trainer_profile_page__head_header_image'
                        alt={`trainer ${props.name}`} 
                        src={props.trainerImage}
                    />
                    <div className='trainer_profile_page__head_header_shadow'/>
                    <div className='trainer_profile_page__head_header_shadow_desktop'/>
                    <h1 className='trainer_profile_page__head_header_h1'>
                        {props.isActive && nameParts.map((part, index) => (
                            index + 1 != nameParts.length ? <span key={index}>{part}</span> : <span key={index}>{part}<Icon alt="verified" src={VerificationIcon} className="trainer_profile_page__head_header_icon" /></span>
                        ))}
                        {!props.isActive && nameParts.map((part, index) => (
                            index + 1 != nameParts.length ? <span key={index}>{part}</span> : <span key={index}>{part}<Icon alt="inverified" src={CrossRedIcon} className="trainer_profile_page__head_header_icon" /></span>
                        ))}
                    </h1>
                </div>
                <div className="trainer_profile_page__head_box">
                    <h4 className='trainer_profile_page__head_box_h4'>{props.description}</h4>
                    
                    {!props.showMore && 
                        <div className="trainer_profile_page__head_box_show_less">
                            <IconBoxGrid maxRows={1} values={props.quickLook} theme={"light"}/>
                        </div>
                    }

                    {props.showMore &&
                        <div className="trainer_profile_page__head_box_show_more">
                            <h3 className="trainer_profile_page__head_box_show_more_h2">Zaměření</h3>
                            <IconBoxGrid values={props.focus} theme={"light"}/>
                            <h3 className="trainer_profile_page__head_box_show_more_h2">Úspěchy</h3>
                            <IconBoxGrid values={props.achievement} theme={"light"}/>
                            <h3 className="trainer_profile_page__head_box_show_more_h2">Kvalifikace</h3>
                            <IconBoxGrid values={props.qualification} theme={"light"}/>
                            <h3 className="trainer_profile_page__head_box_show_more_h2">O trenérovi</h3>
                            <p className="trainer_profile_page__head_box_show_more_h4">{props.about}</p>
                            <h3 className="trainer_profile_page__head_box_show_more_h2">Kontakt</h3>
                            <SocialBoxList values={props.socials} onClick={props.onSocialClick}/>
                        </div>       
                    }

                    <div className="trainer_profile_page__head_box_show_more_desktop">
                        <div className="trainer_profile_page__head_box_show_more_desktop_box">
                            <div className="trainer_profile_page__head_box_show_more_desktop_box_box">
                                <h3 className="trainer_profile_page__head_box_show_more_h2">Zaměření</h3>
                                <IconBoxGrid values={props.focus} theme={"light"} type={"desktop"}/>
                            </div>
                            <div className="trainer_profile_page__head_box_show_more_desktop_box_box">
                                <h3 className="trainer_profile_page__head_box_show_more_h2">Úspěchy</h3>
                                <IconBoxGrid values={props.achievement} theme={"light"} type={"desktop"}/>
                            </div>
                            <div className="trainer_profile_page__head_box_show_more_desktop_box_box">
                                <h3 className="trainer_profile_page__head_box_show_more_h2">Kvalifikace</h3>
                                <IconBoxGrid values={props.qualification} theme={"light"} type={"desktop"}/>
                            </div>
                        </div>
                        <div className="trainer_profile_page__head_box_show_more_desktop_box">
                            <div className="trainer_profile_page__head_box_show_more_desktop_box_box">  
                                <h3 className="trainer_profile_page__head_box_show_more_h2">O trenérovi</h3>
                                <p className="trainer_profile_page__head_box_show_more_h4">{props.about}</p>
                            </div>
                            <div className="trainer_profile_page__head_box_show_more_desktop_box_box">
                                <h3 className="trainer_profile_page__head_box_show_more_h2">Kontakt</h3>
                                <SocialBoxList values={props.socials} onClick={props.onSocialClick}/>
                            </div>
                            <div className="trainer_profile_page__head_box_show_more_desktop_box_box">
                            
                            </div>
                        </div>
                    </div>       

                    <ButtonPrimary title={props.showMore ? "Skrýt" : "Zobrazit vše"} onClick={props.onShowMoreClick} type={"outlined"} className='trainer_profile_page__head_box_button'/>
                </div>
            </div>
            <div className="trainer_profile_page__body">
                <DinamicSwitch style={{marginTop: -8}} values={props.headerValues}/>

                {props.layout == "TRAINING" && 
                    <>
                        <p className='trainer_profile_page__body_h4'>Osobní tréninky, s certifikovaným trenérem, který ti pomůže dostat ze sebe maximum. ať už chceš cvičit ve fitness centrech, doma či venku.</p>
                        {/* <DescriptionRow values={packageValues} style={{padding: 24, marginTop: 24, marginBottom: 24, borderRadius: 8, border: "1px solid black" }}/> */}
                        <ProductBoxScroll values={props.articlesTraining} onClick={props.onArticleClick} type={"ONE_TIME_PURCHASE"}/>
                    </>
                }

                {props.layout == "SUBSCRIPTION" && 
                    <>
                        <p className='trainer_profile_page__body_h4'>Aktivní vedení od certifikovaného trenéra, který ti pomůže dostat ze sebe maximum. Od na míru šitých tréninků až po tvůj osobní jídelníček.</p>
                        {/* <DescriptionHalfRow values={coachingValues} style={{_padding: 24, marginTop: 24, marginBottom: 24, borderRadius: 8, _border: "1px solid black" }}/> */}
                        <ProductBoxScroll values={props.articlesOnlineSubscription} onClick={props.onArticleClick} type={"MONTHLY_SUBSCRIPTION"}/>
                    </>
                }

                {props.layout == "PACKAGE" && 
                    <>
                        <p className='trainer_profile_page__body_h4'>Kompletní návod pro samostatné cvičení, ať už cvičíš doma nebo v posilovně. Dosáhni svých cílů díky instrukcím od certifikovaných trenérů.</p>
                        {/* <DescriptionRow values={packageValues} style={{padding: 24, marginTop: 24, marginBottom: 24, borderRadius: 8, border: "1px solid black" }}/> */}
                        <ProductBoxScroll values={props.articlesOneTime} onClick={props.onArticleClick} type={"ONE_TIME_PURCHASE"}/>
                    </>
                }
                
            </div>
  
            {props.isOwner && <BottomNavbarDouble leftButtonText={"Upravit profil"} rightButtonText={"Na dashboard"} leftButtonOnClick={props.onEditClick} rightButtonOnClick={props.onToDashboardClick}/>}
            {(!props.isOwner && props.isActive) && <BottomNavbar title={"Domluv si konzultaci"} description={"První zdarma"} buttonText={"Kontaktovat"} onClick={props.onContactClick}/>}
            {(!props.isOwner && !props.isActive) && <BottomNavbar title={"Neaktivní trenér"} description={"Účet není aktivní"} buttonText={"Nedostupný"} onClick={() => {}} disabled/>}
       </div>
    );
}
export default TrainerProfilePage;
