import {get, post, put} from "./ApiService";


export async function getAdminAllTrainers() {
  const response = await get(`/admin/trainer/all`);
  if(response.status == 201 || response.status == 200 )  {
    const {body} = await response.json();
  
    if(body.length === 0) {
      return {success: false, message: "Načtení trenérů se nezdařilo, žádný trenéri neexistují"};
    }
    
    return {success: true, body, message: "Načtení trenérů proběhlo úspěšně"};
  }

  return {success: false, message: "Načtení trenérů se nezdařilo"};
}

export async function postAdminActivateTrainer(cstId) {
  const response = await post(`/admin/trainer/activate/${cstId}`);
  if(response.status == 201 || response.status == 200 )  {
    const {body} = await response.json();

    
    return {success: true, body, message: "Trenér byl aktivován"};
  }

  return {success: false, message: "Aktivace trenéra se nezdařila"};
}

export async function postAdminLogin(cstId) {
  const response = await post(`/admin/login/${cstId}`);
  if(response.status == 201 || response.status == 200 )  {
    const {body} = await response.json();
  
    if(body.length === 0) {
      return {success: false, message: "Přihlášení se nezdařilo"};
    }
    
    return {success: true, body, message: "Uživatel byl přihlášen"};
  }

  return {success: false, message: "Přihlášení se nezdařilo"};
}