import {get, post, put, remove} from "./ApiService";

export async function registerClient() {
  const response = await post('/client/register', {});
  if(response.status == 201 || response.status == 200 )  {
    const {body} = await response.json();
  
    return {success: true, body, message: "Registrace proběhla úspěšně"};
  }

  return {success: false, message: "Registrace neproběhla úspěšně"};
}

export async function registerGoogleClient(body) {
  const putResponse = await put('/user/me', body, {
    headers: {
      "Content-Type": "application/json",
      "Authorization": 'Bearer ' + localStorage.getItem('tempToken')
    }
  });
  
  if(putResponse.status != 201 && putResponse.status != 200 )  {
    if(putResponse.status == 400) {
      return {success: false, body, message: "Osobní údaje nejsou vyplněny správně"};
    }
    
    return {success: false, message: "Registrace neproběhla úspěšně"};
  }


  const response = await post('/client/register', {}, {
    headers: {
      "Authorization": 'Bearer ' + localStorage.getItem('tempToken')
    }
  });
  
  if(response.status == 201 || response.status == 200 )  {
    const {body} = await response.json();
  
    return {success: true, body, message: "Registrace proběhla úspěšně"};
  }

  return {success: false, message: "Registrace neproběhla úspěšně"};
}

export async function getPaymentMethods() {
  const response = await get('/client/card');
  if(response.status == 200) {
    const {body} = await response.json();
    return {success: true, body, message: "Získání platebních metod proběhlo úspěšně"};
  }

  return {success: false, message: "Získání platebních metod neproběhlo úspěšně"};
}

export async function deletePaymentMethod(id) {
  const response = await remove('/client/card/' + id);
  if(response.status == 200) {
    const {body} = await response.json();
    return {success: true, body, message: "Odebrání platební metody proběhlo úspěšně"};
  }

  return {success: false, message: "Odebrání platební metody neproběhlo úspěšně"};
}

export async function addPaymentMethod(data) {
  const response = await post('/client/card', data);
  if(response.status == 200) {
    const {body} = await response.json();
    return {success: true, body, message: "Přidání platební metody proběhlo úspěšně"};
  }

  return {success: false, message: "Přidání platební metody neproběhlo úspěšně"};
}