import "./PaymentCardComponent.css";
import TrashCanIcon from "../assets/icons/Trash-Can-Icon.svg";
import CardVisaIcon from "../assets/icons/Card-Visa-Icon.svg";
import CardMastercardIcon from "../assets/icons/Card-Mastercard-Icon.svg";
import CardMaestroIcon from "../assets/icons/Card-Maestro-Icon.svg";
import CardDiscoverIcon from "../assets/icons/Card-Discover-Icon.svg";
import CardDinersclubIcon from "../assets/icons/Card-Dinersclub-Icon.svg";
import Icon from "./Icon";

export default function PaymentCardComponent(props) {
  const {brand, last4, validToMonth, validToYear, onDeleteClick, theme, ...restProps} = props;
  
  const brandsIcons = {
    visa: CardVisaIcon,
    mastercard: CardMastercardIcon,
    maestro: CardMaestroIcon,
    discover: CardDiscoverIcon,
    dinersclub: CardDinersclubIcon
  }

  if(theme == "light") {
    return (
      <div className="payment_card_light" {...restProps}>
        <Icon src={brandsIcons[brand] || brandsIcons["visa"]} alt="card icon" className="payment_card_brand_icon"/>

        <div className="payment_card__box">
          <h5 className="payment_card_box__box_h3_light">**** **** **** {last4}</h5>
          <p className="payment_card_box__box_h4_light">EXP {validToMonth}/{validToYear}</p>
        </div>

        <div className="payment_card__delete_icon_container_light" onClick={() => onDeleteClick(props.id)}>
          <Icon src={TrashCanIcon} alt="delete icon" className="payment_card_delete_icon"/>
        </div>
  
      </div>
    )
  }

  return (
    <div className="payment_card" {...restProps}>
      <Icon src={brandsIcons[brand] || brandsIcons["visa"]} alt="card icon" className="payment_card_brand_icon"/>

      <div className="payment_card__box">
        <h5 className="payment_card_box__box_h3">**** **** **** {last4}</h5>
        <p className="payment_card_box__box_h4">EXP {validToMonth}/{validToYear}</p>
      </div>

      <div className="payment_card__delete_icon_container" onClick={() => onDeleteClick(props.id)}>
        <Icon src={TrashCanIcon} alt="delete icon" className="payment_card_delete_icon"/>
      </div>

    </div>
  )
}