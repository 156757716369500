import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom"
import FilterPage from "../pages/FilterPage/FilterPage";
import UseDimensions from "../hooks/UseDimensions";
import { getFilter } from "../services/FilterService";

export default function FilterScreen() {
  const {type, ids} = useParams();
  const {height} = UseDimensions();
  const [filterValues, setFilterValues] = useState([]);
  const [value, setValue] = useState(null);
  const navigate = useNavigate();

  const idsArray = ids?.split(",") || [];

  useEffect(() => {
    getFilter(type).then((response) => {
      setFilterValues(response.data);
    })
  }, []);

  function progressFilter() {
      if(value == null) {
        return 
      }

      const currentLink = window.location.pathname;  
      let newLink = currentLink;

      // first step 
      if(idsArray.length == 0 && !newLink.endsWith("/")) {
        newLink += "/";
        newLink += value;
        navigate(newLink, { replace: true });
        setValue(null);
        return;
      }

      // first step
      if(idsArray.length == 0) {
        newLink += value;
        navigate(newLink, { replace: true });
        setValue(null);
        return;
      }

      // last step
      if(idsArray.length + 1 == filterValues.length) {
        newLink += ",";
        newLink += value;
        window.location.href = newLink + "/result";
        return;
      }

      // other steps
      newLink += ",";
      newLink += value;
      navigate(newLink, { replace: true });
      setValue(null);
  };


  function backFilter() {
    const currentLink = window.location.pathname;  
    const lastValue = idsArray?.length > 0 ? idsArray[idsArray.length - 1]: null;
    let newLink = currentLink;

    if(idsArray.length == 1) {
      newLink = newLink.substring(0, newLink.lastIndexOf("/"));
      navigate(newLink, { replace: true });
      setValue(lastValue)
      return;
    }

    newLink = newLink.substring(0, newLink.lastIndexOf(","));
    navigate(newLink, { replace: true });
    setValue(lastValue)
  }

  function onSelectClick(index) {
    setValue(index);
  }


  const currentValue = filterValues?.find((value) => value?.index == idsArray?.length);

  return (
    <FilterPage
      height={height}
      currentValue={currentValue}
      backButtonDisabled={idsArray.length == 0}
      submitButtonDisabled={value == null}
      selected={value}
      onSelectClick={onSelectClick}
      onBackClick={backFilter}
      onSubmitClick={progressFilter}
    />
  )
}