import React, { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom"
import FilterPage from "../pages/FilterPage/FilterPage";
import UseDimensions from "../hooks/UseDimensions";
import { getFilter } from "../services/FilterService";
import StepScreen from "./StepScreen";
import MaleIcon from "../assets/icons/Male-Icon.svg";
import FemaleIcon from "../assets/icons/Female-Icon.svg";
import OtherIcon from "../assets/icons/Other-Icon.svg";
import ForbidenIcon from "../assets/icons/Forbiden-Icon.svg";
import StudentIcon from "../assets/icons/Student-Icon.svg";
import AdultIcon from "../assets/icons/Adult-Icon.svg";
import OlderAdultIcon from "../assets/icons/Older-Adult-Icon.svg";
import SeniorIcon from "../assets/icons/Senior-Icon.svg";
import Weight1Icon from "../assets/icons/Weight-1-Icon.svg";
import Weight2Icon from "../assets/icons/Weight-2-Icon.svg";
import Weight3Icon from "../assets/icons/Weight-3-Icon.svg";
import Weight4Icon from "../assets/icons/Weight-4-Icon.svg";
import WeightIcon from "../assets/icons/Weight-Icon.svg";
import SubscriptionIcon from "../assets/icons/Subscription-Icon.svg";
import BundleIcon from "../assets/icons/Bundle-Icon.svg";
import { postArticle, getArticleById, putArticle } from "../services/ArticleService";
import { getAllTrainerCategories } from "../services/CategoryService";
import { formatName } from "../utils/FormatUtils";
import { GlobalContext } from "../providers/GlobalContext";
import { putTrainerInfo } from "../services/TrainerService";
import { goBack } from "../utils/RoutingUtils";
import FiRrChartLineUp from "../assets/icons/rrfi/fi-rr-chart-line-up.svg";
import FiRrStudent from "../assets/icons/rrfi/fi-rr-student.svg";
import FiRrPersonWalkingWithCane from "../assets/icons/rrfi/fi-rr-person-walking-with-cane.svg";

export default function TrainerConsultationScreen() {
  const {user, setUser} = useContext(GlobalContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const trainer = user?.trainer?.length > 0 ? user?.trainer[0] : {};
  const navigate = useNavigate();
  
  const values = [
    {
      index: 0,
      type: "selection",
      title: "Úvodní konzultace",
      description: "Pomůžeme ti s nastavením profilu a zvýšit šance na více prodejů",
      value: null,
      values: [
        {
          index: 0,
          title: "Chci to rozjet naplno",
          description: "Společně kompletně nastavíme tvůj profil pro maximalizaci prodejů. Získáš špičkový copywriting, skvělé fotky produktů a jasnou strategii.",
          subDesciption: "890,00 Kč",
          src: FiRrChartLineUp
        },
        {
          index: 1,
          title: "Chci probrat základy",
          description: "Na společné konzultaci probereme co dělat a co nedělat, abys věděl jak platforma funguje a získal základní tipy na zvýšení prodejů.",
          subDesciption: "Zdarma",
          src: FiRrStudent
        },
        {
          index: 2,
          title: "Obejdu se bez konzultace",
          description: "Vše si nastavím sám, nepotřebuji s ničím pomoct.",
          src: FiRrPersonWalkingWithCane
        },
      ]
    }
  ];

  function onNextCallback(values) {
    console.log("Next");
    console.log(values);
  }

  async function onFinishCallback(values) {
    console.log("Finish");
    console.log(values);

    if(isSubmitting) {
      return;
    }

    setIsSubmitting(true);

    const body = {
        consultation: values[0]?.value == 0 ? "PREMIUM_CONSULTATION" : values[0]?.value == 1 ? "FREE_CONSULTATION" : "NO_CONSULTATION",
    }

    const response = await putTrainerInfo(body);
    
    if(response.success) {
      console.log("Success");

      navigate("/trainer/dashboard");
    }

    if(!response.success) {
      console.log("Error");
      console.log(response.message);
      alert(response.message);
    }


    setIsSubmitting(false);
  }

  console.log("Trainer: ", trainer.length);

  return (
    <StepScreen
      values={values}
      isLoading={isSubmitting}
      onNextCallback={onNextCallback}
      onFinishCallback={onFinishCallback}
    />
  )
}