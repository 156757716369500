import "./TextInputModal.css";
import React, { useState, useEffect, useContext } from 'react';
import ButtonPrimary from '../../components/Buttons/Primary/ButtonPrimary';
import TextInput from "../../components/TextInput/TextInput";
import EmailIcon from "../../assets/icons/Email-Grey-Icon.svg";
import GoogleIcon from "../../assets/icons/Google-Icon.svg";
import { GlobalContext } from "../../providers/GlobalContext";
import { login, loginGoogle } from "../../services/UserService";
import { useGoogleLogin } from '@react-oauth/google';
import Loader from "../../components/Loader";
import SuccessIcon from "../../assets/icons/Success-Icon.svg";
import { getIsEmailRegistered } from "../../services/UserService";
import BaseModal from "./BaseModal";
import Icon from "../Icon";

export default function TextInputModal({style, onFinishCallback, isVisible, setIsVisible}) {

    const View = () => {
        const [value, setValue] = useState("");

        const onFinishClick = (value) => {
            onFinishCallback(value);
            setIsVisible(false);
        }

        const onValueChange = (event) => {
            setValue(event.target.value);
        }


        return (
          <div className='text_input_modal__box'>
            <div className='text_input_modal__box_texts'>
              <h3 className='text_input_modal__title'>Vlastní bod</h3>
              <h5 className='text_input_modal__subtitle'>Zadejte název vlastního bodu</h5>
            </div>
            <TextInput value={value} onChange={onValueChange} icon={EmailIcon} placeholder={"Název bodu"} type={"text"} theme={"outlined_light"}  validator={(value) => value.length > 2 && value.length < 29} errorText="Název musí mít 3 až 28 znaků"/>
            <ButtonPrimary title={"Přidat"} disabled={!(value.length > 2 && value.length < 29)} onClick={() => {onFinishClick(value)}} />
          </div>
        )
    }

    return (
        <BaseModal style={style} isVisible={isVisible} setIsVisible={setIsVisible}>
            <View/>
        </BaseModal>
    )
}

