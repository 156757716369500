import "./DeliveryStatusComponent.css";
import IconBox from "./Boxes/IconBox";
import CreditCardIcon from "../assets/icons/Credit-Card-Icon.svg";
import ChatIcon from "../assets/icons/Chat-Icon.svg";
import TimeIcon from "../assets/icons/Time-Icon.svg";
import GymIcon from "../assets/icons/Gym-Icon.svg";
import ArrowRightIcon from "../assets/icons/Arrow-Right-Icon.svg";
import LineIcon from "../assets/icons/Line-Icon.svg";

export default function DeliveryStatusComponent(props) {
  const {deliveryStatus, theme} = props;

  const deliveryCanceledValues = [
    { 
      src: LineIcon, 
      type: "transparent"
    },
    { 
      src: LineIcon, 
      type: "transparent"
    },
    {
      src: CreditCardIcon,
      status: "EXPIRED",
      type: "round",
      title: "Spolupráce ukončena",
      description: "Spolupráce s trenérem byla ukončena."
    },
    { 
      src: LineIcon, 
      type: "transparent"
    },
    { 
      src: LineIcon, 
      type: "transparent"
    },
  ];
  

  const deliveryActiveValues = [
    { 
      src: CreditCardIcon, 
      status: "PENDING", 
      type:"round",
      title: "Zpracovává se",
      description: "Trenér přijal objednávku."
    },
    { 
      src: LineIcon, 
      type: "transparent" 
    },
    { 
      src: ChatIcon, 
      status: "CONSULTATION", 
      type:"round",
      title: "Konzultace",
      description: "S trenérem je naplánována konzultace."
     },
    { 
      src: LineIcon, 
      type: "transparent" 
    },
    { 
      src: TimeIcon, 
      status: "IMPLEMENTING", 
      type:"round",
      title: "V procesu tvorby",
      description: "Trenér pro tebe vytváří balíček."
    },
    { 
      src: LineIcon, 
      type: "transparent"
    },
    { 
      src: GymIcon, 
      status: "ACTIVE", 
      type:"round",
      title: "Balíček aktivní",
      description: "Balíček nyní můžeš využívat naplno."
    },
  ]

  const deliveryValues = deliveryStatus == "EXPIRED" ? deliveryCanceledValues : deliveryActiveValues;

  const h4ClassName = theme == "dark" ? "delivery_status_component__h4_dark" : "delivery_status_component__h4";  

  const h4BoldClassName = theme == "dark" ? "delivery_status_component__h4_bold_dark" : "delivery_status_component__h4_bold";
  return (
    <div className="delivery_status_component">
      <p className={h4BoldClassName}>
          {deliveryValues.find((value) => value.status == deliveryStatus).title}
      </p>
      <p className={h4ClassName}>
        {deliveryValues.find((value) => value.status == deliveryStatus).description}
      </p>
      <div className="delivery_status_component__box">
        {deliveryValues.map((value, index) => 
          <IconBox key={index} {...value} type={value.type} active={value.status == deliveryStatus} />
        )}
      </div>
    </div>
  )
   
  
}