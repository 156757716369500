import React from "react";
import "./CommonQuestionsPage.css";
import Question from "../../components/Question";

export default function CommonQuestionsPage(props) {

  return (
    <div className="common_questions_page">
        <h3 className='common_questions_page_h3'>
          Často kladené dotazy
        </h3>
        <Question title="Jaký je rozdíl mezi balíčkem a online coachingem?" description="Balíček nemusí být na míru a platí se jednorázově, online coaching je většinou na míru a platí se měsíčně, protože jde o aktivní mentoring od trenéra. U každého produktu najdeš sekci Co se stane po objednání?, kde se dozvíš vše potřebné."/>
        <Question title="Jak se se mnou po objednání trenér spojí?" description="Každý trenér si způsob komunikace určuje sám, ale tvojí objednávkou získá tvé telefonní číslo a typicky ti napíše či zavolá."/>
        <Question title="Co když chci objednávku na míru zrušit?" description="Pokud trenér nedodal to, co jsi si objednal, máš právo na vrácení celé částky."/>
        <Question title="Kdy objednané materiály dostanu?" description="U balíčků, které nejsou na míru obdržíš obsah hned. U balíčků na míru či obsahu k online coachingu ti trenér dodá vše co sis objednal do 14 dní."/>
        <Question title="Co když mi trenér nedodal to, co jsem si objednal? " description="Pokud do 14 dní neobržíš svou objednávku, máš právo na vrácení celé částky."/>
        <Question title="Chci začít cvičit, který produkt či trenér je pro mě nejvhodnější?" description="Pokud jsi začátečník, bude pro tebe vhodný online coaching. Pokud již máš zkušenosti se cvičením, můžeš se vydat cestou balíčku, rozdíly mezi nimi najdeš výše a pro nalezení ideálního řešení pro tebe vyzkoušej Filtraci."/>

        <h3 className='common_questions_page_h3' style={{paddingTop: 24}}>
          Časté dotazy pro trenéry
        </h3>
        <Question title="Jak získám více klientů?" description="Soustřeď se na kvalitní fotky produktů, popisky, dobře upravený profil a nezapomeň přidat své zaměření a úspěchy. Všechny tyto faktory hrají roli při výběru trenéra klientem a také u naší Filtrace, kterou klienti procházejí. Dále doporučujeme připnout odkaz na svůj NextCoach profil na sociální sítě - odkaz v biu na Instagramu, Facebooku apod. My se postaráme o zbytek propagací a zlepšováním platformy."/>
        <Question title="Potřebuji pro prodej trenérskou certifikaci?" description="Ano, držíme si jistou úroveň kvality služeb a proto po každém trenérovi bez výjimky požadujeme při založení profilu nahrání certifikace."/>
        <Question title="Jak dlouho trvá ověření certifikace?" description="Ověření certifikace proběhne do 48 hodin, ale mezitím už můžeš plně připravit svůj profil pro prodej. Ihned po ověření můžeš zveřejnit své produkty k zakoupení."/>
        <Question title="Kdy mi přijdou peníze z objednávky?" description="Z důvodu refund policy peníze obržíš po 14 dnech od data objednání."/>
        <Question title="Co když neodevzdám objednávku?" description="Na dodání máš 14 dní od data objednání. Pokud by došlo k situaci, že jako trenér nedodáš objednávku, klientovi se vrátí peníze a ty budeš penalizován blokací z platformy."/>
        <Question title="Platí se něco za profil na NextCoach?" description="Ne, založení profilu bylo, je a bude zdarma. Bereme si pouze 10% provize z každého prodaného produktu, abychom mohli zlepšovat platformu a zákaznickou zkušenost."/>
    </div>
  );
}
