import "./SocialBoxList.css";
import SocialBox from "./SocialBox";
import { SOCIAL_ICONS } from "../../constants/Icons";
import Icon from "../Icon";
import ArrowRightIcon from "../../assets/icons/Arrow-Right-Icon.svg";

export default function SocialBoxList(props) {
  
  if(!props.values || props.values.length < 1) {
    return <></>;
  }

  const Box = (props) => {
    return (
      <div className="social_box_list__box" onClick={() => props.onClick(props.text)}>
        <div className="social_box_list__box_left">
        <Icon 
          alt={props.text}
          className="social_box_list__box_icon" 
          src={props.src} 
          />
        <h5 className="social_box_list__box_h5">{props.value}</h5>
        </div>
        <Icon alt="arrow" src={ArrowRightIcon}/>

      </div>
    )
  }

  return (
    <div className="social_box_list">
      {props.values.map((value, index) => 
        <Box key={index} text={value.text} value={value.value} src={SOCIAL_ICONS[value.text]} onClick={props.onClick}/>
      )}
    </div>
  )
   
  
}