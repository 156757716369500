import React, { useContext, useRef, useState, useEffect } from "react";
import { useStripe } from "@stripe/react-stripe-js";
import { postBankAccount } from "../services/TrainerService";
import TrainerBankPage from "../pages/TrainerBankPage/TrainerBankPage";
import { GlobalContext } from "../providers/GlobalContext";
import { useNavigate } from "react-router-dom";
import { getTrainerBank } from "../services/TrainerService";
import { goBack } from "../utils/RoutingUtils";

export default function TrainerBankAccountScreen() {
  const stripe = useStripe();
  const { user } = useContext(GlobalContext);
  const [screenState, setScreenState] = useState("idle");
  const [error, setError] = useState(null);
  const [balance, setBalance] = useState({});
  const [exists, setExists] = useState(false);
  const [accountNumberPrefix, setAccountNumberPrefix] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [accountNumberBank, setAccountNumberBank] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    getBalance();
  }, []);

  async function getBalance() {
    const response = await getTrainerBank();

    if (!response.success) {
      console.error(response.message);
      return;
    }


    setBalance(response.body);

    if(response.body?.bankAccount?.length > 0) {
      setAccountNumber("******" + response.body.bankAccount);
      setAccountNumberBank("****");
      setExists(true);
    }

    console.debug("get balance result: ", response);
  }

  async function onFocus() {
    if(!exists) {
      return;
    }

    setAccountNumber("");
    setAccountNumberBank("");
    setExists(false);
  }


  const onButtonClick = async (event) => {
    event.preventDefault();

    if(exists) {
      goBack();
      return;
    }

    if (!stripe || screenState != "idle") {
      return;
    };

    setError(null);
    setScreenState("loading");

    if ((accountNumberPrefix + accountNumber)?.length < 5 || accountNumberBank?.length != 4 ) {
      console.error("wrong data");
      setError("Zadejte správné bankovní údaje");
      setScreenState("idle");
      return;
    }

    const stripeResponse = await stripe.createToken("bank_account", {
      country: "CZ",
      currency: "czk",
      account_number: formatToIBAN(accountNumber, accountNumberPrefix, accountNumberBank),
      account_holder_name: user.username,
      account_holder_type: "individual",
    });

    if(!stripeResponse.token) {
      console.error("error creating token");
      console.error(stripeResponse?.error?.message);
      setError(stripeResponse?.error?.message);
      setScreenState("idle");
      return;
    }
    
    console.log("Received Stripe token");
    const bankAccountResponse = await postBankAccount(stripeResponse.token.id);

    if(!bankAccountResponse.success) {
      console.error("error posting bank account");
      console.error(bankAccountResponse.message);
      setError(bankAccountResponse.message);
      setScreenState("idle");
      return;
    }

    console.log("Bank account posted");
    setScreenState("success");
    goBack();
  };

  function calculateCheckDigits(ibanBody, countryCode = 'CZ') {
    // Convert the IBAN body and country code to a string where letters are replaced by their numeric values
    const numericIban = ibanBody + countryCode + '00'; // Adding '00' for the check digits placeholder
    const replacedIban = numericIban.split('').map(char => {
      if (isNaN(char)) {
        // Convert letters to numbers (A=10, ..., Z=35)
        return char.toUpperCase().charCodeAt(0) - 55;
      } else {
        return char;
      }
    }).join('');
  
    // Perform modulo 97 calculation using a custom function to handle large numbers
    const remainder = modulo97(replacedIban);
    const checkDigits = (98 - remainder).toString().padStart(2, '0');
  
    return checkDigits;
  }
  
  // This function calculates modulo 97 for a large numeric string
  function modulo97(number) {
    let remainder = number.split('').reduce((acc, num) => {
      const updatedNum = (acc + num) % 97;
      return updatedNum;
    }, 0);
  
    return remainder;
  }
  
  function formatToIBAN(accountNumber, prefixNumber, bankNumber) {
    // remove all non-numeric characters
    const prefixNumberNumber = prefixNumber.replace(/\D/g, '');
    const accountNumberNumber = accountNumber.replace(/\D/g, '');
    const bankNumberNumber = bankNumber.replace(/\D/g, '');

    // Normalize account number: remove spaces and ensure it's up to 16 digits
    const normalizedAccountNumber = (prefixNumberNumber + accountNumberNumber).padStart(16, '0');
    const ibanBody = bankNumberNumber + normalizedAccountNumber;
    // Country code for Czech Republic
    const countryCode = 'CZ';
  
    // Calculate check digits
    const checkDigits = calculateCheckDigits(ibanBody, countryCode);
  
    // Combine components to form IBAN
    const IBAN = countryCode + checkDigits + ibanBody;
  
    return IBAN;
  }
  

  const handleAccountNumberPrefixChange = (e) => {
    setAccountNumberPrefix(e.target.value);
  };

  const handleAccountNumberChange = (e) => {
    setAccountNumber(e.target.value);
  }

  const handleAccountNumberBankChange = (e) => {
    setAccountNumberBank(e.target.value);
  }

  const isValid = accountNumberPrefix.length < 10 && accountNumber?.length > 3 && accountNumber?.length <= 20 && accountNumberBank?.length == 4;



  return (
    <TrainerBankPage
      exists={exists}
      screenState={screenState}
      error={error}
      submitDisabled={!isValid && !exists}
      accountNumberPrefix={accountNumberPrefix}
      accountNumber={accountNumber}
      accountNumberBank={accountNumberBank}
      handleAccountNumberPrefixChange={handleAccountNumberPrefixChange}
      handleAccountNumberChange={handleAccountNumberChange}
      handleAccountNumberBankChange={handleAccountNumberBankChange}
      onButtonClick={onButtonClick}
      onFocus={onFocus}
    />
  );
}
