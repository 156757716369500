import "./ProcessDetailComponent.css";
import IconBox from "./Boxes/IconBox";
import CreditCardIcon from "../assets/icons/Credit-Card-Icon.svg";
import ChatIcon from "../assets/icons/Chat-Icon.svg";
import GymIcon from "../assets/icons/Gym-Icon.svg";
import ArrowRightIcon from "../assets/icons/Arrow-Right-Icon.svg";
import VerticalLineIcon from "../assets/icons/Vertical-Line-Icon.svg";
import Icon from "./Icon";

export default function ProcessDetailComponent(props) {
  
  const procesPackageValues = [
    {
      number: 1,
      title: "Potvrzení objednávky", 
      description: "V tu chvíli ti na e-mail přijde potvrzení o platbě a balíček najdeš v sekci Moje balíčky",
     },
    {
      number: 2,
      title: "Trenér tě kontaktuje", 
      description: "Probere s tebou potřebné informace a tvou představu, obvykle formou online konzultace",
     },
    {
      number: 3,
      title: "Trenér vytvoří balíček", 
      description: "Na základě komunikace pro tebe trenér na míru sestaví obsah balíčku",
     },
     {
      number: 4,
      title: "Obdržíš Balíček", 
      description: "Balíček dorazí a můžeš začít trénovat. Pokud něco nevyhovuje můžeš zažádat o úpravu balíčku.",
     },
  ]

  const procesSubscriptionValues = [
    {
      number: 1,
      title: "Potvrzení objednávky", 
      description: "V tu chvíli ti na e-mail přijde potvrzení o platbě a coaching najdeš v sekci Moje balíčky",
     },
    {
      number: 2,
      title: "Trenér tě kontaktuje", 
      description: "Probere s tebou potřebné informace a tvou představu, obvykle formou online konzultace",
     },
    {
      number: 3,
      title: "Trenér vytvoří obsah", 
      description: "Na základě komunikace pro tebe trenér na míru sestaví plán coachingu",
     },
     {
      number: 4,
      title: "Obdržíš obsah", 
      description: "Balíček dorazí a můžeš začít trénovat. Pokud něco nevyhovuje můžeš zažádat o úpravu balíčku.",
     },
     {
      number: 5,
      title: "Trenér s tebou komunikuje", 
      description: "Plány a celý coaching ti podle potřeb upravuje na základě společné komunikace a schůzek",
     },
  ]

  const currentValues = props.type == "MONTHLY_SUBSCRIPTION" ? procesSubscriptionValues : procesPackageValues;

  const Box = (props) => {
    return (
      <div className="process_detail_component__box">
        <div>
          <div className="process_detail_component__box_box">
            <p className="process_detail_component__box_box_h4">{props.number}</p>
          </div>
          {!props.isLast && <Icon className="process_detail_component__box_box_image" src={VerticalLineIcon} alt="icon"/>}
        </div>
        <div>
          <h5 className="process_detail_component__box_h4">{props.title}</h5>
          <p className="process_detail_component__box_p">{props.description}</p>
        </div>
      </div>
    )
  }

  return (
    <div className="process_detail_component">
      {currentValues.map((value, index) => 
        <Box key={index} {...value} isLast={currentValues.length == index + 1} index={index} />
      )}
    </div>
  )
   
  
}