import React from "react";
import "./SubmitButton.css";

export default function SubmitButton(props) {
  const { icon, src, title, onClick, className, ...restProps } = props;

  return (
    <button className={`button_submit ${className}`} {...restProps} onClick={onClick}>
      {title}
    </button>
  );
}
